/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/27/2020, working Edit Cart Items functionality and Add Breadcrumb
 * Shivani Zinzuvadia, 03/10/2021, Condition added to hide cart icon for home and unsubscribe page
 */
import React from "react";
import { Redirect } from "react-router-dom";
import {Modal, Button} from "react-bootstrap";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { manageLaterData } from './components/service';
import $ from "jquery";
import {ReactComponent as CartIcon} from "./assets/cartIcon.svg";
import {ReactComponent as BackIcon} from "./assets/backIcon.svg";
import {ReactComponent as EditIcon} from "./assets/editIcon.svg";
import noImage from "./assets/no-image.svg";
import {ReactComponent as RemoveIcon} from "./assets/removeIcon.svg";
import svangLogo from "./assets/sVang-logo.png";
import './nav.css';
let addToCard = [];
let childOptionTopping = [];
let childOptionToppingObjArray = [];
let tmpSpecialInstructions = [];
function removeDuplicates(arr) {
        return arr.filter((item, 
            index) => arr.indexOf(item) === index); 
} 
const getItems = (addToCard) => {
    if (addToCard) {
        return addToCard.cartItems.length;
    } else {
        return 0;
    }
}
function toRad(Value) {
    return (Value * Math.PI) / 180;
}

const distanceCalculation = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat = toRad(lat1);
    var lat3 = toRad(lat2);
    let factor = 0.621371;
    var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat) * Math.cos(lat3);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    var distance = parseFloat(d * factor);
    distance = distance.toFixed(2);
    return Math.round(distance);
}

class Navi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addToCard: {},
            show: false,
            isShowDoc: false,
            isUpdateButtonDisable: true,
            cartCount: 0,
            addToCartTotalAmount: '',
            redirectTo: false,
            isOpenEditCartModal: false,
            isOpenEditCartModalLatest: false,
            editCartData: {},
            strProductObj: {},
            addition_options: [],
            addition_options_with_price: [],
            parent_options: [],
            child_options: [],
            select_child_options_without_topping: [],
            optional_items: [],
            select_optional_items: [],
            all_items_selection: [],
            select_all_items: [],
            add_special_instructions: '',
            breadcrumbName: '',
            orderObj: {},
            Error: {
                modifiresError: {message: '', index: []},
            },
            linkId: '',
            linkIds: [],
            couponcode: '',
            email: '',
            checkoutButtonDisable: false,
            openDate: '',
            openDay: '',
            hideHeader: this.props.hideHeader,
            productPrice: 0,
            vendorId: this.props.vendorId,
            vendorData: this.props.vendorData
        };
        this.getCartCount = this.getCartCount.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        window.addEventListener("storage", this.getCartCount);
        addToCard = JSON.parse(localStorage.getItem('addToCard'));
        if (addToCard) {
            let newAddToCard = [];
            const vendorId = this.props.vendorId;
            for (let i = 0; i < addToCard.cartItems.length; i += 1) {
                if (vendorId === addToCard.cartItems[i].vendorID) {
                    newAddToCard.push(addToCard.cartItems[i]);
                }
            }
            this.setState({
                cartCount: newAddToCard.length,
                addToCard: {
                    cartItems: newAddToCard
                }
            });
            this.totalAmout({cartItems: newAddToCard});
        } else {
            this.totalAmout({cartItems: []});
        }
    }
    
    redirectCheckout() {
        if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
            this.setState({redirectTo:true, orderObj: {}})
            return;
        }
        const vendorProduct = this.props.vendorProduct;
        this.setState({
            couponcode: this.props?.vendorProduct?.queryData?.couponcode || '',
            email: this.props?.vendorProduct?.queryData?.email || ''
        });
        let orderObj = {};
        if (!Object.keys(vendorProduct.orderAddressObj).length) {
            if (vendorProduct.isActiveAddressType === 1) {
                if (!vendorProduct.userAddress) {
                    swal('Please enter delivery address');
                    return;
                }
                if (vendorProduct.userAddress) {
                    geocodeByAddress(vendorProduct.userAddress)
                        .then(results => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                            if (vendorProduct.vendorData.deliveryRadius <= distanceCalculation(lat, lng, vendorProduct.vendorData.latitude, vendorProduct.vendorData.longitude)) {
                                swal(
                                    'Sorry... Your address is beyond our delivery range. Please choose a pickup option.'
                                );
                                return;
                            } else {
                                if (vendorProduct.deliveryFullData.orderType === 'Schedule For Later') {
                                    orderObj.DeliveryType = 'Delivery';
                                    orderObj.isUnivercity = false;
                                    orderObj.orderType = 'Schedule For Later';
                                    orderObj.line1 = vendorProduct.userAddress;
                                    orderObj.location = {
                                        latitude: lat,
                                        longitude: lng
                                    };
                                    orderObj.scheduleDate = vendorProduct.deliveryFullData.scheduleDate;
                                    orderObj.scheduleTime = vendorProduct.deliveryFullData.scheduleTime;
                                } else {
                                    orderObj.DeliveryType = 'Delivery';
                                    orderObj.isUnivercity = false;
                                    orderObj.orderType = 'Order Now';
                                    orderObj.line1 = vendorProduct.userAddress;
                                    orderObj.location = {
                                        latitude: lat,
                                        longitude: lng
                                    };
                                }
                                this.setState({redirectTo:true, orderObj: orderObj})
                            }
                        }
                    );
                }
            } else {
                if (vendorProduct.deliveryFullData.orderType === 'Schedule For Later') {
                    orderObj.DeliveryType = 'Pick at Restaurant';
                    orderObj.isUnivercity = false;
                    orderObj.orderType = 'Schedule For Later';
                    orderObj.scheduleDate = vendorProduct.deliveryFullData.scheduleDate;
                    orderObj.scheduleTime = vendorProduct.deliveryFullData.scheduleTime;
                } else {
                    orderObj.DeliveryType = 'Pick at Restaurant';
                    orderObj.isUnivercity = false;
                    orderObj.orderType = 'Order Now';
                }
                if (vendorProduct
                    && vendorProduct.vendorData
                    && vendorProduct.vendorData.pickupRadius
                    && this.props.browerLatitude
                    && this.props.browerLongitude
                    && vendorProduct.vendorData.pickupRadius <= distanceCalculation(this.props.browerLatitude, this.props.browerLongitude, vendorProduct.vendorData.latitude, vendorProduct.vendorData.longitude)) {
                    Swal.fire({
                        title: 'You seem far away from this address, are you sure you want to continue?',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            this.setState({redirectTo:true, orderObj: orderObj})
                        }
                    });
                    return;
                }
                this.setState({redirectTo:true, orderObj: orderObj})
            }
        } else {
            if (vendorProduct.isActiveAddressType === 2) {
                if (vendorProduct.orderAddressObj.orderType === 'Schedule For Later') {
                    orderObj.DeliveryType = 'Pick at Restaurant';
                    orderObj.isUnivercity = false;
                    orderObj.orderType = 'Schedule For Later';
                    orderObj.scheduleDate = vendorProduct.orderAddressObj.scheduleDate;
                    orderObj.scheduleTime = vendorProduct.orderAddressObj.scheduleTime;
                } else {
                    orderObj.DeliveryType = 'Pick at Restaurant';
                    orderObj.isUnivercity = false;
                    orderObj.orderType = 'Order Now';
                }
                if (vendorProduct
                    && vendorProduct.vendorData
                    && vendorProduct.vendorData.pickupRadius
                    && this.props.browerLatitude
                    && this.props.browerLongitude
                    && vendorProduct.vendorData.pickupRadius <= distanceCalculation(this.props.browerLatitude, this.props.browerLongitude, vendorProduct.vendorData.latitude, vendorProduct.vendorData.longitude)) {
                    Swal.fire({
                        title: 'You seem far away from this address, are you sure you want to continue?',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then(async (result) => {
                        if (result.value) {
                            this.setState({redirectTo:true, orderObj: orderObj})
                        }
                    });
                    return;
                }
                this.setState({redirectTo:true, orderObj: orderObj})
                return;
            } else {
                if (!vendorProduct.userAddress) {
                    swal('Please enter delivery address');
                    return;
                }
                if (vendorProduct.userAddress) {
                    geocodeByAddress(vendorProduct.userAddress)
                        .then(results => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                            if (vendorProduct.vendorData.deliveryRadius <= distanceCalculation(lat, lng, vendorProduct.vendorData.latitude, vendorProduct.vendorData.longitude)) {
                                swal(
                                    'Sorry... Your address is beyond our delivery range. Please choose a pickup option.'
                                );
                                return;
                            } else {
                                if (vendorProduct.orderAddressObj.orderType === 'Schedule For Later') {
                                    orderObj.DeliveryType = 'Delivery';
                                    orderObj.isUnivercity = false;
                                    orderObj.orderType = 'Schedule For Later';
                                    orderObj.line1 = vendorProduct.userAddress;
                                    orderObj.location = {
                                        latitude: lat,
                                        longitude: lng
                                    };
                                    orderObj.scheduleDate = vendorProduct.orderAddressObj.scheduleDate;
                                    orderObj.scheduleTime = vendorProduct.orderAddressObj.scheduleTime;
                                } else {
                                    orderObj.DeliveryType = 'Delivery';
                                    orderObj.isUnivercity = false;
                                    orderObj.orderType = 'Order Now';
                                    orderObj.line1 = vendorProduct.userAddress;
                                    orderObj.location = {
                                        latitude: lat,
                                        longitude: lng
                                    };
                                }
                                this.setState({redirectTo:true, orderObj: orderObj})
                            }
                        }
                    );
                }
            }
        }
    }
    totalAmout(addToCard) {
        if (addToCard) {
            let addToCartTotalAmount = "";     
            var total_Data = addToCard.cartItems.reduce((acc, item) => acc + parseFloat(Number(item.totalAmount)*item.quantity ? Number(item.totalAmount)*item.quantity : 0),0);
            if (isNaN(total_Data)) {
                total_Data = 0.00;
            }
            addToCartTotalAmount = total_Data.toFixed(2);
            this.setState({addToCartTotalAmount:addToCartTotalAmount})
        }
    }
    getCartCount() {
        let newAddToCard = [];
        let tmpAddToCard = JSON.parse(localStorage.getItem('addToCard'))
        const vendorId = this.props.vendorId;
        if (tmpAddToCard && tmpAddToCard.cartItems && tmpAddToCard.cartItems.length) {
            for (let i = 0; i < tmpAddToCard.cartItems.length; i += 1) {
                if (vendorId === tmpAddToCard.cartItems[i].vendorID) {
                    newAddToCard.push(tmpAddToCard.cartItems[i]);
                }
            }
            addToCard = {
                cartItems: newAddToCard
            };
            this.setState({
                addToCard: {
                    cartItems: newAddToCard
                }
            });
        }
        let count = getItems({cartItems: newAddToCard});
        if(count) {
            this.setState({
                cartCount: count
            });
            this.handleShow();
        } else {
            this.setState({
                cartCount: 0
            })
        }
        addToCard = {
            cartItems: newAddToCard
        };
        this.setState({
            addToCard: {
                cartItems: newAddToCard
            }
        });
        this.totalAmout({cartItems: newAddToCard});
    }
    handleClose() {
        this.setState({isShowDoc:false});
        $(".sidebar-trigger").removeClass('sidebar-trigger-section');
    }
    handleShow() {
        const delivery = JSON.parse(localStorage.getItem(this.props.vendorId + '___delivery'));
        const tabsArray = this?.props?.vendorProduct?.tabsArray ? this.props.vendorProduct.tabsArray : [];
        let tmpValue = false;
        if (tabsArray && tabsArray.length) {
            for (let i = 0; i < tabsArray.length; i += 1) {
                tmpValue = false;
                if (tabsArray[i].isSelected && !tabsArray[i].isOpenMenu) {
                    tmpValue = true;
                    break;
                }
            }
        }
        const selectedVendorData = this.state.vendorData;
        
        if (delivery && delivery.orderType === 'Order Now') {
            manageLaterData(delivery, selectedVendorData, (data) => {
                if (!data.status) {
                    this.setState({openDate: '', openDay: '', checkoutButtonDisable: true});
                    return;
                }
                this.setState({openDate: data.data.scheduleTimeData[0], openDay: data.day, checkoutButtonDisable: tmpValue});
            })
        } else {
            this.setState({openDate: '', openDay: '', checkoutButtonDisable:false})
        }
        
        this.setState({isShowDoc:true});
        $(".sidebar-trigger").addClass('sidebar-trigger-section');
    }
    removeItemFromCart(index, row) {
        addToCard = JSON.parse(localStorage.getItem('addToCard'));
        let tmpData = {};
        if (addToCard) {
            for (let i = 0; i < addToCard.cartItems.length; i += 1) {
                if (row.cartItemId === addToCard.cartItems[i].cartItemId) {
                    tmpData = addToCard.cartItems[i];
                    addToCard.cartItems.splice(i, 1);
                    break;
                }
            }
            if (addToCard.cartItems.length === 0) {
                localStorage.removeItem('addToCard');
                this.props.deleteItem(tmpData);
                this.getCartCount();
                return;
            }
            this.props.deleteItem(tmpData);
            localStorage.setItem('addToCard', JSON.stringify(addToCard))
            this.setState({
                addToCard: addToCard
            });
            this.getCartCount();
        }
    }

    openCartItemModalLatest(row) {
        childOptionTopping = [];
        childOptionToppingObjArray = [];
        tmpSpecialInstructions = [];
        const tmpaddToCard = JSON.stringify(this.state.addToCard);
        if (addToCard && addToCard.cartItems && addToCard.cartItems.length) {
            // Manage special instructions
            if (row && row.add_special_instructions && row.add_special_instructions.length) {
                tmpSpecialInstructions = [row.add_special_instructions];
                this.setState({add_special_instructions: row.add_special_instructions})
            } else {
                this.setState({add_special_instructions: ''})
            }

            // Latest Modifier Manage
            let tmplinkId = [];
            let productPrice = 0;
            if (row && row.modifiers && row.modifiers.length) {
                for (let i = 0; i < row.modifiers.length; i += 1) {
                    if (row.modifiers[i].isItemOption && row.modifiers[i].options && row.modifiers[i].options.length) {
                        for (let ii = 0; ii < row.modifiers[i].options.length; ii += 1) {
                            if (row.modifiers[i].options[ii].isChecked) {
                                productPrice += Number(row.modifiers[i].options[ii].price);
                                tmplinkId.push(row.modifiers[i].options[ii].id)
                            }
                        }
                    } else if (row.modifiers[i].isPizzaTopping) {
                        for (let ii = 0; ii < row.modifiers[i].options.length; ii += 1) {
                            if (row.modifiers[i].options[ii].selectedValue === "right" || row.modifiers[i].options[ii].selectedValue === "left") {
                                productPrice += Number(row.modifiers[i].options[ii].halfPrice);
                            }
                            if (row.modifiers[i].options[ii].selectedValue === "full") {
                                productPrice += Number(row.modifiers[i].options[ii].fullPrice);
                            }
                        }
                    } else {
                        for (let ii = 0; ii < row.modifiers[i].options.length; ii += 1) {
                            if (row.modifiers[i].options[ii].isChecked) {
                                productPrice += Number(row.modifiers[i].options[ii].price);
                            }
                        }
                    }
                }
            }

            this.setState({
                isOpenEditCartModalLatest: true,
                isUpdateButtonDisable: true,
                child_options: [],
                editCartData: row,
                strProductObj: JSON.stringify(row),
                productPrice: productPrice,
                linkIds: tmplinkId,
                addToCard: JSON.parse(tmpaddToCard)
            });
        }
        this.setState({
            Error: {
                modifiresError: {message: '', index: []},
            }
        });
    }
    decreaseItem(row) {
        if (row.Defaultquantity === 1) {
            return;
        }
        row.Defaultquantity -= 1;
        this.setState({
            productData: this.state.productData,
            isUpdateButtonDisable: false,
            addition_options: row.addition_options,
            addition_options_with_price: row.addition_options_with_price,
            parent_options: row.parent_options,
            child_options: row.child_options,
            select_child_options_without_topping: row.select_child_options_without_topping,
            optional_items: row.optional_items,
            select_optional_items: row.select_optional_items,
            select_all_items: row.select_all_items,
            all_items_selection: row.all_items_selection,
            add_special_instructions: row.add_special_instructions
        })
    }
    increaseItem(row) {
        row.Defaultquantity += 1;
        this.setState({
            productData:this.state.productData,
            isUpdateButtonDisable: false,
            addition_options: row.addition_options,
            addition_options_with_price: row.addition_options_with_price,
            parent_options: row.parent_options,
            child_options: row.child_options,
            select_child_options_without_topping: row.select_child_options_without_topping,
            optional_items: row.optional_items,
            select_optional_items: row.select_optional_items,
            select_all_items: row.select_all_items,
            all_items_selection: row.all_items_selection,
            add_special_instructions: row.add_special_instructions
        })
    }
    changeSpecialInstructions(e, siUpdaterow) {
        this.setState({add_special_instructions: e.target.value, isUpdateButtonDisable: false})
        tmpSpecialInstructions = [e.target.value];
    }
    checkValidateLatest(){
        const error = {
            modifiresError: {
                message: '',
                index: [],
            }
        }
        this.setState({
            Error: error
        });
        let tmpValidationCheck = true;
        let indexadd = [];
        // eslint-disable-next-line
        this.state.editCartData?.modifiers?.map((item, index) => {
            if (item && item.choiceLimit && !item.isItemOption && !item.isComesWith && !item.isPizzaTopping) {
                if (!item.isItemOptionId || item.isItemOptionId === "") {
                    const result = item.options.filter(word => word.isChecked === true);
                    var valid = true;
                    let tmpMessage = '';
                    if (item.choiceLimit !== "" && !item.isRequired && !item.isFixed) {
                        if (Number(item.choiceLimit) >= result.length) {
                            valid = true;
                        } else {
                            valid = false;
                        }
                    }
                    if (item.choiceLimit !== "" && item.isRequired && !item.isFixed) {
                        if (Number(item.choiceLimit) <= result.length) {
                            valid = true;
                        } else {
                            tmpMessage = `You must choose minimum ${item.choiceLimit} items `;
                            valid = false;
                        }
                    }
                    if (item.choiceLimit !== "" && !item.isRequired && item.isFixed) {
                        if (Number(item.choiceLimit) === result.length) {
                            valid = true;
                        } else {
                            tmpMessage = `You must choose ${item.choiceLimit} items `;
                            valid = false;
                        }
                    }
                    if (!valid) {
                        tmpValidationCheck = false;
                    }
                    if (!valid) {
                        indexadd.push(index);
                        let tError = {
                            modifiresError: {
                                message: tmpMessage,
                                index: indexadd,
                            }
                        };
                        this.setState({
                            Error:tError
                        });
                        // eslint-disable-next-line
                        this.state.Error = {
                            modifiresError: {
                                message: tmpMessage,
                                index: indexadd,
                            },
                        };
                    }
                }
                if (this.state.linkIds.indexOf(item.isItemOptionId) !== -1) {
                    const result = item.options.filter(word => word.isChecked === true);
                    // eslint-disable-next-line
                    var valid = true;
                    let tmpMessage = '';
                    if (item.choiceLimit !== "" && !item.isRequired && !item.isFixed) {
                        if (Number(item.choiceLimit) >= result.length) {
                            valid = true;
                        } else {
                            valid = false;
                        }
                    }
                    if (item.choiceLimit !== "" && item.isRequired && !item.isFixed) {
                        if (Number(item.choiceLimit) <= result.length) {
                            valid = true;
                        } else {
                            tmpMessage = `You must choose minimum ${item.choiceLimit} items `;
                            valid = false;
                        }
                    }
                    if (item.choiceLimit !== "" && !item.isRequired && item.isFixed) {
                        if (Number(item.choiceLimit) === result.length) {
                            valid = true;
                        } else {
                            tmpMessage = `You must choose ${item.choiceLimit} items `;
                            valid = false;
                        }
                    }
                    if (!valid) {
                        tmpValidationCheck = false;
                    }
                    if (!valid) {
                        indexadd.push(index);
                        let tError = {
                            modifiresError: {
                                message: tmpMessage,
                                index: indexadd,
                            }
                        };
                        this.setState({
                            Error:tError
                        });
                        // eslint-disable-next-line
                        this.state.Error = {
                            modifiresError: {
                                message: tmpMessage,
                                index: indexadd,
                            },
                        };
                    }
                }
            }
        });
        if (tmpValidationCheck) {
            // eslint-disable-next-line
            this.state.Error = {
                modifiresError: {
                    message: '',
                    index: [],
                },
            };
        }
        this.setState({Error: this.state.Error});
        if (this.state.Error.modifiresError.index.length === 0) {
            // eslint-disable-next-line
            this.state.Error = {
                modifiresError: {
                    message: '',
                    index: [],
                },
            };
            this.updateCartItemLatest(this.state.editCartData);
        } else {
            const tmpError = this.state.Error;
            this.setState({Error: tmpError});
            return;
        }
    }

    // Latest Code Come with
    changeComesWith(e, oiUpdaterow, oiRow, moiRow) {
        let productPrice = this.state.productPrice;
        if (oiRow.isChecked) {
            oiRow.isChecked = false;
            productPrice -= Number(oiRow.price);
            oiUpdaterow.compareVariant.push(`${moiRow.displayName} No ${oiRow.name}`);
            oiUpdaterow.variant.push({
                title: moiRow.displayName,
                id: oiRow.id,
                name: `No ${oiRow.name}`,
                isComesWith: true,
                price: oiRow.price,
            });
        } else {
            oiRow.isChecked = true;
            if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                    if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isComesWith) {
                        productPrice += Number(oiRow.price);
                        oiUpdaterow.compareVariant.splice(i, 1);
                        oiUpdaterow.variant.splice(i, 1);
                    }
                }
            }
        }
        this.setState({editCartData: this.state.editCartData, productPrice: productPrice});
    }

    // Latest Code Item Options
    changeItemOptions(e, aoUpdaterow, aoRow, row, index) {
        let productPrice = this.state.productPrice;
        let tmplinkId = this.state.linkIds;
        for (let i = 0; i < row.options.length; i += 1) {
            if (i === index) {
                row.options[i].isChecked = true; 
                productPrice += Number(aoRow.price);
                tmplinkId.push(row.options[i].id);
                aoUpdaterow.compareVariant.push(`${row.displayName} No ${aoRow.name}`);
                aoUpdaterow.variant.push({
                    title: row.displayName,
                    id: aoRow.id,
                    name: aoRow.name,
                    price: aoRow.price,
                    isItemOption: true
                });
            } else {
                if (row.options[i].isChecked) {
                    productPrice -= Number(row.options[i].price);
                    if (aoUpdaterow.variant && aoUpdaterow.variant.length) {
                        for (let ii = 0; ii < aoUpdaterow.variant.length; ii += 1) {
                            if (aoUpdaterow.variant[ii].id === row.options[i].id) {
                                aoUpdaterow.compareVariant.splice(ii, 1);
                                aoUpdaterow.variant.splice(ii, 1);
                            }
                        }
                    }
                }
                row.options[i].isChecked = false;
                if (tmplinkId.indexOf(row.options[i].id) !== -1) {
                    tmplinkId.splice(tmplinkId.indexOf(row.options[i].id), 1)
                }
            }
        }
        childOptionToppingObjArray = [];
        childOptionTopping = [];
        if (aoUpdaterow && aoUpdaterow.modifiers && aoUpdaterow.modifiers.length) {
            for (let ii = 0; ii < aoUpdaterow.modifiers.length; ii += 1) {
                if (aoUpdaterow.modifiers[ii].displayName && aoUpdaterow.modifiers[ii].isPizzaTopping) {
                    for (let iii = 0; iii < aoUpdaterow.modifiers[ii].options.length; iii += 1) {
                        if (aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'full') {
                            productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].fullPrice);
                        } else if (aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'right' || aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'left') {
                            productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].halfPrice);
                        }
                        if (this.state.linkIds.indexOf(aoRow.id) !== -1) {
                            if (aoUpdaterow.modifiers[ii].options[iii].include) {
                                aoUpdaterow.modifiers[ii].options[iii].selectedValue = 'full';
                                childOptionToppingObjArray.push({
                                    title: aoUpdaterow.modifiers[ii].displayName,
                                    name: aoUpdaterow.modifiers[ii].options[iii].name,
                                    id: aoUpdaterow.modifiers[ii].options[iii].id,
                                    price: aoUpdaterow.modifiers[ii].options[iii].fullPrice,
                                    type: 'Full'
                                });
                                if (childOptionTopping.indexOf(`${aoUpdaterow.modifiers[ii].displayName} Full ${aoUpdaterow.modifiers[ii].options[iii].name}`) === -1) {
                                    childOptionTopping.push(`${aoUpdaterow.modifiers[ii].displayName} Full ${aoUpdaterow.modifiers[ii].options[iii].name}`);
                                }
                            }
                            if (!aoUpdaterow.modifiers[ii].options[iii].include) {
                                aoUpdaterow.modifiers[ii].options[iii].selectedValue = '';
                            }
                        }
                    }
                }
                if (aoUpdaterow.modifiers[ii].isItemOptionId && aoUpdaterow.modifiers[ii].isItemOptionId !== "" && this.state.linkIds.indexOf(aoRow.id) !== -1) {
                    for (let iii = 0; iii < aoUpdaterow.modifiers[ii].options.length; iii += 1) {
                        if (aoUpdaterow.modifiers[ii].options[iii].isChecked) {
                            aoUpdaterow.modifiers[ii].options[iii].isChecked = false;
                            productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].price);
                            for (let j = 0; j < aoUpdaterow.variant.length; j += 1) {
                                if (aoUpdaterow.variant[j].id === aoUpdaterow.modifiers[ii].options[iii].id) {
                                    aoUpdaterow.variant.splice(j, 1);
                                    aoUpdaterow.compareVariant.splice(j, 1);
                                }
                            }
                        }
                        if (!aoUpdaterow.modifiers[ii].options[iii].selectedValue) {
                            aoUpdaterow.modifiers[ii].options[iii].selectedValue = "";
                            // productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].price);
                            for (let j = 0; j < aoUpdaterow.variant.length; j += 1) {
                                if (aoUpdaterow.variant[j].id === aoUpdaterow.modifiers[ii].options[iii].id) {
                                    aoUpdaterow.variant.splice(j, 1);
                                    aoUpdaterow.compareVariant.splice(j, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setState({editCartData: this.state.editCartData, linkIds: tmplinkId, productPrice: productPrice});
    }

    // Latest Code Item Option Select 
    changeItemOptionsSelect(e, oiUpdaterow, oiRow, moiRow) {
        let productPrice = this.state.productPrice;
        if (moiRow && moiRow.choiceLimit && moiRow.choiceLimit !== "" && !moiRow.isRequired) {
            oiRow['isChecked'] = !oiRow.isChecked;
            const result = moiRow.options.filter(
                word => word.isChecked === true,
            );
            var valid = true;
            if (moiRow.choiceLimit !== "" && !moiRow.isRequired) {
                if (Number(moiRow.choiceLimit) >= result.length) {
                    valid = true;
                } else {
                    oiRow['isChecked'] = !oiRow.isChecked;
                    valid = false;
                }
            }
            if (valid) {
                if (oiRow.isChecked === true) {
                    oiRow.isChecked = true;
                    productPrice += Number(oiRow.price);
                    oiUpdaterow.compareVariant.push(`${moiRow.displayName} ${oiRow.name}`);
                    oiUpdaterow.variant.push({
                        title: moiRow.displayName,
                        name: oiRow.name,
                        id: oiRow.id,
                        isItemOptionSelect: true,
                        price: oiRow.price
                    });
                } else {
                    oiRow.isChecked = false;
                    if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                        for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                            if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isItemOptionSelect) {
                                productPrice -= Number(oiUpdaterow.variant[i].price);
                                oiUpdaterow.compareVariant.splice(i, 1);
                                oiUpdaterow.variant.splice(i, 1);
                            }
                        }
                    }
                }
            }
        } else {
            if (!oiRow.isChecked) {
                oiRow.isChecked = true;
                productPrice += Number(oiRow.price);
                oiUpdaterow.compareVariant.push(`${moiRow.displayName} ${oiRow.name}`);
                oiUpdaterow.variant.push({
                    title: moiRow.displayName,
                    name: oiRow.name,
                    id: oiRow.id,
                    isItemOptionSelect: true,
                    price: oiRow.price
                });
            } else {
                oiRow.isChecked = false;
                if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                    for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                        if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isItemOptionSelect) {
                            productPrice -= Number(oiUpdaterow.variant[i].price);
                            oiUpdaterow.compareVariant.splice(i, 1);
                            oiUpdaterow.variant.splice(i, 1);
                        }
                    }
                }
            }
        }
        this.setState({editCartData: this.state.editCartData, productPrice: Number(productPrice.toFixed(2))});
    }
    // Pizza Options
    changePizzaOptions(e, key, childOptionsRow, aoRow, index) {
        childOptionToppingObjArray = [];
        childOptionTopping = [];
        let productPrice = this.state.productPrice;
        const editCartData = {...this.state.editCartData};
        for (let i = 0; i < aoRow.length; i += 1) {
            if (aoRow[i].selectedValue === 'full') {
                productPrice -= Number(aoRow[i].fullPrice);
            } else if (aoRow[i].selectedValue === 'left' || aoRow[i].selectedValue === 'right') {
                productPrice -= Number(aoRow[i].halfPrice);
            }
            if (i === index) {
                if (aoRow[i].selectedValue === key) {
                    aoRow[i].selectedValue = '';
                    const locationoptionsLength = editCartData.variant.length;
                    for (let ii = locationoptionsLength; ii--;) {
                        if (editCartData.variant[ii].id === aoRow[i].id) {
                            editCartData.variant.splice(ii, 1);
                        }
                    }
                } else {
                    aoRow[i].selectedValue = key;
                }
            }
        }
        for (let i = 0; i < aoRow.length; i += 1) {
            if (aoRow[i].selectedValue === 'left') {
                productPrice += Number(aoRow[i].halfPrice);
                if (editCartData.variant && editCartData.variant.length) {
                    const locationoptionsLength = editCartData.variant.length;
                    for (let ii = locationoptionsLength; ii--;) {
                        if (editCartData.variant[ii].id === aoRow[i].id) {
                            editCartData.variant.splice(ii, 1);
                        }
                    }
                }
                childOptionToppingObjArray.push({
                    title: childOptionsRow.displayName,
                    name: aoRow[i].name,
                    price: aoRow[i].halfPrice,
                    id: aoRow[i].id,
                    type: 'Left Half'
                });
                if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Left Half ${aoRow[i].name}`) === -1) {
                    childOptionTopping.push(`${childOptionsRow.displayName} Left Half ${aoRow[i].name}`);
                }
            }
            if (aoRow[i].selectedValue === 'right') {
                productPrice += Number(aoRow[i].halfPrice);
                if (editCartData.variant && editCartData.variant.length) {
                    const locationoptionsLength = editCartData.variant.length;
                    for (let ii = locationoptionsLength; ii--;) {
                        if (editCartData.variant[ii].id === aoRow[i].id) {
                            editCartData.variant.splice(ii, 1);
                        }
                    }
                }
                childOptionToppingObjArray.push({
                    title: childOptionsRow.displayName,
                    name: aoRow[i].name,
                    price: aoRow[i].halfPrice,
                    id: aoRow[i].id,
                    type: 'Right Half'
                });
                if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Right Half ${aoRow[i].name}`) === -1) {
                    childOptionTopping.push(`${childOptionsRow.displayName} Right Half ${aoRow[i].name}`);
                }
            }
            if (aoRow[i].selectedValue === 'full') {
                productPrice += Number(aoRow[i].fullPrice);
                if (editCartData.variant && editCartData.variant.length) {
                    const locationoptionsLength = editCartData.variant.length;
                    for (let ii = locationoptionsLength; ii--;) {
                        if (editCartData.variant[ii].id === aoRow[i].id) {
                            editCartData.variant.splice(ii, 1);
                        }
                    }
                }
                childOptionToppingObjArray.push({
                    title: childOptionsRow.displayName,
                    name: aoRow[i].name,
                    price: aoRow[i].fullPrice,
                    id: aoRow[i].id,
                    type: 'Full'
                });
                if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Full ${aoRow[i].name}`) === -1) {
                    childOptionTopping.push(`${childOptionsRow.displayName} Full ${aoRow[i].name}`);
                }
            }
        }
        this.setState({editCartData: editCartData, productPrice: Number(productPrice.toFixed(2))});
    }

    updateCartItemLatest(updateRow) {
        addToCard = JSON.parse(localStorage.getItem('addToCard'));
        if (addToCard && addToCard.cartItems && addToCard.cartItems.length) {
            for (let i = 0; i < addToCard.cartItems.length; i += 1) {
                if (addToCard.cartItems[i].cartItemId === updateRow.cartItemId) {
                    addToCard.cartItems[i].quantity = updateRow.Defaultquantity;
                    addToCard.cartItems[i].Defaultquantity = updateRow.Defaultquantity;
                    if (this.state.add_special_instructions) {
                        addToCard.cartItems[i].add_special_instructions = this.state.add_special_instructions;
                    } else {
                        addToCard.cartItems[i].add_special_instructions = '';
                    }
                    addToCard.cartItems[i].modifiers = updateRow.modifiers;
                    addToCard.cartItems[i].totalAmount = this.state.productPrice;
                    addToCard.cartItems[i].price = Number(this.state.productPrice);
                    addToCard.cartItems[i].compareVariant = removeDuplicates([...updateRow.compareVariant, ...tmpSpecialInstructions, ...childOptionTopping]).sort();
                    // addToCard.cartItems[i].compareVariant = [...updateRow.compareVariant, ...tmpSpecialInstructions, ...childOptionTopping].sort();
                    addToCard.cartItems[i].variant = [...updateRow.variant, ...childOptionToppingObjArray];
                    break;
                }
            }
            localStorage.setItem('addToCard', JSON.stringify(addToCard));
            let newAddToCard = [];
            const vendorId = this.props.vendorId;
            for (let ii = 0; ii < addToCard.cartItems.length; ii += 1) {
                if (vendorId === addToCard.cartItems[ii].vendorID) {
                    newAddToCard.push(addToCard.cartItems[ii]);
                }
            }
            this.setState({
                isOpenEditCartModalLatest: false,
                addToCard: {
                    cartItems: newAddToCard
                }
            });
            this.props.updateItem(updateRow);
            this.getCartCount();
        }
    }
    
    closeCartItemModalLatest() {
        this.setState({editCartData: JSON.parse(this.state.strProductObj), isOpenEditCartModalLatest: false});
    }
    render() {
        const { linkIds
        } = { ...this.state }
        return (

            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-white navbar-custome">
                    <div className="" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                            {this.props.hideHeader !== 'true' &&
                                <li className="nav-item active">
                                    <img src={svangLogo} alt="svangLogo" width="80px"></img>
                                </li>
                            }
                        </ul>
                        {(window.location.pathname !== '/home' &&  window.location.pathname !== '/unsubscribe' && window.location.pathname.indexOf('/o/') === -1 ) &&
                            <span className="navbar-text">
                                <div className="cart_icon">
                                    {/* eslint-disable-next-line */}
                                    <a onClick={() => this.handleShow()}>
                                        <CartIcon/>
                                        <span className="cart_count">{this.state.cartCount}</span>
                                        {/* <span className="cart_count">{this.props}</span> */}
                                    </a>
                                </div>
                            </span>
                        }
                    </div>
                </nav>
                {this.state.isShowDoc && 
                    <div className="cart-doc-main">
                        <div className="cart-doc-main-header">
                            <h3 className="cart-doc-main-title">Your order 
                            {window.innerWidth > 767 ? (
                                <span className="cart-doc-main-close" onClick={() => this.handleClose()}>close</span>
                            ) : (
                                <span className="cart-doc-main-close" onClick={() => this.handleClose()}>back to menu</span>
                            )}
                            </h3>
                        </div>
                        <div className="cart-doc-main-body">
                        {this.state.checkoutButtonDisable &&
                            <div className="text-red order-not-avaible-text">we apologize, we are not currently accepting carryout orders until {this.state.openDay} {this.state.openDate}. Please select the next schedule time.</div>
                        }
                        { this.state.addToCard && this.state.addToCard.cartItems && this.state.addToCard.cartItems.length > 0 &&
                            this.state.addToCard.cartItems.map((row, index) => (
                                <div className="cart_item_section" key={index}>
                                    <div className="cart_left_content">
                                        <div className="sidebar-cart_title-wrapper">
                                        <span className="item-img">
                                            
                                            <img src={row?.photo?row.photo:noImage} alt={row.photo} />
                                        </span>
                                        <span className="title">
                                            <span className="common-primary-link" onClick={() => this.openCartItemModalLatest(row)}>{row.name}</span> <br></br>
                                            <span className="product-quantity">Qty: {row.quantity}</span>
                                            {row.variant.map((vRow, vIndex) => (
                                                <span className="variant" key={vIndex}>{vRow.name}{vRow.type ? `(${vRow.type})`: ""}
                                                { row && row.variant && (row.variant.length-1) !== vIndex && 
                                                    <span>, </span>
                                                }
                                                </span>
                                            ))}
                                        </span>
                                        </div>
                                        {row && row.add_special_instructions &&
                                            <span className="special-instructions">"{row.add_special_instructions}"</span>
                                        }
                                    </div>
                                    <div className="cart_right_content">
                                    <div className="pricewrapper">
                                        <span className="price">
                                            ${(Number(row.totalAmount)*row.quantity).toFixed(2)}
                                        </span>
                                    </div>
                                        <div>
                                            <div className="remove-cart-icon" onClick={() => this.removeItemFromCart(index, row)}>
                                                <RemoveIcon/>
                                            </div>
                                            <div className="remove-cart-icon" onClick={() => this.openCartItemModalLatest(row)}>
                                                <EditIcon/>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))
                        }
                        
                        {
                            !(this.state.addToCard && this.state.addToCard.cartItems && this.state.addToCard.cartItems.length) && 
                            <div className="text-center">
                                Empty Cart
                            </div>
                        }
                        </div>
                        { this.state.addToCard && this.state.addToCard.cartItems && this.state.addToCard.cartItems.length > 0 && 
                            <div>
                                {window.innerWidth > 767 ? (
                                    <div>
                                        {!this.state.checkoutButtonDisable &&
                                            <div className="sidebar-button" onClick={() => this.redirectCheckout()}>
                                                {this.state.redirectTo &&
                                                    <Redirect to={{ pathname: '/checkout/'+this.props.vendorId, state: {addToCard: this.state.addToCard, orderObj: this.state.orderObj, couponcode: this.state.couponcode, email: this.state.email}}}></Redirect>
                                                }
                                                <button className="btn btn-bottom" to={"/checkout/"+this.props.vendorId}>Checkout (${this.state.addToCartTotalAmount})</button>
                                            </div>
                                        }
                                        {this.state.checkoutButtonDisable &&
                                            <div className="sidebar-button">
                                                    <button className="btn btn-bottom disabled">Checkout (${this.state.addToCartTotalAmount})</button>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div className="bottom-cart-wrapper">
                                        <div className="back-btn">
                                            <span className="cart-back-icon"><BackIcon /></span>
                                            <button className="btn btn-bottom pl-1" onClick={() => this.handleClose()}> Back</button>
                                        </div>
                                        {!this.state.checkoutButtonDisable &&
                                            <div className="sidebar-button" onClick={() => this.redirectCheckout()}>
                                                {this.state.redirectTo && 
                                                    <Redirect to={{ pathname: '/checkout/'+this.props.vendorId, state: {addToCard: this.state.addToCard, orderObj: this.state.orderObj, couponcode: this.state.couponcode, email: this.state.email}}}></Redirect>
                                                }
                                                <button className="btn btn-bottom" to={"/checkout/"+this.props.vendorId}>Checkout (${this.state.addToCartTotalAmount})</button>
                                            </div>
                                        }
                                        {this.state.checkoutButtonDisable &&
                                            <div className="sidebar-button">
                                                <button className="btn btn-bottom disabled">Checkout (${this.state.addToCartTotalAmount})</button>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                }
                <Modal className="product_modal product-box-model" show={this.state.isOpenEditCartModalLatest} onHide={() => this.closeCartItemModalLatest()}>
                    <Modal.Header className="modal-header-main" closeButton>
                        <Modal.Title>
                            <h3 className="modal-product-title">{this.state.editCartData.name}</h3>
                            {/* <h5 className="mb-0">${this.state.editCartData.price}</h5> */}
                            <h5 className="mb-0">
                                ${(((Number(this.state.productPrice.toFixed(2))) * this.state.editCartData.Defaultquantity)).toFixed(2)}
                            </h5>
                            { this.state.editCartData.description &&
                                <p>{this.state.editCartData.description}</p>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    {((this.state.editCartData.modifiers && this.state.editCartData.modifiers.length > 0) 
                        || this.state.editCartData.special_instructions) && 
                            <Modal.Body className="modal-body-main">
                                {this.state && this.state.editCartData && this.state.editCartData.photo && 
                                    <img className="model-product-image" src={this.state.editCartData.photo} alt={this.state.editCartData.name}></img>
                                }
                                <div className="row">
                                    {this.state.editCartData.modifiers.map((row, index) => (
                                        <div className="col-md-12" key={index}>
                                            {/* Comes With Options */}
                                            {row.isComesWith && !row.isItemOption && !row.isPizzaTopping &&
                                                <div className="product-optional-items">
                                                    <h5>{row.displayName}</h5>
                                                    {
                                                        row.options.map((oiRow, oiIndex) => (
                                                            <div className="checkbox" key={oiIndex}>
                                                                <input type="checkbox" id={row.displayName+oiRow.name+index+oiIndex} name={oiRow.name+"comewith"+index} value={oiRow.name} checked={oiRow.isChecked} onChange={(e) => this.changeComesWith(e, this.state.editCartData, oiRow, row)} />
                                                                <label className={"check-label " + (oiRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oiRow.name+index+oiIndex} > {oiRow.name}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {/* Item Options */}
                                            {row.isItemOption && !row.isComesWith && !row.isPizzaTopping &&
                                                <div className="product-addition-options">
                                                    <h5>{row.displayName}</h5>
                                                    {
                                                        row.options.map((aoRow, aoIndex) => (
                                                            <div className="radio mb-3 mt-0" key={aoIndex}>
                                                                <input type="radio" id={row.displayName+aoRow.name+index+aoIndex} name={row.displayName+'itemoption'+index+aoIndex} value={aoRow.name} checked={aoRow.isChecked} onChange={(e) => this.changeItemOptions(e, this.state.editCartData, aoRow, row, aoIndex)} />
                                                                <label className={"radio-label " + (aoRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+aoRow.name+index+aoIndex}>{aoRow.name}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {/* Item Options Selection */}
                                            {!row.isItemOption && !row.isComesWith && !row.isPizzaTopping &&
                                                <div>
                                                    {(!row.isItemOptionId || row.isItemOptionId === "") &&
                                                        <div className="product-optional-items">
                                                            <h5>{row.displayName}
                                                            </h5>
                                                            {row && row.choiceLimit !== "" && !row.isRequired && !row.isFixed &&
                                                                <p>Maximum of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row && row.choiceLimit !== "" && row.isRequired && !row.isFixed &&
                                                                <p>Minimum of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row && row.choiceLimit !== "" && !row.isRequired && row.isFixed && 
                                                                <p>Select of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row.choiceLimit !== "" && row.isRequired && !row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                <p className="m-t--5-i color-red error-scroll-top">You must choose minimum {row.choiceLimit} items</p>
                                                            }
                                                            {row.choiceLimit !== "" && !row.isRequired && row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                <p className="m-t--5-i color-red error-scroll-top">You must choose {row.choiceLimit} items</p>
                                                            }
                                                            {
                                                                row.options.map((oisRow, oiIndex) => (
                                                                    <div className="checkbox" key={oiIndex}>
                                                                        <input type="checkbox" id={row.displayName+oisRow.name+index+oiIndex} name={oisRow.name+"itemoptionselect"+index} value={oisRow.name} checked={oisRow.isChecked ? oisRow.isChecked : false} onChange={(e) => this.changeItemOptionsSelect(e, this.state.editCartData, oisRow, row)} />  
                                                                        {/* <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name} > {oisRow.name} {oisRow.price > 0 ? `($${oisRow.price})`: ""} */}
                                                                        <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name+index+oiIndex} > {oisRow.name}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                    { linkIds.indexOf(row.isItemOptionId) !== -1 &&
                                                        <div className="product-optional-items">
                                                            <h5>{row.displayName}
                                                            </h5>
                                                            {row && row.choiceLimit !== "" && !row.isRequired && !row.isFixed &&
                                                                <p>Maximum of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row && row.choiceLimit !== "" && row.isRequired && !row.isFixed &&
                                                                <p>Minimum of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row && row.choiceLimit !== "" && !row.isRequired && row.isFixed && 
                                                                <p>Select of <span>{row.choiceLimit}</span></p>
                                                            }
                                                            {row.choiceLimit !== "" && row.isRequired && !row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                <p className="m-t--5-i color-red error-scroll-top">You must choose minimum {row.choiceLimit} items</p>
                                                            }
                                                            {row.choiceLimit !== "" && !row.isRequired && row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                <p className="m-t--5-i color-red error-scroll-top">You must choose {row.choiceLimit} items</p>
                                                            }
                                                            {
                                                                row.options.map((oisRow, oiIndex) => (
                                                                    <div className="checkbox" key={oiIndex}>
                                                                        <input type="checkbox" id={row.displayName+oisRow.name+index+oiIndex} name={oisRow.name+"itemoptionselect"+index} value={oisRow.name} checked={oisRow.isChecked ? oisRow.isChecked : false} onChange={(e) => this.changeItemOptionsSelect(e, this.state.editCartData, oisRow, row)} />  
                                                                        {/* <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name} > {oisRow.name} {oisRow.price > 0 ? `($${oisRow.price})`: ""} */}
                                                                        <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name+index+oiIndex} > {oisRow.name}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {/* Pizza Topping */}
                                            {!row.isComesWith && !row.isItemOption && row.isPizzaTopping && 
                                                <div className="row width-100">
                                                    <div className="col-md-12">
                                                        { linkIds.indexOf(row.isItemOptionId) !== -1 &&
                                                            <div className="">
                                                                    <h5 className="childOptionTopping_name toppingName_main">
                                                                        <span className="sub_topping_title_left">{row.displayName}</span>
                                                                        <span className="sub_topping_title">
                                                                            <span>Left Half</span>
                                                                            <span>Right Half</span>
                                                                            <span>Full</span>
                                                                        </span>
                                                                    </h5>
                                                                    {row.options.map((subChildOptionsRow, subChildOptionsIndex) => (
                                                                        <div className="child-topping-box" key={subChildOptionsIndex}>
                                                                            <h5 className="childOptionTopping_name">
                                                                                <span className={"sub_topping_title_left" + (!subChildOptionsRow.selectedValue ? " font-weight-300": "")}>{subChildOptionsRow.name} 
                                                                                </span>
                                                                                <div className="m-b-5 mt-0 radio_fancy_wrapper">                                                                                                                     
                                                                                    <div className="radio_fancy">
                                                                                        <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'left' ? 'check-topping-radio' : '')}>
                                                                                            <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'left'} checked={subChildOptionsRow.selectedValue === 'left'} onClick={(e) => this.changePizzaOptions(e, 'left', row, row.options, subChildOptionsIndex)} />
                                                                                        </div>
                                                                                        <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'right' ? 'check-topping-radio' : '')}>
                                                                                            <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'right'} checked={subChildOptionsRow.selectedValue === 'right'} onClick={(e) => this.changePizzaOptions(e, 'right', row, row.options, subChildOptionsIndex)} />
                                                                                            </div>
                                                                                        <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'full' ? 'check-topping-radio' : '')}>
                                                                                            <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'full'} checked={subChildOptionsRow.selectedValue === 'full'} onClick={(e) => this.changePizzaOptions(e, 'full', row, row.options, subChildOptionsIndex)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </h5>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                {this.state.editCartData.special_instructions &&
                                    <div className="product-optional-items product-special-instructions mt-0">
                                        <h5>Special Instructions</h5>
                                        <div className="special-name">
                                            <input type="text" autoComplete="off" id="specialInstructions" value={this.state.add_special_instructions} name="specialInstructions" maxLength="120" placeholder="Special Instructions" onChange={(e) => this.changeSpecialInstructions(e, this.state.productDetailsObj)} />
                                        </div>
                                    </div>
                                }
                            </Modal.Body>
                        }
                    <Modal.Footer className="modal-footer-main">
                        <div className="custome-modal-footer mt-0">
                            <div className="dynamic-price-section">
                                <div className="product-category-menu">
                                    <span className="prod-cate-decrease" onClick={() => this.decreaseItem(this.state.editCartData)}>-</span>
                                    <span className="">{this.state.editCartData.Defaultquantity}</span>
                                    <span className="prod-cate-increase" onClick={() => this.increaseItem(this.state.editCartData)}>+</span>
                                </div>
                                <div>
                                    <p className="mb-0">$ {((
                                        Number(this.state.productPrice.toFixed(2))
                                        *
                                        this.state.editCartData.Defaultquantity
                                        )).toFixed(2)}</p>
                                </div>
                            </div>
                            <div className="model-button-wrapper">
                                {Number(((Number(this.state.productPrice.toFixed(2)) * this.state.editCartData.Defaultquantity)).toFixed(2)) > 0 && 
                                    <Button className="btn-orange" variant="orange" onClick={() => this.checkValidateLatest(this.state.editCartData)}>
                                        Update
                                    </Button>
                                }
                                {Number(((Number(this.state.productPrice.toFixed(2)) * this.state.editCartData.Defaultquantity)).toFixed(2)) === 0 && 
                                    <Button className="btn-orange disabled" variant="orange">
                                        Update
                                    </Button>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    };
}

export default Navi;
