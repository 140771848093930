const moment = require('moment-timezone');
const parseTime = (s, key) => {
    var c = s.split(':');
    let addMinute = 0;
    if (key === 'add') {
        addMinute = 30;
    }
    return (parseInt(c[0]) * 60) + parseInt(c[1]) + parseInt(addMinute);
}

const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

const convertHours = (mins) => {
    var hour = Math.floor(mins/60);
    var tmins = mins%60;
    var converted = pad(hour, 2)+':'+pad(tmins, 2);
    return converted;
}

const convertTime12to24 = (time12h) => {
    time12h = time12h.replace('PM', ' PM');
    time12h = time12h.replace('AM', ' AM');
    const [time, modifier] = time12h.split(' ');
    
    let [hours, minutes] = time.split(':');
    
    if (hours === '12') {
        hours = '00';
    }
    
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    
    return `${hours}:${minutes}`;
}

const manageScheduleArray = (vendorData, currentDay) => {
    let tmpArray = [];
    for (const [key, value] of Object.entries(vendorData.schedule[currentDay])) {
        if (value && value.available) {
            tmpArray.push({
                title: key,
                available: value.available,
                delivery: value.delivery,
                door: value.door,
                order: value.order
            });
        }
    }
    if (tmpArray && tmpArray.length) {
        tmpArray.sort(function(a, b) {
            return a.order - b.order;
        });
        return tmpArray;
    } else {
        return tmpArray;
    }
};

const dayPassReturnKey = (day) => {
    let key = 0;
        if (day === 'Sunday') {
            key = 0;
        }
        else if (day === 'Monday') {
            key = 1;
        }
        else if (day === 'Tuesday') {
            key = 2;
        }
        else if (day === 'Wednesday') {
            key = 3;
        }
        else if (day === 'Thursday') {
            key = 4;
        }
        else if (day === 'Friday') {
            key = 5;
        } else {
            key = 6;
        }
        return key;
}

const getDayFunction = (day) => {
    // eslint-disable-next-line
    switch (day) {
        case 0:
            return "SUN";
        case 1:
            return "MON";
        case 2:
            return "TUE";
        case 3:
            return "WED";
        case 4:
            return "THU";
        case 5:
            return "FRI";
        case 6:
            return "SAT";
    }
}

const manageCurrentTime = (timezone, dateType, date) => {
    var options = {
        timeZone: timezone,
        // hour: 'numeric', minute: 'numeric'
    };
    if (dateType === 'weekDay') {
        options.weekday = 'long';
        var formatterW = new Intl.DateTimeFormat([], options);
        var currentTimeW = formatterW.format(date);
        return currentTimeW;
    }
    if (!dateType) {
        return Number(moment.tz(timezone).format("HH")+moment.tz().format("mm"))
    }
    if (dateType === 'short') {
        options.year = 'numeric';
        options.month = '2-digit';
        options.day = '2-digit';
        var formatter1 = new Intl.DateTimeFormat(['en-US'], options);
        var currentTime1 = formatter1.format(date);
        return currentTime1;
    }
    var formatter2 = new Intl.DateTimeFormat([], options);
    var currentTime2 = formatter2.format(new Date());
    return parseInt(currentTime2.split(':').join(''));
}

const manageScheArryFunction = (vendorData) => {
    const oneDayTime = 60 * 60 * 24 * 1000;
    let nextDay = 1;
    var manageScheArry = [{
        title: 'Today',
        date: manageCurrentTime(vendorData.tz, 'short', new Date().getTime()),
        scheduleDay:  getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date()))),
        scheduleArray: manageScheduleArray(vendorData, getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date())))),
        timeArray: []
    }];

    const scheduleArrarManage = () => {
        let holiday = true;
        const date = manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)).split('/').join('-');
        if (vendorData && vendorData.offdays && vendorData.offdays.length) {
            for (let ii = 0; ii < vendorData.offdays.length; ii += 1) {
                if (vendorData.offdays[ii].date === date) {
                    nextDay += 1;
                    holiday = false;
                }
            }
        }
        if (holiday === true) {
            manageScheArry.push({
                title: manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)),
                date: manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)),
                // scheduleDay: getDayFunction(new Date(new Date().getTime() + (oneDayTime*nextDay)).getDay()),
                scheduleDay: getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date(new Date().getTime() + (oneDayTime*nextDay))))),
                scheduleArray: manageScheduleArray(vendorData, getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date(new Date().getTime() + (oneDayTime*nextDay)))))),
                timeArray: []
            });
            if (manageScheArry.length !== 3) {
                nextDay += 1;
                scheduleArrarManage();
            }
        } else {
            // scheduleArrarManage();
            if (manageScheArry.length !== 3) {
                scheduleArrarManage();
            }
        }
    }
    scheduleArrarManage();
    return manageScheArry;
}

const changeScheduleDay = (value, vendorData, tmpD, tmpData, cb) => {
    if (!value) {
        value = 'Today';
    }
    for (let i = 0; i < tmpData.length; i += 1) {
        if (tmpData[i].title === value) {
            if (tmpData[i].title === 'Today') {
                let manageTimeArr = [];
                for (let ii = 0; ii < tmpData[i].timeArray.length; ii += 1) {
                    // var tValue = tmpData[i].timeArray[ii].split(' - ');
                    var tmpTime = convertTime12to24(tmpData[i].timeArray[ii].split(' - ')[0]);
                    var startTime = parseInt(tmpTime.split(':').join(''));
                    if (startTime > manageCurrentTime(vendorData.tz)+100) {
                        manageTimeArr.push(tmpData[i].timeArray[ii]);
                    }
                }
                if (!(manageTimeArr && manageTimeArr.length)) {
                    manageTimeArr = ['Restaurant Not Available'];
                }
                let tmpDefaultValueTime = manageTimeArr[0];
                let tmpScheduleTimeDate = tmpData[i].date;
                if (tmpD && tmpD.scheduleTime) {
                    tmpDefaultValueTime = tmpD.scheduleTime;
                }
                if (tmpD && tmpD.scheduleDate) {
                    tmpScheduleTimeDate = tmpD.scheduleDate;
                }
                cb({
                    defaultValueTime: tmpDefaultValueTime,
                    scheduleTimeData: manageTimeArr,
                    scheduleTimeDate: tmpScheduleTimeDate
                });
            } else {
                if (!(tmpData[i].timeArray && tmpData[i].timeArray.length)) {
                    tmpData[i].timeArray = ['Restaurant Not Available'];
                }
                let tmpDefaultValueTime = tmpData[i].timeArray[0];
                let tmpScheduleTimeDate = tmpData[i].date;
                
                const tmpD = JSON.parse(localStorage.getItem(vendorData.id + '___delivery'));
                if (tmpD && tmpD.scheduleTime) {
                    tmpDefaultValueTime = tmpD.scheduleTime;
                }
                if (tmpD && tmpD.scheduleDate) {
                    tmpScheduleTimeDate = tmpD.scheduleDate;
                }
                
                cb({
                    defaultValueTime: tmpDefaultValueTime,
                    scheduleTimeData: tmpData[i].timeArray,
                    scheduleTimeDate: tmpScheduleTimeDate
                });
            }
            break;
        }
    }
}

export function manageLaterData(delivery, vendorData, cb) {
    // manageCurrentTime
    if (vendorData && vendorData.schedule) {
        var manageScheArry = manageScheArryFunction(vendorData);
        const getGenTime = (timeString) => {
            let H = +timeString.substr(0, 2);
            let h = (H % 12) || 12;
            if (h < 10) {
                h = '0'+h;
            }
            let ampm = H < 12 ? "AM" : "PM";
            return timeString = h + timeString.substr(2, 3) + ampm;
        };
        let count = 0;
        const countFun = (row) => {
            if (count >= manageScheArry.length) {
                setTimeout(() => {
                    if (manageCurrentTime(vendorData.tz, 'short', new Date().getTime()) === delivery.scheduleTimeDate) {
                        changeScheduleDay('Today', vendorData, delivery, manageScheArry, (resData) => {
                            if (resData.scheduleTimeData[0] === 'Restaurant Not Available') {
                                changeScheduleDay(manageScheArry[1].date, vendorData, delivery, manageScheArry, (resData1) => {
                                    if (resData1.scheduleTimeData[0] === 'Restaurant Not Available') {
                                        changeScheduleDay(manageScheArry[2].date, vendorData, delivery, manageScheArry, (resData2) => {
                                            cb({
                                                status: true,
                                                data: resData2,
                                                day: 'Tomorrow'
                                            });
                                        })
                                    } else {
                                        cb({
                                            status: true,
                                            data: resData1,
                                            day: 'Tomorrow'
                                        });
                                    }
                                });
                            } else {
                                cb({
                                    status: true,
                                    data: resData,
                                    day: 'Today'
                                });
                            }
                        });
                    } else {
                        changeScheduleDay(delivery.scheduleTimeDate, vendorData, delivery, manageScheArry, (resData) => {
                            if (resData.scheduleTimeData[0] === 'Restaurant Not Available') {
                                changeScheduleDay(manageScheArry[1].date, vendorData, delivery, manageScheArry, (resData1) => {
                                    if (resData1.scheduleTimeData[0] === 'Restaurant Not Available') {
                                        changeScheduleDay(manageScheArry[2].date, vendorData, delivery, manageScheArry, (resData2) => {
                                            cb({
                                                status: true,
                                                data: resData2,
                                                day: 'Tomorrow'
                                            });
                                        })
                                    } else {
                                        cb({
                                            status: true,
                                            data: resData1,
                                            day: 'Tomorrow'
                                        });
                                    }
                                });
                            } else {
                                cb({
                                    status: true,
                                    data: resData,
                                    day: 'Today'
                                });
                            }
                        });
                    }
                }, 500);
                return;
            }
            var testArr = [];
            let scheduleArrayCount = 0;
            const scheduleArrayFun = (scheduleRow) => {
                if (scheduleArrayCount >= row.scheduleArray.length) {
                    count += 1;
                    countFun(manageScheArry[count]);
                    return;
                }
                let fromtime = scheduleRow.door.split('-')[0];
                let totime = scheduleRow.door.split('-')[1];
                function calculateTimeSlot(start_time, end_time, interval = 30){
                    var formatted_time;
                    var time_slots = [];
                    for(var i=start_time; i<=end_time; i = i+interval){
                        formatted_time = convertHours(i);
                        time_slots.push(formatted_time);
                    }
                    if (parseTime(time_slots[time_slots.length-1]) < end_time) {
                        time_slots.push(convertHours(end_time));
                    }
                    for (let ii = 0; ii < time_slots.length; ii += 1) {
                        if (ii === 0) {
                            if (testArr.indexOf(getGenTime(time_slots[0])) === -1) {
                                // testArr.push(getGenTime(time_slots[0]))
                            }
                        } else {
                            if (time_slots[ii+1] !== undefined && testArr.indexOf(getGenTime(time_slots[ii])) === -1) {
                                testArr.push(getGenTime(time_slots[ii]));
                            }
                            
                            if ((time_slots.length - 1) === ii) {
                                testArr.push(getGenTime(time_slots[ii]));
                            }
                        }
                    }
                    return testArr;
                }
                row.timeArray = calculateTimeSlot( parseTime(fromtime, 'add'), parseTime(totime), 30);
                scheduleArrayCount += 1;
                scheduleArrayFun(row.scheduleArray[scheduleArrayCount]);
            }
            if (row && row.scheduleArray) {
                scheduleArrayFun(row.scheduleArray[scheduleArrayCount]);
            }  
        };
        countFun(manageScheArry[count]);
    } else {
        cb({
            status: false
        })
    }
}