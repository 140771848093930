import { getMethod } from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';

export async function getVendorData(cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
    
                    axios.get(apiBaseURL+'get-vendor-data', {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod('get-vendor-data', '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.get(apiBaseURL+'get-vendor-data', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod('get-vendor-data', '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}


export async function getSingleVendorData(vendorId, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.get(apiBaseURL+'getsinglevendors/'+vendorId, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod('getsinglevendors/'+vendorId, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.get(apiBaseURL+'getsinglevendors/'+vendorId, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod('getsinglevendors/'+vendorId, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}