import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import route from "./route";
import $ from "jquery";
// import { getAuth } from './service'
import './index.css';
import "aos/dist/aos.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
var idleTime = 0;
$(document).ready(function () {

    //Increment the idle time counter every minute.
    // eslint-disable-next-line
    var idleInterval = setInterval(timerIncrement, 60000); // 1 minute

    $(this).mousemove(function (e) {
        idleTime = 0;
    });
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.onclick = resetTimer;
    window.onscroll = resetTimer;
    window.onkeypress = resetTimer;
    let queryParameters = window.location.href.split("?");
    removeCampaignAnalyticsQueryParams(queryParameters);

    function resetTimer() {
      idleTime = 0;
    }
    $(this).keypress(function (e) {
        idleTime = 0;
    });
    $(this).keyup(function (e) {
      idleTime = 0;
  });
});

function timerIncrement() {
    idleTime = idleTime + 1;
    if (window.location.pathname.indexOf('loyaltycustomer') !== -1) {
      return;
    }
    if (idleTime > 45) { // 45 minutes
      localStorage.removeItem('addToCard');
      localStorage.removeItem(window.location.href.split('/')[4] + '___delivery');
      window.location.href = `${window.location.origin}/vendorproduct/${window.location.href.split('/')[4]}`
    }
}

function removeCampaignAnalyticsQueryParams(queryParameters){
  let requiredQueryParams = [];
  let mainUrl = "";
  if (queryParameters.length === 2) {
    const queryParams = Object.fromEntries(new URLSearchParams(`?${queryParameters[1]}`));
    if(queryParams && Object.keys(queryParams).length > 0){
      for (var key in queryParams) {
        if(key.search('utm') === -1)  {
          requiredQueryParams.push(`${key}=${queryParams[key]}`);
        }
      }
    }
  } 
  mainUrl = queryParameters[0];
  requiredQueryParams = requiredQueryParams.join("&");
  if(requiredQueryParams !== ""){
    mainUrl = mainUrl + `?${requiredQueryParams}`;
  }
  window.history.replaceState({}, document.title, mainUrl);
}

const initRoutes  = () => {
  return(
    <BrowserRouter>
      <div>
      {route}
      </div>
    </BrowserRouter>
  )
};

const initializedRoutes = initRoutes();
ReactDOM.render(initializedRoutes, document.getElementById("root"));
