import { postMethod, getMethod } from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';
const apiBaseURLV2 = process.env.REACT_APP_APIURL+'/api/v2/';
const apiBaseURLV3 = process.env.REACT_APP_APIURL+'/api/v3/';
const version = 'V2';
const versionV3 = 'V3';
export async function createcustomer(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'createCustomer', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('createCustomer', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        if (error.response) {
                            cb({
                                errorMessage: error.response.data.errorMessage || "Payment error. Please contact support@svang.app if the issue persists."
                            });
                        } else {
                            cb({
                                errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                            });
                        }
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'createCustomer', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('createCustomer', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                if (error.response) {
                    cb({
                        errorMessage: error.response.data.errorMessage || "Payment error. Please contact support@svang.app if the issue persists."
                    });
                } else {
                    cb({
                        errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                    });
                }
            });
        }
    } catch (error) {
        console.error(error);
        cb({
            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
        });
    }
};

export async function chargeCustomerCard(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'chargeCustomerCard', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('chargeCustomerCard', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({
                            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                        });
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'chargeCustomerCard', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('chargeCustomerCard', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({
                    errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                });
            });
        }
    } catch (error) {
        console.error(error);
        cb({
            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
        });
    }
};

export async function chargeCustomerCardV2(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth(version, async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURLV2+'chargeCustomerCard', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('chargeCustomerCard', requestOptions, version, (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({
                            errorMessage: error?.response?.data?.errorMessage ? error.response.data.errorMessage : "Payment error. Please contact support@svang.app if the issue persists."
                        });
                    });
                }
            });
        } else {
            axios.post(apiBaseURLV2+'chargeCustomerCard', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('chargeCustomerCard', requestOptions, version, (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({
                    errorMessage: error?.response?.data?.errorMessage ? error.response.data.errorMessage : "Payment error. Please contact support@svang.app if the issue persists."
                });
            });
        }
    } catch (error) {
        console.error(error);
        cb({
            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
        });
    }
}

export async function createOrder(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth(versionV3, async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURLV3+'createOrder', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('createOrder', requestOptions, versionV3, (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.post(apiBaseURLV3+'createOrder', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('createOrder', requestOptions, versionV3, (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}

export async function getCollectionData(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'getCollectionData', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('getCollectionData', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'getCollectionData', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('getCollectionData', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}

export async function vendorSquareAuth(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'vendorSquareAuth', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('vendorSquareAuth', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'vendorSquareAuth', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('vendorSquareAuth', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export async function validatecouponcode(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth(version, async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURLV2+'coupon/validatecouponcode?db=mongodb', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('coupon/validatecouponcode?db=mongodb', requestOptions, version, (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURLV2+'coupon/validatecouponcode?db=mongodb', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('coupon/validatecouponcode?db=mongodb', requestOptions, version, (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export async function getactivecouponsbylocation(params, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
    
                    axios.get(apiBaseURL+'coupon/getactivecouponsbylocation?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod('coupon/getactivecouponsbylocation?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.get(apiBaseURL+'coupon/getactivecouponsbylocation?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod('coupon/getactivecouponsbylocation?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}

export async function getactivecouponsbyvendor(params, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
    
                    axios.get(apiBaseURL+'coupon/getactivecouponsbyvendor/'+params.vendorId+'?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod('coupon/getactivecouponsbyvendor/'+params.vendorId+'?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.get(apiBaseURL+'coupon/getactivecouponsbyvendor/'+params.vendorId+'?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod('coupon/getactivecouponsbyvendor/'+params.vendorId+'?db='+params.db+'&latitude='+params.latitude+'&longitude='+params.longitude, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}

export async function checkdeliveryquote(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'postmates/checkdeliveryquote', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('postmates/checkdeliveryquote', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'postmates/checkdeliveryquote', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('postmates/checkdeliveryquote', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export async function insertUser(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'insertuser', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('insertuser', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'insertuser', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('insertuser', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};
