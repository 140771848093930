import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';

export async function unsubscribe(requestData, cb) {
    try {
        axios.post(apiBaseURL+'unsubscribe', requestData,  {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',                
            }
        }).then(result => {
            cb(result.data);
        }).catch(error => {
            console.error(error);
            cb({});
        });
    } catch (error) {
        console.error(error);
        cb({});
    }
}

export async function checkSubscriptionStatus(requestData, cb) {
    try {
        axios.post(apiBaseURL+'getSubscriptionStatus', requestData,  {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',                
            }
        }).then(result => {
            cb(result.data);
        }).catch(error => {
            console.error(error);
            cb({});
        });
    } catch (error) {
        console.error(error);
        cb({});
    }
}