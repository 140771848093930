import { postMethod, getMethod } from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';

export async function getLoyaltyCustomerForm(vendorId, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.get(apiBaseURL+`getLoyaltyCustomerForm/${vendorId}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod(`getLoyaltyCustomerForm/${vendorId}`, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.get(apiBaseURL+`getLoyaltyCustomerForm/${vendorId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod(`getLoyaltyCustomerForm/${vendorId}`, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}


export async function insertLoyaltyCustomer(requestOptions, cb) {
    try {

    } catch (error) {
        console.error(error);
        cb({
            errorMessage: "Please contact support@svang.app if the issue persists."
        });
    }
    if (!localStorage.getItem('token')) {
        getAuth('', async (auth) => {
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
                axios.post(apiBaseURL+'insertLoyaltyCustomer', requestOptions, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }).then(result => {
                    if (result.data.success === false) {
                        postMethod('insertLoyaltyCustomer', requestOptions, '', (resData) => {
                            cb(resData);
                        });
                        return;
                    }
                    cb(result.data);
                }).catch(error => {
                    console.error(error);
                    cb({
                        errorMessage: "Please contact support@svang.app if the issue persists."
                    });
                });
            }
        });
    } else {
        axios.post(apiBaseURL+'insertLoyaltyCustomer', requestOptions, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(result => {
            if (result.data.success === false) {
                postMethod('insertLoyaltyCustomer', requestOptions, '', (resData) => {
                    cb(resData);
                });
                return;
            }
            cb(result.data);
        }).catch(error => {
            console.error(error);
            cb({
                errorMessage: "Please contact support@svang.app if the issue persists."
            });
        });
    }
};
