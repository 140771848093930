/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/25/2020, Fixed open cart item that time UI responsive
 */
import React from "react";
import './root.css';


class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <div>
                <p>Server is running</p>
            </div>
        )
    }
}

export default Root;