/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/25/2020, Fixed open cart item that time UI responsive
 */
import React from "react";
import queryString from 'query-string'
import AOS from 'aos';
import {ReactComponent as Spinner} from "../../assets/spinner.svg";
import { getVendorData } from './service';
import noImage from "../../assets/no-image.svg";
import Navi from "../../nav";
import './home.css';

let isResId = false;
let queryData;
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorData: [],
            isSpinner: false,
        };
        
        queryData = queryString.parse(props.location.search);
        if (queryData && queryData.restaurantId) {
            isResId = true;
        }
        if (queryData && queryData.tableNo) {
            localStorage.setItem('tableNo', queryData.tableNo);
        } else {
            localStorage.removeItem('tableNo');
        }

        if (queryData && queryData.dining) {
            localStorage.setItem('dining', queryData.dining);
        } else {
            localStorage.removeItem('dining');
        }

    }
    componentDidMount() {
        // or simply just AOS.init();
        AOS.init({
            duration : 100
        });
        getVendorData((vendors) => {
        // getVendorData().then(vendors => {
            if (vendors && vendors.data && vendors.data.length) {
                if (!isResId) {
                    this.setState({vendorData:vendors.data});
                    setTimeout(() => {
                        this.setState({isSpinner:true});
                    }, 500);
                } else {
                    if (vendors.data && vendors.data.length) {
                        for (let i = 0; i < vendors.data.length; i += 1) {
                            if (queryData.restaurantId === vendors.data[i].id) {
                                localStorage.removeItem(vendors.data[i].id + '___dealCouponAndEmail');
                                let tmpData = [];
                                tmpData.push(vendors.data[i]);
                                localStorage.removeItem('addToCard');
                                this.props.history.push('/vendorproduct/'+ tmpData[0].id, tmpData[0]);
                                break;
                            }
                        }
                    } else {
                        this.setState({vendorData:[]});
                        setTimeout(() => {
                            this.setState({isSpinner:true});
                        }, 100);
                    }
                }
            } else {
                this.setState({vendorData:[]});
                setTimeout(() => {
                    this.setState({isSpinner:true});
                }, 100);
            }
        });
    }
    redirectVendorProduct(row) {
        try {
            if (localStorage.getItem(row.id + '___delivery')) {
                localStorage.removeItem(row.id + '___delivery');
            }
            localStorage.removeItem(row.id + '___dealCouponAndEmail');
            this.props.history.push('/vendorproduct/'+ row.id, row);
        } catch(error) {
            console.error('error', error);
        }
    }
    render() {
        return (
            <div className="">
                <div>
                    <Navi />
                    {this.state.isSpinner && this.state.vendorData && this.state.vendorData.length &&   
                        <div className="sidebar-trigger">
                            <div className="container-fluid">
                                <div className="row resturant-list-row">
                                    {this.state.vendorData.map((row, index) => (
                                        <div className="col-md-3 col-vendor" key={index} data-aos="fade-up" data-aos-delay="100">
                                            <div className="main-vendor-container" onClick={() => this.redirectVendorProduct(row)}>
                                                {row?.photo && 
                                                    <div className="vendor-image-section">
                                                        <img className="vendor-image" src={row.photo} alt={row.title}></img>
                                                    </div>
                                                }
                                                {(!row || !row.photo) &&
                                                    <div className="vendor-image-section">
                                                        <img className="vendor-image" src={noImage} alt={row.title}></img>
                                                    </div>
                                                }
                                                <div className="res-info">
                                                    <h2 className="vendor-title">{row.title}</h2>
                                                    <p>{row.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>    
                        </div>
                    }
                    {this.state.isSpinner && !(this.state.vendorData && this.state.vendorData.length) &&
                        <div className="container-fluid mt-5">
                            <div className="row mt-5">
                                <div className="col-md-12 mt-5">
                                    <h5 className="text-center">No Restaurant Found.</h5>
                                </div>
                            </div>
                        </div>
                    }
                    
                    {this.state && !this.state.isSpinner &&
                        <div className="center-box-cn">
                            <div className="center-box-section">
                                <Spinner/>
                            </div>
                        </div>
                    }
            </div>
            </div>
        )
    }
}

export default Home;