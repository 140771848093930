/**
 * Maintainence Hoistory (Name, Date, Comments)
 * Shivani Zinzuvadia, 10/03/2021, Created unsubscribe page
 */
import React from "react";
import Navi from "../../nav";
import { unsubscribe, checkSubscriptionStatus } from './service';
import './unsubscribe.css';
import successLogo from "../../assets/success-logo.png";
class Unsubscribe extends React.Component {
    constructor(props) {
        super(props);
        this.reasons = [
            "Your emails are not relevant to me.",
            "Your emails are too frequent.",
            "I don't remember signing up for this.",
            "I no longer want to receive these emails.",
            "Other"];
        const urlParams = new URLSearchParams(window.location.search);
        const data = urlParams.get('data');
        const vendorID = urlParams.has('utm_vendor') ? urlParams.get('utm_vendor') : "";
        const uuID = urlParams.has('utm_source') ? urlParams.get('utm_source') : "";
        const campaignID = urlParams.has('utm_campaign') ? urlParams.get('utm_campaign') : "";
        const campaignData = {vendorID, uuID, campaignID};
        const decodedData = window.atob(data)
        const urlData = Object.fromEntries(new URLSearchParams(decodedData));
        this.state = {
            urlData,
            selectedReason: "Your emails are not relevant to me.",
            otherReason: "",
            successResponse: false,
            successMessage: 'You are unsubscribed from our mailing list.',
            campaignData
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.backPage = this.backPage.bind(this)
    }
    componentDidMount(){
        let reqData = {...this.state.urlData}
        checkSubscriptionStatus(reqData, (result) => {
            if(result.status){
                if(result?.response && 'subscriptionStatus' in result.response && result.response.subscriptionStatus === false){
                    this.setState({successResponse: true});
                    this.setState({successMessage: 'Your email not found in our mailing list.'})
                }
            }
        })
    }

    backPage(event){
        event.preventDefault();
        this.props.history.push(`/vendorproduct/${this.state.urlData.vendorID}`);   
    }

    onFormSubmit(event) {
        event.preventDefault();
        let reason = this.state.selectedReason !== "Other" ? this.state.selectedReason : this.state.otherReason;
        let unsubscribeData = {...this.state.urlData, reason, campaignData: this.state.campaignData}
        unsubscribe(unsubscribeData, (result) => {
            if(result.status){
                this.setState({successResponse: true});
            }
        })
    }

    render() {
        const { selectedReason, otherReason, successResponse, successMessage } = {...this.state}
        return (
            <div className="">
                <div>
                    <Navi />
                   
                        <div className="radio-wrapper">
                            <div className="row">
                                <div className="col-xs-12">
                                    <form>
                                        <div className="form-group mb-0fg">
                                            {!successResponse && <div>
                                            <h3>Unsubscribe from our mailing list</h3>

                                            <p>It would be greatful if you could tell us why</p>

                                            {this.reasons && this.reasons.map((reason, index) => (
                                            <div className="radiodelivery" key={index}>
                                                <input id={`radio-${index}`} 
                                                name="radio" 
                                                type="radio" 
                                                value={reason} 
                                                checked={selectedReason === reason ? true : false}
                                                onChange={(event) => {
                                                    this.setState({selectedReason: event.target.value})
                                                }}/>
                                                <label htmlFor={`radio-${index}`} className="radiodelivery-label">{reason}</label>
                                            </div>))}

                                            {selectedReason === "Other" && 
                                            <textarea rows="2" 
                                            className="other-text" 
                                            onChange={(event) =>this.setState({otherReason: event.target.value})} value={otherReason}>
                                            </textarea>}

                                            <div className="submit-btn-wrapper">
                                                <button type="submit" className="btn btn-submit" onClick={(event) => {this.onFormSubmit(event)}}>Submit</button>
                                            </div>
                                            </div>}

                                            {successResponse && <div className="success-msg">
                                                <img src={successLogo} alt="success-logo"></img>
                                                <p>{successMessage}</p>
                                            </div>}

                                            <a href="\#" className="botttom-link" onClick={(event) => {this.backPage(event)}}>Return to our Website</a>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                   
                </div>
            </div>
        )
    }
}

export default Unsubscribe;