import { getAuth } from '../service';
import axios from 'axios'
let apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';
const apiBaseURLV2 = process.env.REACT_APP_APIURL+'/api/v2/';
const apiBaseURLV3 = process.env.REACT_APP_APIURL+'/api/v3/';
export function getMethod(url, version, cb) {
    try {
        getAuth(version, (auth) => {
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
                const getData = async (url) => {
                    try {
                        if (version === 'V2') {
                            apiBaseURL = apiBaseURLV2;
                        }
                        if (version === 'V3') {
                            apiBaseURL = apiBaseURLV3;
                        }
                        axios.get(apiBaseURL+url, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + auth.token
                            }
                        }).then(result => {
                            cb(result.data);
                        }).catch(error => {
                            console.error(error);
                            cb({});
                        });
                    } catch (error) {
                        console.error(error);
                        cb({})
                    }
                }
                getData(url);
            }
        })
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export function postMethod(url, requestOptions, version, cb) {
    try {
        getAuth(version, (auth) => {
            if (auth && auth.success && auth.token) {
                localStorage.setItem('token', auth.token);
                const postData = async (url, requestOptions) => {
                    try {
                        if (version === 'V2') {
                            apiBaseURL = apiBaseURLV2;
                        }
                        if (version === 'V3') {
                            apiBaseURL = apiBaseURLV3;
                        }
                        axios.post(apiBaseURL+url, requestOptions, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + auth.token
                            }
                        }).then(result => {
                            cb(result.data);
                        }).catch(error => {
                            console.error(error);
                            cb({});
                        });
                    } catch (error) {
                        console.error(error);
                        cb({});
                    }
                }
                postData(url, requestOptions);
            }
        });
    } catch (error) {
        console.error(error);
        cb({});
    }
};

