import { postMethod} from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';


export async function payment3d(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'payment3d', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('payment3d', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({
                            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                        });
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'payment3d', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('payment3d', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({
                    errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
                });
            });
        }
    } catch (error) {
        console.error(error);
        cb({
            errorMessage: "Payment error. Please contact support@svang.app if the issue persists."
        });
    }
}
