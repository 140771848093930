import React from 'react';
import Helmet from 'react-helmet';
import $ from "jquery";
import queryString from 'query-string'
import {Modal, Button, InputGroup, FormControl, Media} from "react-bootstrap";
import { 
    createcustomer,
    chargeCustomerCardV2,
    createOrder,
    vendorSquareAuth,
    validatecouponcode,
    getactivecouponsbyvendor,
    checkdeliveryquote,
    insertUser
} from './service'
import { getVendorData, getSingleVendorData } from '../home/service'
import './checkout.css';
import {ReactComponent as Spinner} from "../../assets/spinner.svg";
import {ReactComponent as Deal} from "../../assets/deal.svg";
import {ReactComponent as DealRed} from "../../assets/dealRed.svg";
import {ReactComponent as DealSuccess} from "../../assets/dealSuccess.svg";
const atob = require('atob');
const crypto = require('crypto');
const algorithm = process.env.REACT_APP_ALGORITHM;
const secretKey = process.env.REACT_APP_SECRETKEY;
const decrypt = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);
    return decrpyted.toString();
};
 
function evenRound(num, decimalPlaces) {
    var d = decimalPlaces || 0;
    var m = Math.pow(10, d);
    var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    var i = Math.floor(n), f = n - i;
    var e = 1e-8; // Allow for rounding errors in f
    var r = (f > 0.5 - e && f < 0.5 + e) ?
    // eslint-disable-next-line
                ((i % 2 == 0) ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
}
 
function manageServiceCharge1(key, serviceCharge, selectedVendorData, deliveryCharge, saving) {
    if (!serviceCharge) {
        return 0;
    }
    if (!key) {
        key = 'percentage'
    }
    if (key === 'fix') {
        return serviceCharge;
    }
    if (key === 'percentage') {
        var total_Data = addToCartData.reduce((acc, item) => acc + parseFloat(Number(item.totalAmount)*item.quantity ? Number(item.totalAmount)*item.quantity : 0),0);
        if (isNaN(total_Data)) {
            total_Data = 0.00;
        }
        const orderObj = JSON.parse(localStorage.getItem(selectedVendorData.id + '___orderObj'))

        let minimumOrderCharge = 0;
        if (orderObj && orderObj.DeliveryType === 'Delivery') {
            minimumOrderCharge = Number(selectedVendorData.minimumOrderCharge) - Number(total_Data);
        } else {
            deliveryCharge = 0;
        }
        
        const finalTotal = (total_Data-saving) + (minimumOrderCharge > 0 ? minimumOrderCharge: 0) + deliveryCharge;
        return (finalTotal*Number(serviceCharge))/100;
    }
}
 
let addToCartData = [];
let selectedVendorData = {};
let tableNo = '';
let APPLICATION_ID = '';
let LOCATION_ID = '';
let vendorId = '';
let queryData = {};
let orderData = {};
let queryParameters = window.location.href.split("?");
class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addToCartData: [],
            subTotal: '',
            selectedVendorData: {},
            tableNo: '',
            fields: {firstName: '', lastName: '', emailid: '', mobileno: ''},
            errors: {},
            isLoad: false,
            pageLoad: false,
            errorMessages: [],
            paymentLineItems: [],
            isActivekey: 2,
            isSubscribe: true,
            tipObj: {},
            customTipModelShow: false,
            customTipAmount: '',
            tipData: [],
            dynamicUrl: '',
            isOpenCard: false,
            orderObj: {},
            paymentError: '',
            isPayNow: true,
            paymentMethod: 'payNow',
            couponcode: '',
            couponDetailsName: '',
            inputcouponcode: '',
            inputcouponcodeError: false,
            dealsModelShow: false,
            dealsData: {},
            dealDefaultErrorStatus: true,
            dealErrorStatus: false,
            dealErrorMessage: '',
            emailDealMessage: '',
            saving: 0,
            freeDelivery: false,
            delivery_charge: '',
            dealData: {},
            deliveryInstruction: '',
            deliveryErrorMesssage: '',
            displayDeliveryErrorMesssage: false,
            deliveryQuote: {},
            minimumOrderCharge: 0
        };
        queryData = queryString.parse(props.location.search);
        if (queryData && Object.keys(queryData).length) {
            orderData = JSON.parse(
                decrypt({
                    iv: atob(queryData.id),
                    content: queryData.orderData
                })
            )
        }
        this.tipChangeAmount = this.tipChangeAmount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deliveryInstructionChange = this.deliveryInstructionChange.bind(this);
        this.dealhandleChange = this.dealhandleChange.bind(this);
        this.submitPayForm = this.submitPayForm.bind(this);
        this.cardNonceResponseReceived = this.cardNonceResponseReceived.bind(this);
    }
    componentDidMount() {
        getSingleVendorData(this.props.match.params.vendorId, (data) => {
            if (data.status) {
                selectedVendorData = data.data;
                if (orderData && Object.keys(orderData).length) {
                    const removeCartItem = JSON.parse(localStorage.getItem('addToCard'));
                    const tmpremoveCartItem = [];
                    let cartItems = {};
                    if (removeCartItem) {
                        for (let i = 0; i < removeCartItem.cartItems.length; i += 1) {
                            if (removeCartItem.cartItems[i].vendorID !== selectedVendorData.id) {
                                tmpremoveCartItem.push(removeCartItem.cartItems[i]);
                            }
                        }
                        cartItems = {
                            cartItems: [...orderData.products, ...tmpremoveCartItem]
                        }
                    } else {
                        cartItems = {
                            cartItems: orderData.products
                        }
                    }
                    this.setState({
                        fields: {
                            emailid: orderData?.userDetails?.email || '',
                            firstName: orderData?.userDetails?.firstName || '',
                            lastName: orderData?.userDetails?.lastName || '',
                            mobileno: orderData?.userDetails?.phone || ''
                        }
                    });
                    localStorage.setItem('addToCard', JSON.stringify(cartItems));
                    addToCartData = orderData.products;
                    let pickupData = {
                        DeliveryType: 'Pick at Restaurant',
                        isUnivercity: false,
                        orderType: 'Order Now'
                    };
                    localStorage.setItem(this.props.match.params.vendorId + '___orderObj', JSON.stringify(pickupData));
                    pickupData.isActiveAddressType = 2;
                    localStorage.setItem(this.props.match.params.vendorId + '___delivery', JSON.stringify(pickupData));
                    orderData = {};
                    window.history.replaceState({}, document.title, queryParameters[0]);
                }
                addToCartData = this.props?.history?.location?.state?.addToCard?.cartItems;
                if (!addToCartData) {
                    const tmpCartItem = JSON.parse(localStorage.getItem('addToCard')).cartItems;
                    const tmpUseVendorId = this.props.match.params.vendorId;
                    let newCartItem = []; 
                    for (let i = 0; i < tmpCartItem.length; i += 1) {
                        if (tmpCartItem[i].vendorID === tmpUseVendorId) {
                            newCartItem.push(tmpCartItem[i]);
                        }
                    }
                    addToCartData = newCartItem;
                }
                if (localStorage.getItem('tableNo')) {
                    tableNo = localStorage.getItem('tableNo');
                }
                if (selectedVendorData.directPayment) {
                    vendorId = selectedVendorData.id;
                }
                vendorSquareAuth({
                    vendorId: vendorId
                }, (squareAuth) => {
                    if (!squareAuth.status) {
                        this.setState({
                            paymentError: squareAuth.statusText,
                        });
                        return;
                    }
                    APPLICATION_ID = squareAuth.data.applicationID;
                    LOCATION_ID = squareAuth.data.locationID;
                    this.initSquareUI();
                    this.setState({isOpenCard: true});
                });
                if (!selectedVendorData.delivery_charge) {
                    selectedVendorData.delivery_charge = "0";
                }
                const mainServiceCharge = selectedVendorData.serviceCharge;
                selectedVendorData.updateServiceCharge = manageServiceCharge1(selectedVendorData.serviceChargeType, mainServiceCharge, selectedVendorData, 0, this.state.saving);
                if (!(addToCartData && addToCartData.length)) {
                    const passUrl = `/vendorproduct/${selectedVendorData.id}`;
                    this.props.history.push(passUrl);
                    return;
                }
                this.setState({addToCartData: addToCartData, selectedVendorData: selectedVendorData, delivery_charge: selectedVendorData.delivery_charge || '0', tableNo: tableNo});
                if (addToCartData && addToCartData.length) {
                    let tmpPaymentLineItems = [];
                    for (let i = 0; i < addToCartData.length; i += 1) {
                        let tmpVariant = {};
                        let note = '';
                        if (addToCartData[i].variant && addToCartData[i].variant.length) {
                            for (let ii = 0; ii < addToCartData[i].variant.length; ii += 1) {
                                tmpVariant[addToCartData[i].variant[ii].title.replace(/ /g, '-')] = addToCartData[i].variant[ii].name;
                                if (addToCartData[i].variant[ii] && addToCartData[i].variant[ii].price) {
                                    note += `${addToCartData[i].variant[ii].name}($${addToCartData[i].variant[ii].price})`;
                                } else {
                                    note += addToCartData[i].variant[ii].name;
                                }
                                if ((addToCartData[i].variant.length-1) !== ii) {
                                    note += ', ';
                                } 
                            }
                        }
        
                        if (addToCartData[i].add_special_instructions) {
                            if (addToCartData[i].variant && addToCartData[i].variant.length) {
                                note += `, Special Instructions: ${addToCartData[i].add_special_instructions}`;
                            } else {
                                note += `Special Instructions: ${addToCartData[i].add_special_instructions}`;
                            }
                        }
                        tmpPaymentLineItems.push({
                            "quantity": addToCartData[i].quantity.toString(),
                            "base_price_money": {
                                "amount": Number((addToCartData[i].totalAmount*100).toFixed(2)),
                                "currency": "USD"
                            },
                            "note": note,
                            "name": addToCartData[i].name,
                            "metadata": tmpVariant
                        });
                    }
                    this.setState({paymentLineItems: tmpPaymentLineItems});
                }
                this.setState({dynamicUrl: `/vendorproduct/${selectedVendorData.id}`});
                if (selectedVendorData && selectedVendorData.tips && selectedVendorData.tips.value && selectedVendorData.tips.value.length) {
                    this.setState({tipData: selectedVendorData.tips.value, isActivekey: selectedVendorData.tips.default+1});
                } else {
                    this.setState({tipData: [], isActivekey: 0});
                }
                if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                    if (this?.props?.history?.location?.state?.orderObj) {
                        if (!localStorage.getItem(selectedVendorData.id + '___orderObj')) {
                            localStorage.setItem(selectedVendorData.id + '___orderObj', JSON.stringify(this.props.history.location.state.orderObj));
                            this.setState({
                                orderObj: this.props.history.location.state.orderObj
                            });
                        } else {
                            this.setState({
                                orderObj: JSON.parse(localStorage.getItem(selectedVendorData.id + '___orderObj'))
                            });
                        }
                        if (this?.props?.history?.location?.state?.orderObj
                            && this.props.history.location.state.orderObj.DeliveryType === 'Delivery'
                            && JSON.parse(localStorage.getItem(selectedVendorData.id + '___orderObj')).DeliveryType === 'Delivery') {
                            selectedVendorData.updateServiceCharge = manageServiceCharge1(selectedVendorData.serviceChargeType, mainServiceCharge, selectedVendorData, Number(selectedVendorData.delivery_charge), this.state.saving);
                            this.setState({
                                selectedVendorData: selectedVendorData
                            })
                            this.manageDeliveryCharge();
                        }
                    } else {
                        let tmpOrderObj = JSON.parse(localStorage.getItem(selectedVendorData.id + '___orderObj'));
                        this.setState({
                            orderObj: tmpOrderObj
                        });
                        if (tmpOrderObj
                            && tmpOrderObj.DeliveryType === 'Delivery') {
                            selectedVendorData.updateServiceCharge = manageServiceCharge1(selectedVendorData.serviceChargeType, mainServiceCharge, selectedVendorData, Number(selectedVendorData.delivery_charge), this.state.saving);
                            this.setState({
                                selectedVendorData: selectedVendorData
                            })
                            this.manageDeliveryCharge();
                        }
                    }
                    setTimeout(() => {
                        selectedVendorData.updateServiceCharge = manageServiceCharge1(selectedVendorData.serviceChargeType, mainServiceCharge, selectedVendorData, Number(selectedVendorData.delivery_charge), this.state.saving);
                        this.setState({
                            selectedVendorData: selectedVendorData
                        })
                        this.totalAmout(selectedVendorData);
                    }, 500);
                }
                if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
                    setTimeout(() => {
                        selectedVendorData.updateServiceCharge = manageServiceCharge1(selectedVendorData.serviceChargeType, mainServiceCharge, selectedVendorData, Number(selectedVendorData.delivery_charge), this.state.saving);
                        this.setState({
                            selectedVendorData: selectedVendorData
                        })
                        this.totalAmout(selectedVendorData);
                    }, 500);
                }
                if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
                    if (selectedVendorData.payLater && selectedVendorData.paymentDisableDineIn) {
                        this.setState({
                            isPayNow: false,
                            paymentMethod: 'payLater'
                        });
                    }
                    if (selectedVendorData.payLater && !selectedVendorData.paymentDisableDineIn) {
                        this.setState({
                            isPayNow: true,
                            paymentMethod: 'payNow'
                        });
                    }
                } else {
                    this.setState({
                        isPayNow: true
                    });
                }
                if (JSON.parse(localStorage.getItem(selectedVendorData.id + '___dealCouponAndEmail')) && Object.keys(JSON.parse(localStorage.getItem(selectedVendorData.id + '___dealCouponAndEmail')).length > 0)) {
                    const dealCouponAndEmail = JSON.parse(localStorage.getItem(selectedVendorData.id + '___dealCouponAndEmail'));
                    if (dealCouponAndEmail.couponcode && dealCouponAndEmail.email) {
                        this.setState({
                            couponcode: dealCouponAndEmail.couponcode,
                            fields: {emailid: dealCouponAndEmail.email},
                        });
                        setTimeout(() => {
                            this.finalApplyDealCode(dealCouponAndEmail.couponcode);
                        }, 500);
                    }
                }
                const params = {
                    db: 'mongodb',
                    latitude: selectedVendorData.latitude,
                    longitude: selectedVendorData.longitude,
                    vendorId: selectedVendorData.id
                }
                getactivecouponsbyvendor(params, (activeCouponCode) => {
                    activeCouponCode.response.activeDeals = [];
                    this.setState({
                        dealsData: activeCouponCode
                    });
                });
            } else {
                console.error('Something Wrong')
            }
        });
    }
    async initSquareUI() {
        let self = this;
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }
        let payments;
        try {
            payments = window.Square.payments(APPLICATION_ID, LOCATION_ID);
        } catch {
        //   const statusContainer = document.getElementById(
        //     'payment-status-container'
        //   );
        //   statusContainer.className = 'missing-credentials';
        //   statusContainer.style.visibility = 'visible';
          return;
        }
  
        let card;
        try {
            card = await self.initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }
  
        async function handlePaymentMethodSubmission(
            event,
            paymentMethod,
            price,
            shouldVerify = false
        ) {
            event.preventDefault();
            try {
                // disable the submit button as we await tokenization and make a payment request.
                if (price === "NaN") {
                    price = cardButton.getAttribute('data-price');
                }
                cardButton.disabled = true;
                const token = await self.tokenize(paymentMethod);
                let verificationToken;
                if (shouldVerify) {
                    verificationToken = await self.verifyBuyer(payments, token, price);
                }
                self.createPayment(
                    token,
                    verificationToken
                );
                // self.displayPaymentResults('SUCCESS');
                // console.debug('Payment Success', paymentResults);
            } catch (e) {
                cardButton.disabled = false;
                // self.displayPaymentResults('FAILURE');
            }
        }
  
        const cardButton = document.getElementById('card-button');
        const price = cardButton.getAttribute('data-price');
        cardButton.addEventListener('click', async function (event) {
            if (!self.validateForm()) {
                return;
            }
            // SCA only needs to be run for Card Payments. All other payment methods can be set to false.
            handlePaymentMethodSubmission(event, card, price, true);
        });
    }
    async tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            throw new Error(
            `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
            );
        }
      }
    async initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    }
    // verificationToken can be undefined, as it does not apply to all payment methods.
    async createPayment(token, verificationToken) {
        const bodyParameters = {
            locationId: LOCATION_ID,
            sourceId: token,
        };

        if (verificationToken !== undefined) {
            bodyParameters.verificationToken = verificationToken;
        }
        this.cardNonceResponseReceived(null, token, bodyParameters)
    }
  
    async verifyBuyer(payments, token, price) {
        const verificationDetails = {
            amount: price,
            billingContact: {
                // addressLines: ['123 Main Street', 'Apartment 1'],
                // familyName: 'shyam',
                // givenName: 'alian',
                // email: 'shyamalian1211@gmail.com',
                // country: 'GB',
                // phone: '3214563987',
                // region: 'LND',
                // city: 'London',
            },
            currencyCode: 'USD',
            intent: 'CHARGE',
            // intent: 'STORE',
        };
        const verificationResults = await payments.verifyBuyer(
            token,
            verificationDetails
        );
        return verificationResults.token;
    }
    manageDeliveryCharge() {
        this.setState({
            pageLoad: true
        });
        const maximumDeliveryCharge = selectedVendorData.maximumDeliveryCharge;
        const serviceCharge = selectedVendorData.updateServiceCharge;
        let orderObj = {};
        if (selectedVendorData) {
            orderObj = JSON.parse(localStorage.getItem(selectedVendorData.id + '___orderObj'));
        }
        if (selectedVendorData.deliveryMethod === '' || selectedVendorData.deliveryMethod === undefined || selectedVendorData.deliveryMethod === 'dyod') {
            if (!selectedVendorData.delivery_charge) {
                selectedVendorData.delivery_charge = '0';
            }
            this.setState({
                delivery_charge: selectedVendorData.delivery_charge,
                deliveryQuote: {},
                deliveryErrorMesssage: ''
            });
            setTimeout(() => {
                this.tipDefult(selectedVendorData.tips.default+1, selectedVendorData.tips.value[selectedVendorData.tips.default]);
                this.setState({
                    pageLoad: false
                });
            }, 1000);
        } else if (selectedVendorData.deliveryMethod === 'postmates') {
            checkdeliveryquote({
                vendorId: selectedVendorData.id,
                dropoff_address: orderObj.line1,
                dropoff_latitude: orderObj.location.latitude,
                dropoff_longitude: orderObj.location.longitude,
                pickup_address: selectedVendorData.address,
                pickup_latitude: selectedVendorData.latitude,
                pickup_longitude: selectedVendorData.longitude
            }, (quoteData) => {
                if (!quoteData.status) {
                    this.setState({
                        pageLoad: false,
                        deliveryQuote: {},
                        deliveryErrorMesssage: 'Delivery driver not availble at your location, please change this order to pickup',
                        displayDeliveryErrorMesssage: true
                    });
                    return;
                }
                if (Object.keys(quoteData) && Object.keys(quoteData).length === 0) {
                    this.setState({
                        pageLoad: false,
                        deliveryQuote: {},
                        deliveryErrorMesssage: 'Delivery driver not availble at your location, please change this order to pickup',
                        displayDeliveryErrorMesssage: true
                    });
                    return;
                }
                const quoteFee = quoteData.data.fee;
                const totalDeliveryFee = quoteFee;
                if (totalDeliveryFee > Number(maximumDeliveryCharge*100)) {
                    this.setState({
                        delivery_charge: (maximumDeliveryCharge-serviceCharge).toFixed(2),
                        deliveryQuote: quoteData.data,
                        deliveryErrorMesssage: 'Delivery driver not availble at your location, please change this order to pickup',
                        displayDeliveryErrorMesssage: true
                    });
                    setTimeout(() => {
                        this.tipDefult(selectedVendorData.tips.default+1, selectedVendorData.tips.value[selectedVendorData.tips.default]);
                        this.setState({
                            pageLoad: false
                        });
                    }, 1000);
                    return;
                }
                if (totalDeliveryFee <= Number(maximumDeliveryCharge*100)) {
                    this.setState({
                        delivery_charge: ((quoteFee/100)-serviceCharge).toFixed(2),
                        deliveryQuote: quoteData.data,
                        deliveryErrorMesssage: ''
                    });
                    setTimeout(() => {
                        this.tipDefult(selectedVendorData.tips.default+1, selectedVendorData.tips.value[selectedVendorData.tips.default]);
                        this.setState({
                            pageLoad: false
                        });
                    }, 1000);
                    return;
                }
            });
        }
    }
    cardNonceResponseReceived(errors, nonce, cardData) {
        if (this.validateForm()) {
            if (errors) {
                const tmpErr = errors.map(error => error.message);
                this.setState({
                    errorMessages: tmpErr
                });
                return;
            }
            const cardButton = document.getElementById('card-button');
            this.setState({errorMessages: []});
            this.setState({isLoad: true});
            if (this.state.deliveryErrorMesssage) {
                setTimeout(() => {
                    this.setState({
                        isLoad: false,
                        displayDeliveryErrorMesssage: true
                    });
                    $(window).scrollTop(0);
                }, 3000);
                return
            }
            // User Insert
            insertUser({
                email: this.state.fields.emailid,
                firstName: this.state.fields.firstName,
                lastName: this.state.fields.lastName,
                phone: "+1"+this.state.fields.mobileno,
                student: false,
                isSubscribe: this.state.isSubscribe,
                vendorId: this.state.selectedVendorData.id,
                orderSource: 'Svang Website'
            }, () =>{});
            createcustomer({
                email_address: this.state.fields.emailid,
                family_name: this.state.fields.firstName + ' ' + this.state.fields.lastName,
                phone_number: "+1"+this.state.fields.mobileno,
                vendorId: vendorId
            }, (customerRes) => {
                if (customerRes && customerRes.errorMessage) {
                    this.setState({isLoad: false});
                    cardButton.disabled = false;
                    this.setState({
                        paymentError: customerRes.errorMessage,
                    });
                    $(window).scrollTop(0);
                    return;
                }
                const service_charges = [{
                    amount_money: {
                        amount: Number((Number(this.state.tipObj.amount)*100).toFixed(0)),
                        currency: 'USD',
                    },
                    calculation_phase: 'TOTAL_PHASE',
                    name: 'Tips',
                    taxable: false,
                }];
                if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                    if (this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData && this.state.delivery_charge) {
                        if (this.state.minimumOrderCharge > 0) {
                            service_charges.push({
                                amount_money: {
                                    amount: Number(
                                    (
                                        Number(this.state.minimumOrderCharge) * 100
                                    ).toFixed(0),
                                    ),
                                    currency: 'USD',
                                },
                                calculation_phase: 'SUBTOTAL_PHASE',
                                name: 'Minimum Order Charge',
                                taxable: true,
                            });
                        }
                        service_charges.push({
                            amount_money: {
                                amount: Number(
                                (
                                    Number(this.state.delivery_charge) * 100
                                ).toFixed(0),
                                ),
                    
                                currency: 'USD',
                            },
                            calculation_phase: 'SUBTOTAL_PHASE',
                            name: 'DeliveryCharge',
                            taxable: this.state.selectedVendorData?.deliveryTaxable ? true : false,
                        });
                    }
                }
                if (this.state.selectedVendorData && this.state.selectedVendorData.updateServiceCharge > 0) {
                    service_charges.push({
                        amount_money: {
                            amount: Number(
                            (
                                Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) * 100
                            ).toFixed(0),
                            ),
                
                            currency: 'USD',
                        },
                        calculation_phase: 'SUBTOTAL_PHASE',
                        name: 'Service Charge',
                        taxable: this.state.selectedVendorData?.serviceChargeTaxable ? true : false,
                    });
                }
                let deliveryType = '';
                let orderType = '';
                let orderDate;
                let customerDetails = {
                    email_address: this.state.fields.emailid,
                    family_name: this.state.fields.firstName + ' '+ this.state.fields.lastName,
                    phone_number: "+1"+this.state.fields.mobileno,
                }
                if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
                    deliveryType = 'Dine In';
                }
                if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                    if (this.state.orderObj.DeliveryType === 'Pick at Restaurant' || this.state.orderObj.DeliveryType === 'Delivery') {
                        deliveryType = this.state.orderObj.DeliveryType;
                        orderType = this.state.orderObj.orderType;
                        if (this.state.orderObj && this.state.orderObj.orderType && this.state.orderObj.orderType === 'Order Now') {
                            orderDate = new Date();
                        }
                        if (this.state.orderObj && this.state.orderObj.orderType && this.state.orderObj.orderType === 'Schedule For Later') {
                            orderDate = {
                                scheduleDate: this.state.orderObj.scheduleDate,
                                scheduleTime: this.state.orderObj.scheduleTime
                            };
                        }
                    }
                }
                const paymentObj = {
                    "vendorId": vendorId,
                    "deliveryType": deliveryType,
                    "orderType": orderType,
                    "tz": this.state.selectedVendorData.tz,
                    "inDineLeadTime": this.state.selectedVendorData?.inDineLeadTime ? this.state.selectedVendorData.inDineLeadTime : 30,
                    "pickupLeadTime": this.state.selectedVendorData?.pickupLeadTime ? this.state.selectedVendorData.pickupLeadTime : 30,
                    "deliveryLeadTime": this.state.selectedVendorData?.deliveryLeadTime ? this.state.selectedVendorData.deliveryLeadTime: 45,
                    "orderDate": orderDate,
                    "customerDetails": customerDetails,
                    "nonce": nonce,
                    "accept_partial_authorization": false,
                    "autocomplete": true,
                    "customer_id": customerRes.customer.id,
                    "buyer_email_address": this.state.fields.emailid,
                    "location_id": LOCATION_ID,
                    "note": "",
                    "order": {
                        "line_items": this.state.paymentLineItems,
                        "service_charges": service_charges,
                        "taxes": [{
                            "name": "tax",
                            "percentage": this.state.selectedVendorData.tax,
                            "type": "ADDITIVE",
                            "scope": "ORDER"
                        }]
                    }
                };
                let paymentStatus = 'paid';
                if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')
                    && this.state.orderObj.DeliveryType === 'Delivery' && this.state?.selectedVendorData?.deliveryMethod) {
                        paymentObj.autocomplete = false;
                        paymentStatus = 'pending';
                }

                if (this.state.saving > 0) {
                    paymentObj.order.discounts = [{
                        "amount_money": {
                            "amount": Number(
                                (
                                Number(this.state.saving) * 100
                                ).toFixed(0),
                            ),
                            "currency": "USD"
                        },
                        "name": this.state.couponcode,
                        "type": "FIXED_AMOUNT",
                        "scope": "ORDER"
                    }]
                }

                if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true'
                    && this.state.selectedVendorData && this.state.selectedVendorData.payLater
                    && this.state.paymentMethod === 'payLater' && !this.state.isPayNow) {
                        paymentObj.payLater = true;
                        paymentStatus = 'COD';
                }
                paymentObj.verificationToken = cardData?.verificationToken ? cardData.verificationToken : "";
                chargeCustomerCardV2(paymentObj, (paymentResponse) => {
                    if (paymentResponse && paymentResponse.errorMessage) {
                        this.setState({isLoad: false});
                        cardButton.disabled = false;
                        this.setState({
                            paymentError: paymentResponse.errorMessage,
                        });
                        $(window).scrollTop(0);
                        return;
                    }
                    if (paymentResponse.customerCardResponse && paymentResponse.payment) {
                        let tmpServiceChargeValue = 0;
                        if (this?.state?.selectedVendorData?.serviceChargeTaxable) {
                            tmpServiceChargeValue = this.state.selectedVendorData.updateServiceCharge;
                        }


                        let tmpTaxAmount = Number(evenRound(((((
                            (Number(this.state.subTotal)-this.state.saving)+Number(parseFloat(tmpServiceChargeValue).toFixed(2)))
                            ) * Number(this.state.selectedVendorData.tax))/100), 2));
                        if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable) {
                            tmpTaxAmount = Number(evenRound(((((
                                Number(this.state.subTotal)-this.state.saving)+
                                Number(this.state.minimumOrderCharge)+
                                Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                                Number(parseFloat(tmpServiceChargeValue).toFixed(2))
                                ) * Number(this.state.selectedVendorData.tax))/100), 2));
                        }
                        if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable) {
                            tmpTaxAmount = Number(evenRound(((((
                                Number(this.state.subTotal)-this.state.saving)+
                                Number(this.state.minimumOrderCharge)+
                                Number(parseFloat(tmpServiceChargeValue).toFixed(2))
                                ) * Number(this.state.selectedVendorData.tax))/100), 2));
                        }
                        const tmpupdateServiceCharge = this.state.selectedVendorData.updateServiceCharge;
                        const tmpvendorId = this.state.selectedVendorData.id;
                        getVendorData((vendors) => {
                        if (vendors && vendors.data && vendors.data.length) {
                            for (let i = 0; i < vendors.data.length; i += 1) {
                                if (tmpvendorId === vendors.data[i].id) {
                                    vendors.data[i].updateServiceCharge = tmpupdateServiceCharge;
                                    this.setState({
                                        selectedVendorData: vendors.data[i]
                                    })
                                    break;
                                }
                            }
                        }
                        const orderObj = {
                            paymentId: paymentResponse?.payment?.paymentId ? paymentResponse.payment.paymentId : "",
                            versionToken: paymentResponse?.payment?.versionToken ? paymentResponse.payment.versionToken : "",
                            author: {
                                email: this.state.fields.emailid || '',
                                firstName: this.state.fields.firstName || '',
                                lastName: this.state.fields.lastName || '',
                                phone: "+1"+this.state.fields.mobileno || ''
                            },
                            paymentCardInfo: {
                                cardType: paymentResponse?.payment?.card_details?.card?.card_type ? paymentResponse.payment.card_details.card.card_type : "",
                                cardBrand: paymentResponse?.payment?.card_details?.card?.card_brand ? paymentResponse.payment.card_details.card.card_brand : "",
                                cardNumber: paymentResponse?.payment?.card_details?.card?.last_4 ? 'X'+paymentResponse.payment.card_details.card.last_4 : "",
                                paymentApproval: paymentResponse?.payment?.status ? paymentResponse.payment.status : ""
                            },
                            paymentType: "Prepaid",
                            paymentStatus: paymentStatus,
                            deliveryInstruction: this.state.deliveryInstruction || '',
                            printed: false,
                            products: this.state.addToCartData,
                            ordervia: 'web',
                            platform: '',
                            resturantDetails: {
                                deliveryCharge: 0,
                                resturantDetails: this.state.selectedVendorData,
                                minimumOrderCharge: Number(this?.state?.minimumOrderCharge ? this.state.minimumOrderCharge : 0),
                                taxAmount: tmpTaxAmount,
                                tipAmount: this.state.tipObj.amount,
                                serviceCharge: Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)),
                                total: ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2)
                            },
                            status: 'Pending',
                            vendorID: this.state.selectedVendorData.id,
                            orderSource: 'Svang Website'
                        };
                        if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true'
                            && this.state.selectedVendorData && this.state.selectedVendorData.payLater
                            && this.state.paymentMethod === 'payLater' && !this.state.isPayNow) {
                                orderObj.paymentCardInfo = {};
                                orderObj.payLater = true;
                                orderObj.paymentType = "Pay later";
                        }
                        if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
                            orderObj.deliveryType = 'Dine In';
                        }
                        if (this.state.tableNo) {
                            orderObj.tableNo = this.state.tableNo;
                        }
                        if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                            orderObj.address = this.state.orderObj;
                            orderObj.orderType = this.state.orderObj.orderType;
                            orderObj.deliveryType = this.state.orderObj.DeliveryType;
                            if (this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData && this.state.delivery_charge) {
                                orderObj.deliveryMethod = this.state.selectedVendorData.deliveryMethod;
                                orderObj.deliveryQuote = this.state.deliveryQuote;
                                orderObj.resturantDetails.deliveryCharge = Number(this.state.delivery_charge);

                                if (this.state.selectedVendorData.updateServiceCharge && this.state.selectedVendorData.updateServiceCharge > 0) {
                                    orderObj.resturantDetails.serviceCharge = Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2));
                                    orderObj.resturantDetails.total = ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(this.state.delivery_charge) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2);
                                    if (this.state.selectedVendorData.deliveryMethod === 'postmates') {
                                        orderObj.isPostmatesCreateDelivery = false;
                                    }
                                } else {
                                    orderObj.resturantDetails.serviceCharge = 0;
                                    orderObj.resturantDetails.total = ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(this.state.delivery_charge) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2);
                                    if (this.state.selectedVendorData.deliveryMethod === 'postmates') {
                                        orderObj.isPostmatesCreateDelivery = false;
                                    }
                                }
                            }
                        } else {
                            orderObj.address = {};
                        }
                        if (this.state.dealData && Object.keys(this.state.dealData).length > 0) {
                            orderObj.resturantDetails.dealCodeDetails = this.state.dealData;
                        }
                        orderObj.resturantDetails.savings = this.state.saving;
                        createOrder(orderObj, (orderData) => {
                            if (orderData && !orderData.status) {
                                if (orderData.key && orderData.key === 'driver_assign') {
                                    this.setState({
                                        deliveryErrorMesssage: 'Delivery driver not availble at your location, please change this order to pickup',
                                        isLoad: false,
                                        displayDeliveryErrorMesssage: true
                                    });
                                    $(window).scrollTop(0);
                                    return;    
                                }
                                if (orderData.key && orderData.key === 'payment_error') {
                                    cardButton.disabled = false;
                                    this.setState({
                                        paymentError: 'Payment error. Please contact support@svang.app if the issue persists.',
                                        isLoad: false
                                    });
                                    $(window).scrollTop(0);
                                    return;    
                                }
                                cardButton.disabled = false;
                                this.setState({
                                    paymentError: 'Your order could be created, please send a message to the support@svang.app',
                                    isLoad: false
                                });
                                $(window).scrollTop(0);
                                return;
                            }
                            this.setState({isLoad: false});
                            const tmpAddToCart = JSON.parse(localStorage.getItem('addToCard'));
                            const tmpAddToCartRemove = [];
                            if (tmpAddToCart && tmpAddToCart.cartItems && tmpAddToCart.cartItems.length) {
                                for (let i = 0; i < tmpAddToCart.cartItems.length; i += 1) {
                                    if (tmpAddToCart.cartItems[i].vendorID !== this.state.selectedVendorData.id) {
                                        tmpAddToCartRemove.push(tmpAddToCart.cartItems[i]);
                                    }
                                }
                            }
                            if (!(tmpAddToCart.cartItems && tmpAddToCart.cartItems.length)) {
                                localStorage.removeItem('addToCard');
                            } else {
                                localStorage.setItem('addToCard', JSON.stringify({cartItems: tmpAddToCartRemove}));
                            }
                            var today = new Date();
                            var dd = today.getDate();

                            var mm = today.getMonth()+1; 
                            var yyyy = today.getFullYear();
                            if (dd < 10) {
                                dd='0'+dd;
                            }
                            if (mm < 10) {
                                mm='0'+mm;
                            }
                            const date = mm+'-'+dd+'-'+yyyy;
                            let tmpTotal = ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(this.state.minimumOrderCharge) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2);
                            if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                                if (this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData && this.state.delivery_charge) {
                                    if (this.state.selectedVendorData.updateServiceCharge && this.state.selectedVendorData.updateServiceCharge > 0) {
                                        tmpTotal = ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(this.state.delivery_charge) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2)
                                    } else {
                                        tmpTotal = ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(this.state.delivery_charge) + tmpTaxAmount + Number(this.state.tipObj.amount)).toFixed(2)
                                    }
                                }
                            }
                            const passUrl = `/orderreceive/${this.state.selectedVendorData.id}?title=${this.state.selectedVendorData.title}&total=${tmpTotal}&orderId=${orderData.orderId}&shortOrderId=${orderData.shortOrderId}&email=${this.state.fields.emailid}&date=${date}`;
                            this.props.history.push(passUrl);
                        });
                        });
                    }
                });
            });
        }
    }
    postalCode() {
        const postalCode = ''; // your logic here
        return postalCode;
    }
    tipDefult(key, row) {
        this.setState({isActivekey: key});
        const tmpObj = {}
        let tmpServiceCharge = 0;
        if (this?.state?.selectedVendorData?.serviceChargeTaxable) {
        tmpServiceCharge = this.state.selectedVendorData.updateServiceCharge;
        }
        let tmpTotal = (Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(tmpServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100).toFixed(2));
        if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
            if (this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData && this.state.delivery_charge) {
                if (this.state.selectedVendorData.deliveryTaxable) {
                    tmpTotal = (Number(this.state.subTotal)-this.state.saving) +
                        Number(this.state.minimumOrderCharge) +
                        Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))+
                        Number(this.state.delivery_charge) +
                        Number((evenRound(((((
                            Number(this.state.subTotal)-this.state.saving)+
                            Number(this.state.minimumOrderCharge)+
                            Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                            Number(parseFloat(tmpServiceCharge).toFixed(2))
                        ) * Number(this.state.selectedVendorData.tax))/100), 2)));
                } else {
                    tmpTotal = (Number(this.state.subTotal)-this.state.saving) +Number(this.state.minimumOrderCharge)+ Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(this.state.delivery_charge) + Number((evenRound(((((
                        Number(this.state.subTotal)-this.state.saving)+
                        Number(this.state.minimumOrderCharge) +
                        Number(parseFloat(tmpServiceCharge).toFixed(2))
                        ) * Number(this.state.selectedVendorData.tax))/100), 2)));
                }
            }
        }
        const total = tmpTotal;
        const tmp = Number(row);
        tmpObj.value = tmp;

        
        tmpObj.amount = evenRound((Number(total)*tmp)/100, 2);
        this.setState({tipObj: tmpObj});
    }
    customTipClick(){
        this.setState({customTipModelShow: true, customTipAmount: ''});
    }
    customTipCloseModel() {
        this.setState({customTipModelShow:false, customTipAmount: ''});
    }
    saveCustomeTip(amount) {
        this.setState({
            customTipModelShow:false,
            customTipAmount: '',
            tipObj: {
                value: 0,
                amount: evenRound(Number(amount), 2)
            },
            isActivekey: 0
        });
    }
    totalAmout(selectedVendorData) {
        if (addToCartData) {
            let subTotal = "";     
            var total_Data = addToCartData.reduce((acc, item) => acc + parseFloat(Number(item.totalAmount)*item.quantity ? Number(item.totalAmount)*item.quantity : 0),0);
            if (isNaN(total_Data)) {
                total_Data = 0.00;
            }
            subTotal = total_Data.toFixed(2);
            let minimumOrderCharge = 0;
            let tmpServiceCharge = 0;
        if (this?.state?.selectedVendorData?.serviceChargeTaxable) {
            tmpServiceCharge = this.state.selectedVendorData.updateServiceCharge;
        }
            let tmpTotal = (Number(subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(((((Number(subTotal)-this.state.saving) + Number(parseFloat(tmpServiceCharge).toFixed(2))) * Number(selectedVendorData.tax))/100).toFixed(2));
            if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
                if (this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData && this.state.delivery_charge) {
                    if (this.state.selectedVendorData.deliveryTaxable) {
                        minimumOrderCharge = (Number(selectedVendorData.minimumOrderCharge) - Number(subTotal)).toFixed(2);
                        tmpTotal = (Number(subTotal)-this.state.saving) +
                            Number(minimumOrderCharge) +
                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) +
                            Number(this.state.delivery_charge) +
                            Number((evenRound(((((Number(subTotal)-this.state.saving)+Number(minimumOrderCharge)+Number(parseFloat(this.state.delivery_charge).toFixed(2))+Number(parseFloat(tmpServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)));
                    } else {
                        minimumOrderCharge = (Number(selectedVendorData.minimumOrderCharge) - Number(subTotal)).toFixed(2);
                        tmpTotal = (Number(subTotal)-this.state.saving) +
                            Number(minimumOrderCharge) +
                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) +
                            Number(this.state.delivery_charge) +
                            Number((evenRound(((((Number(subTotal)-this.state.saving)+Number(minimumOrderCharge)+Number(parseFloat(tmpServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)));
                    }
                }
            }
            const total = tmpTotal;
            const tmpObj = {};
            tmpObj.value = Number(this.state.tipData[this.state.isActivekey-1]);
            tmpObj.amount = evenRound((Number(total)*Number(this.state.tipData[this.state.isActivekey-1]))/100, 2);
            this.setState({subTotal:subTotal, tipObj: tmpObj, minimumOrderCharge: minimumOrderCharge > 0 ? minimumOrderCharge: 0});
        }
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields.firstName) {
            formIsValid = false;
            errors["firstName"] = "*Please enter your first name.";
        }

        if (typeof fields.firstName !== "undefined") {
            if (!fields.firstName.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["firstName"] = "*Please enter alphabet characters only.";
            }
            if (!fields.firstName.match(/.*\S.*/)) {
                formIsValid = false;
                errors["firstName"] = "*Please enter alphabet characters only.";
            }
        }

        if (!fields.lastName) {
            formIsValid = false;
            errors["lastName"] = "*Please enter your last name.";
        }

        if (typeof fields.lastName !== "undefined") {
            if (!fields.lastName.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["lastName"] = "*Please enter alphabet characters only.";
            }
            if (!fields.firstName.match(/.*\S.*/)) {
                formIsValid = false;
                errors["firstName"] = "*Please enter alphabet characters only.";
            }
        }

        if (!fields.emailid) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }

        if (typeof fields.emailid !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields.emailid)) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }

        if (!fields.mobileno) {
            formIsValid = false;
            errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof fields.mobileno !== "undefined") {
            // eslint-disable-next-line
            // if (!fields.mobileno.match(/^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g)) {
            // if (!fields.mobileno.match(/^(?:\+1\s\(\d{3}\)\s\d{3}-\d{4}|\+1\d{10}|\d{3}\.\d{3}\.\d{4})$/g)) {
            //     formIsValid = false;
            //     errors["mobileno"] = "*Please enter valid mobile no.";
            // } else 
            if (fields.mobileno.length > 10) {
                formIsValid = false;
                errors["mobileno"] = "*Please enter valid mobile no.";
            } else if (fields.mobileno.length < 10) {
                errors["mobileno"] = "*Please enter valid mobile no.";
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;  
    }
    submitPayForm(e) {
        if (this.validateForm()) {
        console.log('Done');
        }
    }
    handleChange(e) {
        let fields = this.state.fields;
        if (e.target.name === "mobileno") {
            fields[e.target.name] = e.target.value.replace(/\D+/g, '');
        } else {
            fields[e.target.name] = e.target.value;
        }
        this.setState({
            fields
        });
    }
    deliveryInstructionChange(e) {
        this.setState({
            deliveryInstruction: e.target.value
        })
    }
    tipChangeAmount(e) {
        this.setState({
            customTipAmount: e.target.value
        });
    }
    onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-/.test(keyValue))
        event.preventDefault();
    }
    continueShopping() {
        this.props.history.push(this.state.dynamicUrl, this.state.selectedVendorData);
    }
    changePaymentMethod(event) {
        const value = event.target.value;
        let tmpValue = false;
        if (value === 'payNow') {
            tmpValue = true;
        }
        this.setState({
            paymentMethod: value,
            isPayNow: tmpValue
        });
    }
    openDealModal(){
        this.setState({dealsModelShow: true, inputcouponcode: '', inputcouponcodeError: false});
        const email = this.state.fields.emailid;
        if (!email) {
            this.setState({
                emailDealMessage: 'Please enter your information before applying deals.'
            });
        }
    }
    dealsCloseModel() {
        this.setState({dealsModelShow:false});
        this.setState({
            emailDealMessage: ''
        });
        const email = this.state.fields.emailid;
        if (!email) {
            setTimeout(() => {
                $('#emailid').focus();
            }, 500);
        }
    }
    dealhandleChange(e) {
        this.setState({
            inputcouponcode: e.target.value,
            inputcouponcodeError: false,
        });
    }
    applyDealCode (e) {
        const tmpCouponVal = this.state.inputcouponcode;
        if (!tmpCouponVal) {
            this.setState({
                inputcouponcode: '',
                inputcouponcodeError: true,
            });
            return;
        }
        this.finalApplyDealCode(this.state.inputcouponcode);
    }
    async finalApplyDealCode (couponCode) {
        const email = this.state.fields.emailid;
        if (!email) {
            this.setState({
                emailDealMessage: 'Please enter your information before applying deals.'
            });
            return;
        } else {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                this.setState({
                    emailDealMessage: 'Please enter valid email'
                }); 
                return;
            }
        }
        const passObj = {
            vendorId: this.state.selectedVendorData.id,
            email: email,
            couponCode: couponCode,
            orderType: this.state?.orderObj?.DeliveryType && this.state.orderObj.DeliveryType === 'Pick at Restaurant' ? 'Pickup' : 'Delivery'
        }
        validatecouponcode(passObj, (result) => {
            const tmpData = this.state.selectedVendorData;
            const orderSubTotal = this.state.subTotal;
            const cartItems = this.state.addToCartData;
            const deliveryType = this.state?.orderObj?.DeliveryType ? this.state.orderObj.DeliveryType: '';
            const tmpFunction = () => {
                if (result.status === true) {
                    if (result?.response?.couponDetails) {
                        var res = result?.response;
                        if (result?.response?.couponDetails?.discountType === 'amountOff') {
                            if (result?.response?.couponDetails?.orderAbove != null) {
                                if (
                                    parseFloat(result?.response?.couponDetails?.orderAbove) <=
                                    parseFloat(orderSubTotal)
                                ) {
                                    if (
                                    result?.response?.couponDetails?.selectedFoodItems?.length > 0
                                    ) {
                                        var tempdata = [];
            
                                        result?.response?.couponDetails?.selectedFoodItems.map(
                                            // eslint-disable-next-line
                                            item => {
                                                // eslint-disable-next-line
                                                item.product.map(pItem => {
                                                    // eslint-disable-next-line
                                                    cartItems.filter(function(item, index) {
                                                    if (item.id === pItem.id) {
                                                        tempdata.push(index);
                                                    }
                                                    });
                                                });
                                            },
                                        );
                                        if (tempdata.length > 0) {
                                            res['ItemListIndex'] = tempdata;
                                            res['discount'] = result.response.couponDetails.takeOff;
                                            return {
                                                error: false,
                                                data: res,
                                                couponDetailsName: result.response.couponDetails.couponName
                                            };
                                        } else {
                                            return {
                                                error: true,
                                                data: `Deals is not applicable`,
                                                couponDetailsName: ''
                                            };
                                        }
                                    } else {
                                        res['ItemListIndex'] = [];
                                        res['discount'] = result.response.couponDetails.takeOff;
                                        return {
                                            error: false,
                                            data: res,
                                            couponDetailsName: result.response.couponDetails.couponName
                                        };
                                    }
                                } else {
                                    return {
                                        error: true,
                                        data: `Deals code is Invalid, Order should be more than $${result?.response?.couponDetails?.orderAbove}`,
                                        couponDetailsName: ''
                                    };
                                }
                            } else {
                                res['ItemListIndex'] = [];
                                res['discount'] = result.response.couponDetails.takeOff;
                                return {
                                    error: false,
                                    data: res,
                                    couponDetailsName: result.response.couponDetails.couponName
                                };
                            }
                        }
                        if (result?.response?.couponDetails?.discountType === 'percentageOff') {
                            var val = evenRound(
                                (orderSubTotal / 100) * result.response.couponDetails.takeOff, 2
                            );
                            if (result?.response?.couponDetails?.orderAbove != null) {
                                if (
                                    parseFloat(result?.response?.couponDetails?.orderAbove) <=
                                    parseFloat(orderSubTotal)
                                ) {
                                    if (
                                        result?.response?.couponDetails?.selectedFoodItems?.length > 0
                                    ) {
                                        var tempData = [];
                                        result?.response?.couponDetails?.selectedFoodItems.map(
                                            // eslint-disable-next-line
                                            item => {
                                                // eslint-disable-next-line
                                                item.product.map(pItem => {
                                                    // eslint-disable-next-line
                                                    cartItems.filter(function(item, index) {
                                                    if (item.id === pItem.id) {
                                                        tempData.push(index);
                                                    }
                                                    });
                                                });
                                            },
                                        );
                                        res['ItemListIndex'] = tempData;
                                        if (result?.response?.couponDetails?.upto > 0 && result.response.couponDetails.takeOff > result?.response?.couponDetails?.upto) {
                                            res['discount'] = result?.response?.couponDetails?.upto;
                                        } else {
                                            res['discount'] = result.response.couponDetails.takeOff;
                                        }
                                        return {
                                            error: false,
                                            data: res,
                                            couponDetailsName: result.response.couponDetails.couponName
                                        };
                                    } else {
                                        res['ItemListIndex'] = [];
                                        if (result?.response?.couponDetails?.upto > 0 && val > result?.response?.couponDetails?.upto) {
                                            res['discount'] = result?.response?.couponDetails?.upto;
                                        } else {
                                            res['discount'] = val;
                                        }
                                        return {
                                            error: false,
                                            data: res,
                                            couponDetailsName: result.response.couponDetails.couponName
                                        };
                                    }
                                } else {
                                    return {
                                        error: true,
                                        data: `Deals code is Invalid, Order should be more than $${result?.response?.couponDetails?.orderAbove}`,
                                        couponDetailsName: ''
                                    };
                                }
                            } else {
                                res['ItemListIndex'] = [];
                                if (result?.response?.couponDetails?.upto > 0 && val > result?.response?.couponDetails?.upto) {
                                    res['discount'] = result?.response?.couponDetails?.upto;
                                } else {
                                    res['discount'] = val;
                                }
                                return {
                                    error: false,
                                    data: res,
                                    couponDetailsName: result.response.couponDetails.couponName
                                };
                            }
                        }
                        if (result?.response?.couponDetails?.discountType === 'freeDelivery') {
                            if (
                                deliveryType === 'Delivery'
                            ) {
                                if (result?.response?.couponDetails?.orderAbove != null) {
                                    if (
                                        parseFloat(result?.response?.couponDetails?.orderAbove) <=
                                        parseFloat(orderSubTotal)
                                    ) {
                                        res['ItemListIndex'] = [];
                                        res['discount'] = 0;
                                        res['freeDelivery'] = true;
                                        return {
                                            error: false,
                                            data: res,
                                            couponDetailsName: result.response.couponDetails.couponName
                                        };
                                    } else {
                                        return {
                                            error: true,
                                            data: `Deals code is Invalid, Order should be more than $${result?.response?.couponDetails?.orderAbove}`,
                                            couponDetailsName: ''
                                        };
                                    }
                                } else {
                                    res['ItemListIndex'] = [];
                                    res['discount'] = 0;
                                    res['freeDelivery'] = true;
                                    return {
                                        error: false,
                                        data: res,
                                        couponDetailsName: result.response.couponDetails.couponName
                                    };
                                }
                            } else {
                                return {
                                    error: true,
                                    data: `Deals not validate for Pickup`,
                                    couponDetailsName: ''
                                };
                            }
                        }
                    }
                    return {
                        error: true,
                        data: 'Invalid Code',
                        couponDetailsName: ''
                    };
                } else {
                    return {
                        error: true,
                        data: result.statusText ? result.statusText : 'Invalid Deal Code.',
                        couponDetailsName: ''
                    };
                }
            }
            
            this.setState({
                dealDefaultErrorStatus: false,
                dealErrorStatus: tmpFunction().error,
                dealErrorMessage: tmpFunction().data,
                couponcode: couponCode,
                dealsModelShow: false,
                couponDetailsName: tmpFunction().couponDetailsName,
            });
            if (!tmpFunction().error) {
                tmpData.updateServiceCharge = manageServiceCharge1(tmpData.serviceChargeType, tmpData.serviceCharge, tmpData, Number(tmpData.delivery_charge), tmpFunction()?.data?.discount || 0);
                this.setState({
                    selectedVendorData: tmpData
                })
                this.setState({
                    saving: tmpFunction()?.data?.discount || 0,
                    dealData: tmpFunction().data,
                });
            } else {
                tmpData.updateServiceCharge = manageServiceCharge1(tmpData.serviceChargeType, tmpData.serviceCharge, tmpData, Number(tmpData.delivery_charge), 0);
                this.setState({
                    selectedVendorData: tmpData
                })
                this.setState({
                    saving: 0,
                    dealData: {},
                });
            }

            if (tmpFunction()?.data?.freeDelivery) {
                tmpData.updateServiceCharge = manageServiceCharge1(tmpData.serviceChargeType, tmpData.serviceCharge, tmpData, 0, 0);
                this.setState({
                    selectedVendorData: tmpData
                })
                this.setState({
                    freeDelivery: true,
                    delivery_charge: '0'
                });
            } else {
                this.setState({
                    freeDelivery: false
                });
                this.manageDeliveryCharge();
            }
            this.tipDefult(this.state.isActivekey, this.state.tipData[this.state.isActivekey-1]);
        });

        const dealsData = this.state.dealsData;
        const activeDeals = this?.state?.dealsData?.response?.activeDeals?.length ? this.state.dealsData.response.activeDeals : [];
        const currentDeals = this?.state?.dealsData?.response?.currentDeals?.length ? this.state.dealsData.response.currentDeals : [];
        const upcomingDeals = this?.state?.dealsData?.response?.upcomingDeals?.length ? this.state.dealsData.response.upcomingDeals : [];
        if (activeDeals && activeDeals.length) {
            if (activeDeals[0].couponcode !== couponCode) {
                currentDeals.push(activeDeals[0]);
                activeDeals.splice(0, 1);
            }
        }
        if (currentDeals && currentDeals.length) {
            for (let i = 0; i < currentDeals.length; i += 1) {
                if (currentDeals[i].couponCode === couponCode) {
                    activeDeals.push(currentDeals[i]);
                    currentDeals.splice(i, 1);
                }
            }
        }
        this.setState({
            dealsData: {
                status: dealsData.status,
                response: {
                    activeDeals: activeDeals,
                    currentDeals: currentDeals, 
                    upcomingDeals: upcomingDeals
                }
            }
        });
    }
    removeActiveDealCode(couponData) {
        const dealsData = this.state.dealsData;
        const activeDeals = this?.state?.dealsData?.response?.activeDeals?.length ? this.state.dealsData.response.activeDeals : [];
        const currentDeals = this?.state?.dealsData?.response?.currentDeals?.length ? this.state.dealsData.response.currentDeals : [];
        const upcomingDeals = this?.state?.dealsData?.response?.upcomingDeals?.length ? this.state.dealsData.response.upcomingDeals : [];
        const tmpData = this.state.selectedVendorData;
        if (activeDeals && activeDeals.length) {
            currentDeals.push(activeDeals[0]);
        }
        this.manageDeliveryCharge();
        tmpData.updateServiceCharge = manageServiceCharge1(tmpData.serviceChargeType, tmpData.serviceCharge, tmpData, Number(tmpData.delivery_charge), 0);
        this.setState({
            selectedVendorData: tmpData
        })
        this.setState({
            couponcode: '',
            dealDefaultErrorStatus: true,
            freeDelivery: false,
            dealErrorStatus: false,
            dealErrorMessage: '',
            saving: 0,
            dealData: {},
            dealsData: {
                status: dealsData.status,
                response: {
                    activeDeals: [],
                    currentDeals: currentDeals, 
                    upcomingDeals: upcomingDeals
                }
            }
        });
    }
    pickupChange() {
        const orderObj = JSON.parse(localStorage.getItem(this.props.match.params.vendorId + '___orderObj'));
        const delivery = JSON.parse(localStorage.getItem(this.props.match.params.vendorId + '___delivery'));
        const changeorderObj = {
            DeliveryType: 'Pick at Restaurant',
            isUnivercity: false,
            orderType: orderObj.orderType
        }    
        if (orderObj.orderType === 'Schedule For Later') {
            changeorderObj.scheduleDate = orderObj.scheduleDate;
            changeorderObj.scheduleTime = orderObj.scheduleTime;
        }

        const changedelivery = {
            DeliveryType: 'Pick at Restaurant',
            isUnivercity: false,
            isActiveAddressType: 2,
            orderType: delivery.orderType
        }

        if (delivery.orderType === 'Schedule For Later') {
            changedelivery.scheduleDate = delivery.scheduleDate;
            changedelivery.scheduleTime = delivery.scheduleTime;
        }
        this.setState({
            delivery_charge: '0'
        });
        if (selectedVendorData && selectedVendorData.tips && selectedVendorData.tips.value && selectedVendorData.tips.value.length) {
            this.setState({tipData: selectedVendorData.tips.value, isActivekey: selectedVendorData.tips.default+1});
        } else {
            this.setState({tipData: [], isActivekey: 0});
        }
        if (!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true')) {
            this.setState({
                orderObj: changeorderObj,
                deliveryErrorMesssage: '',
                displayDeliveryErrorMesssage: false
            });
            if (changeorderObj
                && changeorderObj.DeliveryType === 'Delivery') {
                this.manageDeliveryCharge();
            }
            
            setTimeout(() => {
                this.totalAmout(selectedVendorData);
            }, 500);
        }
        localStorage.setItem(this.props.match.params.vendorId + '___orderObj', JSON.stringify(changeorderObj));
        localStorage.setItem(this.props.match.params.vendorId + '___delivery', JSON.stringify(changedelivery));
    }
    permissionCheck(e) {
        const isSubscribe = !this.state.isSubscribe;
        this.setState({
            isSubscribe: isSubscribe
        });
    }
    render() {
        const { selectedVendorData, isPayNow, paymentMethod, dealsData, emailDealMessage } = { ...this.state }
        return (
            <div>
                <Helmet>
                    <meta httpEquiv='cache-control' content='no-cache' />
                    <meta httpEquiv='expires' content='0' />
                    <meta httpEquiv='pragma' content='no-cache' />
                </Helmet>
                <div className="breadcrumb-wrapper">
                    <ul>
                        <li>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => this.continueShopping()} >{this.state.selectedVendorData.title}
                            </a>
                            </li>
                        <li>/</li>
                        <li>checkout</li>
                    </ul>
                </div>
                <div className="checkout-main-container container-fluid">
                    <div className="row header-row">
                        <div className="col-md-12">
                            <div className="checkout-header">
                                <button type="button" className="btn-orange continue-shopping-btn" onClick={() => this.continueShopping()}>Continue shopping</button>
                                {/* <h1 className="text-center mt-1">Your order from</h1> */}
                            </div>
                        </div>
                    </div>
                    <div className="checkout-container">
                        {this.state.paymentError &&
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-center color-red">{this.state.paymentError}</p>
                                </div>
                            </div>
                        }
                        {this.state.displayDeliveryErrorMesssage &&
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-center color-red">{this.state.deliveryErrorMesssage} 
                                        {/* eslint-disable-next-line */}
                                        <a className="pickup-link" onClick={() => this.pickupChange()}> click here</a>
                                    </p>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') &&
                                    <div className="order_box_wrapper order_box_left_wrapper m-b-30">
                                        <p className="font-weight-600">{this.state.selectedVendorData.name}</p>
                                        {this.state.orderObj && this.state.orderObj.DeliveryType === 'Delivery' &&
                                            <div>
                                                <div className="font-weight-600">{this.state.orderObj.DeliveryType} at</div>
                                                {this.state.orderObj && this.state.orderObj.line1 &&
                                                    <div>{this.state.orderObj.line1}</div>
                                                }
                                                {this.state.orderObj && this.state.orderObj.scheduleDate &&
                                                    <div>Schedule Date: {this.state.orderObj.scheduleDate}</div>
                                                }
                                                {this.state.orderObj && this.state.orderObj.scheduleTime &&
                                                    <div>Schedule Time: {this.state.orderObj.scheduleTime}</div>
                                                }
                                                <div className="row mt-2">
                                                    <div className="col-xs-12 col-sm-12 col-md-8">
                                                        <input type="text" className="input-common" name="deliveryInstruction" autoComplete="off" placeholder="Delivery Instructions" value={this.state.deliveryInstruction} onChange={this.deliveryInstructionChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.orderObj && this.state.orderObj.DeliveryType === 'Pick at Restaurant' &&
                                            <div>
                                                <div className="font-weight-600">Pick at</div>
                                                <p className="mb-0">{this.state.selectedVendorData.addressLine1}</p>
                                                <p className="mb-0">{this.state.selectedVendorData.addressLine2}</p>
                                                {/* <div className="font-weight-600">{this.state.orderObj.DeliveryType}</div> */}
                                                {this.state.orderObj && this.state.orderObj.scheduleDate &&
                                                    <div>Schedule Date: {this.state.orderObj.scheduleDate}</div>
                                                }
                                                {this.state.orderObj && this.state.orderObj.scheduleTime &&
                                                    <div>Schedule Time: {this.state.orderObj.scheduleTime}</div>
                                                }
                                                <div className="row mt-2">
                                                    <div className="col-xs-12 col-sm-12 col-md-8">
                                                        <input type="text" className="input-common" name="deliveryInstruction" autoComplete="off" placeholder="Pickup Instructions" value={this.state.deliveryInstruction} onChange={this.deliveryInstructionChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* {JSON.stringify(this.state.orderObj)} */}
                                    </div>
                                }
                                <div className="order_box_wrapper order_box_left_wrapper">
                                <h3><span> Your Items</span><span className="add-more-item-link" onClick={() => this.continueShopping()}>Add More Items</span></h3>
                                {
                                    this.state.addToCartData.map((cartRow, cartIndex) => (
                                        <div className="order-item-main" key={cartIndex}>
                                            <div className="qty-wrapper">
                                                <p>{cartRow.quantity}</p>
                                            </div>
                                            <div className="item-info-wrapper">
                                                <div className="food-name-price">
                                                    <p className="color_b">{cartRow.name}</p>
                                                    <p className="color_b">${(Number(cartRow.totalAmount)*cartRow.quantity).toFixed(2)}</p>
                                                </div>
                                                {cartRow && cartRow.variant && 
                                                    cartRow.variant.map((vRow, vIndex) => (
                                                        <span key={vIndex}>{vRow.name}{vRow.type ? `(${vRow.type})`: ""}
                                                        { cartRow && cartRow.variant && (cartRow.variant.length-1) !== vIndex && 
                                                            <span>, </span>
                                                        }
                                                        </span>
                                                    ))
                                                }
                                            
                                                {cartRow && cartRow.add_special_instructions &&
                                                    <p className="special_intro">"{cartRow.add_special_instructions}"</p>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="payment-final">
                                    <p className="payment-p"><span>Sub Total:</span> <span>${this.state.subTotal}</span></p>
                                    {this.state.minimumOrderCharge > 0 &&
                                        <p className="payment-p"><span>Minimum Order Charge:</span> <span>${this.state.minimumOrderCharge}</span></p>
                                    }
                                    {this.state.saving > 0 &&
                                        <p className="payment-p"><span>Saving:</span> <span>-${this.state.saving.toFixed(2)}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' &&
                                        <p className="payment-p"><span>Delivery Charge:</span><span> ${parseFloat(this.state.delivery_charge).toFixed(2)}</span></p>
                                    }
                                    
                                    {this.state.selectedVendorData.updateServiceCharge > 0 &&
                                        <p className="payment-p"><span>Service Charge:</span><span> ${parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)}</span></p>
                                    }
                                    {/* {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.serviceCharge > 0 &&
                                        <p className="payment-p"><span>Service Charge:</span><span> ${parseFloat(this.state.selectedVendorData.serviceCharge).toFixed(2)}</span></p>
                                    } */}
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.delivery_charge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && this.state.selectedVendorData.serviceChargeTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound((((
                                            (Number(this.state.subTotal)-this.state.saving)+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && !this.state.selectedVendorData.serviceChargeTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound((((
                                            (Number(this.state.subTotal)-this.state.saving)
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound((((
                                            (Number(this.state.subTotal)-this.state.saving)+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <p className="payment-p"><span>Tax ({this.state.selectedVendorData.tax}%):</span><span> ${Number(evenRound((((
                                            (Number(this.state.subTotal)-this.state.saving)
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2))}</span></p>
                                    }
                                    {/* <p className="payment-p"><span>Tax:</span><span> ${(evenRound(((Number(this.state.subTotal) * Number(this.state.selectedVendorData.tax))/100), 2)).toFixed(2)}</span></p> */}

                                    <p className="payment-p"><span>Tip:</span><span> ${parseFloat(this.state.tipObj.amount).toFixed(2)}</span></p>
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.delivery_charge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }

                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge)+ Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)+
                                            Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }

                                {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge)+ Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                            Number(this.state.subTotal)-this.state.saving)+
                                            Number(this.state.minimumOrderCharge)
                                            ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }

                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)+Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }
                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }

                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)+Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }
                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && !this.state.selectedVendorData.serviceChargeTaxable &&
                                        <h5><span>Total:</span><span> ${((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</span></h5>
                                    }
                                    <p className="payment-p pr-payment"><span>Add a tip to say thanks </span>
                                        {this.state.tipObj.value !== 0 ? (
                                            <span>({this.state.tipObj.value}%) ${parseFloat(this.state.tipObj.amount).toFixed(2)}</span>
                                        ):(
                                            <span>${parseFloat(this.state.tipObj.amount).toFixed(2)}</span>
                                        )}
                                    </p>
                                    
                                    <div className="tip-section">
                                        <div className="btn-group tip-btn-group">
                                            {this.state.tipData && this.state.tipData.length &&
                                                this.state.tipData.map((tRow, tIndex) => (
                                                    <div key={tIndex}>
                                                        <button type="button" className={`btn btn-primary ${tIndex+1 >= 2 ? "ml-1" : ""} ${this.state.isActivekey === tIndex+1 ? "active" : ""}`} onClick={() => this.tipDefult(tIndex+1, tRow)}>{tRow} %</button>
                                                    </div>
                                                ))
                                            } 
                                        </div>
                                        <div className="custom-tip">
                                            <span onClick={() => this.customTipClick()}>Custom</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 user-detail-col">
                                <div className="order_box_wrapper">
                                    <h3>Payment</h3>
                                    <div className="user-details-container">
                                        <h4>User Details</h4>
                                        {/* onSubmit= {this.submitPayForm} */}
                                        <form method="post" autoComplete="on" name="userForm" >
                                            <div className="form-user-details">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label>First Name <span className="color-red">*</span></label>
                                                        <input type="text" name="firstName" autoComplete="on" className={(!this.state.errors.firstName) ? "":"mb-0"} placeholder="Type your first name." value={this.state.fields.firstName} onChange={this.handleChange} />
                                                        <div className="errorMsg">{this.state.errors.firstName}</div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Last Name <span className="color-red">*</span></label>
                                                        <input type="text" name="lastName" autoComplete="on" className={(!this.state.errors.lastName) ? "":"mb-0"} placeholder="Type your last name." value={this.state.fields.lastName} onChange={this.handleChange} />
                                                        <div className="errorMsg">{this.state.errors.lastName}</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Email ID: <span className="color-red">*</span></label>
                                                        <input type="text" name="emailid" id="emailid" autoComplete="on" className={!this.state.errors.emailid ? "":"mb-0"} placeholder="Type your email and we never share your email with anyone." value={this.state.fields.emailid} onChange={this.handleChange}  />
                                                        <div className="errorMsg">{this.state.errors.emailid}</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Mobile No: <span className="color-red">*</span></label>
                                                        <input
                                                            inputmode="number"
                                                            className="last-input-tel"
                                                            name="mobileno"
                                                            minLength="10"
                                                            maxLength="10"
                                                            autoComplete="on"
                                                            placeholder="Type your phone number and we never share your phone number with anyone"
                                                            value={this.state.fields.mobileno} onChange={this.handleChange}   />
                                                        <div className="errorMsg">{this.state.errors.mobileno}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        
                                        <div className="order_box_wrapper order_box_left_wrapper m-b-30 m-t-30">
                                            <div className="couponcode-container">
                                                <div className="couponcode-left">
                                                    {this.state.dealDefaultErrorStatus &&
                                                        <div>
                                                            <Deal className="deal-svg" />
                                                            <p>Apply Deals</p>
                                                        </div>
                                                    }
                                                    {!this.state.dealDefaultErrorStatus && this.state.dealErrorStatus && 
                                                        <div>
                                                            <DealRed className="deal-svg" />
                                                            <p className="text-red">{this.state.dealErrorMessage}</p>
                                                        </div>
                                                        
                                                    }
                                                    {!this.state.dealDefaultErrorStatus && !this.state.dealErrorStatus && 
                                                        <div className="code_apply_content">
                                                            <DealSuccess className="deal-svg" />  
                                                            <div>                                                          
                                                                <p>Code {this.state.couponcode} applied!</p>
                                                                {this.state.couponDetailsName &&
                                                                    <p>{this.state.couponDetailsName}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="couponcode-right">
                                                    <span className="view-deals-link" onClick={() => this.openDealModal()}>View Deals</span>
                                                </div>
                                            </div>
                                        </div>
                                        {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && selectedVendorData.payLater && !selectedVendorData.paymentDisableDineIn && 
                                            <div className="pay-later-box">
                                                <div className="radio">
                                                    <input type="radio" name="pay" id="payNow" value="payNow" checked={paymentMethod === 'payNow'} onChange={(e) => this.changePaymentMethod(e)} />
                                                    <label className="radio-label" htmlFor="payNow">Pay Now</label>
                                                    <input type="radio" name="pay" id="payLater" value="payLater" checked={paymentMethod === 'payLater'} onChange={(e) => this.changePaymentMethod(e)} />
                                                    <label className="radio-label pl-3" htmlFor="payLater">Pay Later</label>
                                                </div>
                                            </div>
                                        }
                                        {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && selectedVendorData.payLater && paymentMethod === 'payLater' && !isPayNow &&
                                            <div>
                                                <div className="pay-btn-wrapper">
                                                    <div className="user-permission-checkbox checkbox from-group">
                                                        <input type="checkbox" id="permission-checkbox" name="permission-checkbox" value="" checked={this.state.isSubscribe} onChange={(e) => this.permissionCheck(e)} />
                                                        <label htmlFor="permission-checkbox" className="check-label check-label1"> sVang can send me emails about the best deals from {this.state.selectedVendorData.name}</label>
                                                    </div>
                                                    {this.state.selectedVendorData.serviceChargeTaxable &&
                                                    <button className="sq-creditcard" onClick={(e) => this.cardNonceResponseReceived(null)}>
                                                        Pay Later ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }
                                                    </button>
                                                    }
                                                    {!this.state.selectedVendorData.serviceChargeTaxable &&
                                                    <button className="sq-creditcard" onClick={(e) => this.cardNonceResponseReceived(null)}>
                                                        Pay Later ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {this.state.isOpenCard && isPayNow &&
                                        <div>
                                            <form id="payment-form">
                                                <div id="card-container"></div>
                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                                                                Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.delivery_charge).toFixed(2))+
                                                                Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }
                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.delivery_charge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.delivery_charge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }

                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)+
                                                                Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }
                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Delivery' && !this.state.selectedVendorData.deliveryTaxable && !this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(this.state.minimumOrderCharge) + Number(parseFloat(this.state.delivery_charge).toFixed(2)) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((
                                                                Number(this.state.subTotal)-this.state.saving)+
                                                                Number(this.state.minimumOrderCharge)
                                                                ) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }

                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }
                                                    {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') && this.state.orderObj.DeliveryType === 'Pick at Restaurant' && !this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }

                                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        } className="card-button-css" type="button">Pay ${
                                                            ((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2))) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)
                                                        }</button>
                                                    }
                                                    {localStorage.getItem('dining') && localStorage.getItem('dining') === 'true' && !this.state.selectedVendorData.serviceChargeTaxable &&
                                                        <button id="card-button" data-price={((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)} className="card-button-css" type="button">Pay ${((Number(this.state.subTotal)-this.state.saving) + Number(parseFloat(this.state.selectedVendorData.updateServiceCharge).toFixed(2)) + Number(evenRound(((((Number(this.state.subTotal)-this.state.saving)) * Number(this.state.selectedVendorData.tax))/100), 2)) + Number(this.state.tipObj.amount)).toFixed(2)}</button>
                                                    }
                                                {/* <button id="card-button" data-price="1.00" className="card-button-css" type="button">Pay $1.00</button> */}
                                            </form>
                                            {/* <div id="payment-status-container"></div> */}
                                            <div className="user-permission-checkbox checkbox from-group">
                                                <input type="checkbox" id="permission-checkbox" name="permission-checkbox" value="" checked={this.state.isSubscribe} onChange={(e) => this.permissionCheck(e)} />
                                                <label htmlFor="permission-checkbox" className="check-label check-label1"> sVang can send me emails about the best deals from {this.state.selectedVendorData.name}</label>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Modal className="custom-tip-modal" show={this.state.customTipModelShow} onHide={() => this.customTipCloseModel()}>
                        <Modal.Body>
                            <div className="body-custom-tip">
                                <label className="mr-2">$</label>
                                <input type="number" onKeyPress={this.onKeyPress.bind(this)} placeholder="amount in $" value={this.state.customTipAmount} onChange={this.tipChangeAmount} onWheel={ event => event.currentTarget.blur() }></input>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button className="cancel-btn" variant="secondary" onClick={() => this.customTipCloseModel()}>
                            Cancel
                        </Button>
                        <Button className="add-btn" variant="secondary" onClick={() => this.saveCustomeTip(this.state.customTipAmount)}>
                            Add
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal className="deals-modal" show={this.state.dealsModelShow} onHide={() => this.dealsCloseModel()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Deals</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="deals-body">
                                {emailDealMessage && 
                                    <p className="text-center text-red">{emailDealMessage}</p>
                                }
                                {dealsData && dealsData.status && dealsData.response && dealsData.response
                                && !(dealsData.response.currentDeals && dealsData.response.currentDeals.length)
                                && !(dealsData.response.upcomingDeals && dealsData.response.upcomingDeals.length) &&
                                    <p className="text-center">No deal available.</p>
                                }
                                {dealsData && dealsData.status && dealsData.response && dealsData.response
                                && dealsData.response.activeDeals && dealsData.response.activeDeals.length > 0 &&
                                    <div className="active-deals">
                                        {/* eslint-disable-next-line */}
                                        <h5>Active Deals <a className="coupon-remove-link" onClick={() => this.removeActiveDealCode(dealsData.response.activeDeals[0])}>remove</a></h5>
                                        <div>
                                            {
                                                dealsData.response.activeDeals.map((aRow, aIndex) => (
                                                    <Media className="coupon-media pointer" key={aIndex}>
                                                        <img
                                                            width={64}
                                                            height={64}
                                                            className="mr-3"
                                                            src={aRow.vendorDetails.photo}
                                                            alt={aRow.vendorDetails.photo}
                                                        />
                                                        <Media.Body>
                                                            <h5 className="mb-0">{aRow.shortDescription}</h5>
                                                            <p className="mb-0">{aRow.couponCode}</p>
                                                            <p className="mb-0">{aRow.vendorDetails.name}</p>
                                                        </Media.Body>
                                                    </Media>
                                                ))
                                            } 
                                        </div>
                                    </div>
                                }
                                {dealsData && dealsData.status && dealsData.response && dealsData.response
                                && dealsData.response.currentDeals && dealsData.response.currentDeals.length > 0 &&
                                    <div className="active-deals">
                                        <h5>Available Deals</h5>
                                        <div>
                                            {
                                                dealsData.response.currentDeals.map((dRow, dIndex) => (
                                                    <Media className="coupon-media pointer" key={dIndex} onClick={() => this.finalApplyDealCode(dRow.couponCode)}>
                                                        <img
                                                            width={64}
                                                            height={64}
                                                            className="mr-3"
                                                            src={dRow.vendorDetails.photo}
                                                            alt={dRow.vendorDetails.photo}
                                                        />
                                                        <Media.Body>
                                                            <h5 className="mb-0">{dRow.shortDescription}</h5>
                                                            <p className="mb-0">{dRow.couponCode}</p>
                                                            <p className="mb-0">{dRow.vendorDetails.name}</p>
                                                        </Media.Body>
                                                    </Media>
                                                ))
                                            } 
                                        </div>
                                    </div>
                                }

                                {dealsData && dealsData.status && dealsData.response && dealsData.response
                                && dealsData.response.upcomingDeals && dealsData.response.upcomingDeals.length > 0 &&
                                    <div className="active-deals">
                                        <h5>Upcoming Deals</h5>
                                        <div>
                                            {
                                                dealsData.response.upcomingDeals.map((uRow, uIndex) => (
                                                    <Media className="coupon-media" key={uIndex}>
                                                        <img
                                                            width={64}
                                                            height={64}
                                                            className="mr-3"
                                                            src={uRow.vendorDetails.photo}
                                                            alt={uRow.vendorDetails.photo}
                                                        />
                                                        <Media.Body>
                                                            <h5 className="mb-0">{uRow.shortDescription}</h5>
                                                            <p className="mb-0">{uRow.couponCode}</p>
                                                            <p className="mb-0">{uRow.vendorDetails.name}</p>
                                                        </Media.Body>
                                                    </Media>
                                                ))
                                            } 
                                        </div>
                                    </div>
                                }
                                <div className="input-group-deal">
                                    <InputGroup className="mb-2">
                                        <FormControl
                                            placeholder="Enter a deal code"
                                            aria-label="Enter a deal code"
                                            aria-describedby="deal-code"
                                            value={this.state.inputcouponcode}
                                            onChange={this.dealhandleChange}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="deal-input-gp-text" id="deal-code" onClick={(e) => this.applyDealCode(e)}>APPLY</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    {this.state.inputcouponcodeError && 
                                        <p className="mb-0 text-red">Enter a deal code</p>
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.dealsCloseModel()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {this.state.isLoad && 
                        <div className="spinner-wrapper">
                            <div>
                                <Spinner/>
                                <p>Payment processing please do not click browser back button.</p>
                            </div>
                        </div>
                    }
                    {this.state.pageLoad && 
                        <div className="spinner-wrapper">
                            <div>
                                <Spinner/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
 export default Checkout;