/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/27/2020, Working Add Breadcrumb
 */
import React from 'react';
import queryString from 'query-string'
// import { Link } from 'react-router-dom';
import './orderReceive.css';
import successLogo from "../../assets/success-logo.png";
let queryData = {};
class OrderReceive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        queryData = queryString.parse(props.location.search);
    }
    componentDidMount() {
        localStorage.removeItem(this.props.match.params.vendorId + '___dealCouponAndEmail');
    }
    componentDidUpdate() {
    }
    redirectHome() {
        this.props.history.push(`/vendorproduct/${this.props.match.params.vendorId}`);
    }
    render() {
        return (
            <div>
                <div className="breadcrumb-wrapper">
                    <ul>
                        <li>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => this.redirectHome()}>{queryData.title}</a>
                        </li>
                        <li>/</li>
                        <li>orderReceive</li>
                    </ul>
                </div>
                <div className="ml-3">
                    {/* eslint-disable-next-line */}
                    <a onClick={() => this.redirectHome()}>Back to Home</a>
                </div>
                <div className="order-receive-container">
                    <img src={successLogo} alt="success-logo"></img>
                    <h2>Thank YOU!</h2>
                    <p>Your order in the amount of $ {queryData.total} was placed successfully on {queryData.data}.</p>
                    <p>Your order ID is {queryData.shortOrderId}. We will email you a copy of your receipt at your email {queryData.email}.</p>
                    <button type="button" className="go-to-menu-btn" onClick={() => this.redirectHome()}>Go to menu</button>
                </div>
               <div className="container google-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="google-qr-wrapper">
                                <div className="google-qr-btn apple-btn">
                                    <img src="https://static.svang.app/apple-app-logo.png" alt="apple-app-logo"></img>
                                    <img src="https://static.svang.app/qr-code-apple.png" alt="qr-code-apple"></img>
                                </div>
                                <div className="logo-center">
                                    <img src="https://static.svang.app/svang-new-logo.png" alt="svang-new-logo"></img>
                                </div>
                                <div className="google-qr-btn">
                                    <img src="https://static.svang.app/google-play-logo.png" alt="google-play-logo"></img>
                                    <img src="https://static.svang.app/qr-code-google.png" alt="qr-code-google"></img>
                                </div>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
export default OrderReceive;