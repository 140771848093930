import React from "react";
import swal from 'sweetalert';
import { getLoyaltyCustomerForm, insertLoyaltyCustomer } from './service';
import './loyaltycustomer.css';
import PageNotFound from '../pageNotFound/pageNotFound';

class LoyaltyCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormDisplay: true,
            vendorId: '',
            vendorData: {},
            errorMessage: '',
            frm_Details: {
                customername: '',
                phonenumber: '',
                email: '',
                isSubscribe: true,
            },
            frm_errors: {
                customername: '',
                phonenumber: '',
                email: ''
            }
        };
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }
    componentDidMount() {
        const params = this.props.match.params;
        this.setState({
            vendorId: params.vendorId
        });
        getLoyaltyCustomerForm(params.vendorId, (vendorsData) => {
            if (vendorsData && vendorsData.status) {
                this.setState({
                    isFormDisplay: vendorsData.status,
                    vendorData: vendorsData.data
                });
            } else {
                this.setState({
                    isFormDisplay: vendorsData.status,
                    errorMessage: vendorsData.statusText
                });
            }
        });
    }
    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_Details;
        let formErrors = {};
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "customername" && value === "") || (formSubmit === true && formDetails["customername"] === "")) {
            fieldName = 'customername';
            formErrors[fieldName] = `The 'Name' is a required field.`;
            isError = true;
        } else {
            if (!formDetails["customername"]) {
                formErrors["customername"] = `The 'Name' is a required field.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "phonenumber" && value === "") || (formSubmit === true && formDetails["phonenumber"] === "")) {
            fieldName = 'phonenumber';
            formErrors[fieldName] = `The 'Phone Number' is a required field.`;
            isError = true;
        } else {
            if (formDetails["phonenumber"]) {
                var phonenoRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/);
                if (!phonenoRegex.test(formDetails["phonenumber"])) {
                    formErrors["phonenumber"] = `The 'Phone Number' is not in a valid format.`;
                    isError = true;
                } else {
                    if (formDetails["phonenumber"].length < 9) {
                        formErrors["phonenumber"] = `The 'Phone Number' value should be more than 9.`;
                        isError = true;
                    }
                }
            } else {
                fieldName = 'phonenumber';
                formErrors[fieldName] = `The 'Phone Number' is a required field.`;
                isError = true;
            }
        }
        if ((formSubmit === false && fieldName === "email" && value === "") || (formSubmit === true && formDetails["email"] === "")) {
            fieldName = 'email';
            formErrors[fieldName] = `The 'Email' is a required field.`;
            isError = true;
        } else {
            if (formDetails["email"]) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(formDetails["email"])) {
                    isError = true;
                    formErrors["email"] = "The 'Email' is not in a valid format.";
                }
            } else {
                fieldName = 'email';
                formErrors[fieldName] = `The 'Email' is a required field.`;
                isError = true;
            }
        }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_Details: formDetails });
        return isError;
    }
    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }
    onDetailsChange(e) {
        let self = this;
        let formDetails = self.state.frm_Details;
        let formErrors = self.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        self.setState({ frm_Details: formDetails });
        let isError = self.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            self.setState({ frm_errors: formErrors });
        }
    }
    onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            const finalObject = {...self.state.frm_Details, vendorId: self.state.vendorId};
            insertLoyaltyCustomer(finalObject, (response) => {
                if (response.status) {
                    this.props.history.push('/vendorproduct/'+self.state.vendorId);
                } else {
                    swal(response.statusText);
                }
            });
        }
    }
    permissionCheck(e, key) {
        const frm_Details = this.state.frm_Details;
        if (key === "isSubscribe") {
            const isSubscribe = !this.state.frm_Details.isSubscribe;
            frm_Details.isSubscribe = isSubscribe;
        }
        this.setState({
            frm_Details: frm_Details
        });

    }
    render() {
        const { frm_Details, frm_errors, isFormDisplay } = { ...this.state }
        return (
            <div className="container">
                {isFormDisplay && 
                    <div className="squre-form-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="new-form-wrapper">
                                    <h1 className="form-title">Loyalty Customer Form</h1>
                                    <div className="form-wrapper">
                                        <form>
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className={"form-control " + (frm_errors.customername && frm_errors.customername.length !== "" ? 'invalid' : '')}
                                                    name="customername"
                                                    id="customername"
                                                    placeholder="Enter Name"
                                                    autoComplete="off"
                                                    value={frm_Details.customername}
                                                    onChange={this.onDetailsChange}
                                                    onBlur={this.onFocusOut}
                                                    required
                                                    />
                                                    {frm_errors.customername && frm_errors.customername !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.customername}</div>
                                                    }
                                            </div>
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    className={"form-control " + (frm_errors.phonenumber && frm_errors.phonenumber.length !== "" ? 'invalid' : '')}
                                                    name="phonenumber"
                                                    id="phonenumber"
                                                    autoComplete="off"
                                                    placeholder="Enter Phone Number"
                                                    value={frm_Details.phonenumber}
                                                    onChange={this.onDetailsChange}
                                                    onBlur={this.onFocusOut}
                                                    required
                                                    />
                                                    {frm_errors.phonenumber && frm_errors.phonenumber !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.phonenumber}</div>
                                                    }
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    className={"form-control " + (frm_errors.email && frm_errors.email.length !== "" ? 'invalid' : '')}
                                                    name="email"
                                                    id="email"
                                                    autoComplete="off"
                                                    placeholder="Enter Email"
                                                    value={frm_Details.email}
                                                    onChange={this.onDetailsChange}
                                                    onBlur={this.onFocusOut}
                                                    required
                                                    />
                                                    {frm_errors.email && frm_errors.email !== "" &&
                                                        <div className="invalid-feedback">{frm_errors.email}</div>
                                                    }
                                            </div>
                                            <div className="form-group customer-checkbox">
                                                <input type="checkbox" className="input-checkbox" id="isSubscribe" name="isSubscribe" value="" checked={this.state.frm_Details.isSubscribe} onChange={(e) => this.permissionCheck(e, "isSubscribe")} />
                                                <label className="input-checkbox-label" htmlFor="isSubscribe"> sVang can send me emails about the best deals from {this.state.vendorData.name} </label>
                                            </div>
                                            <div className="submit-btn-wrapper">
                                                <button type="button" className="btn btn-submit" onClick={this.onFormSubmit}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mt-2 mb-0">Download our app </p>
                                <p className="font-20"><a className="font-30" href="https://play.google.com/store/apps/details?id=com.svang" rel="noreferrer" target="_blank"> android</a> or <a className="font-30" href="https://apps.apple.com/us/app/svang-llc/id1539371717" rel="noreferrer" target="_blank">ios</a></p>
                            </div>
                        </div>
                    </div>
                }
                {!isFormDisplay &&
                    <PageNotFound></PageNotFound>
                }
            </div>
        )
    }
}
 
export default LoyaltyCustomer;