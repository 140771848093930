/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/25/2020, Fixed open cart item that time UI responsive
 */
import React from "react";
import { getSquareForm, getsquareauthtoken } from './service';
import './squareform.css';

class SquareForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormDisplay: false,
            vendorId: '',
            errorMessage: '',
            frm_squareDetails: {
                clientId: '',
                clientSecret: '',
                locationId: ''
            },
            frm_errors: {
                clientId: '',
                clientSecret: '',
                locationId: ''
            }
        };
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }
    componentDidMount() {
        const params = this.props.match.params;
        this.setState({
            vendorId: params.vendorId
        });
        getSquareForm(params.vendorId, (vendorsProducts) => {
            if (vendorsProducts && vendorsProducts.status) {
                this.setState({
                    isFormDisplay: vendorsProducts.status
                });
            } else {
                this.setState({
                    isFormDisplay: vendorsProducts.status,
                    errorMessage: vendorsProducts.statusText
                });
            }
        });
    }
    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_squareDetails;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "clientId" && value === "") || (formSubmit === true && formDetails["clientId"] === "")) {
            fieldName = 'clientId';
            formErrors[fieldName] = `The 'Client Id' is a required field.`;
            isError = true;
        }
        if ((formSubmit === false && fieldName === "clientSecret" && value === "") || (formSubmit === true && formDetails["clientSecret"] === "")) {
            fieldName = 'clientSecret';
            formErrors[fieldName] = `The 'Client Secret' is a required field.`;
            isError = true;
        }
        if ((formSubmit === false && fieldName === "locationId" && value === "") || (formSubmit === true && formDetails["locationId"] === "")) {
            fieldName = 'locationId';
            formErrors[fieldName] = `The 'Location Id' is a required field.`;
            isError = true;
        }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_squareDetails: formDetails });
        return isError;
    }
    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }
    onDetailsChange(e) {
        let self = this;
        let formDetails = self.state.frm_squareDetails;
        let formErrors = self.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        self.setState({ frm_squareDetails: formDetails });
        let isError = self.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            self.setState({ frm_errors: formErrors });
        }
    }
    onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            getsquareauthtoken(
                self.state.frm_squareDetails.clientId,
                self.state.frm_squareDetails.clientSecret,
                self.state.vendorId,
                self.state.frm_squareDetails.locationId,
                (response) => {
                    if (response.status) {
                        window.open(response.url);
                    } else {
                        alert(response.statusText);
                    }
            });
        }
    }
    render() {
        const { frm_squareDetails, frm_errors, isFormDisplay, errorMessage } = { ...this.state }
        return (
            <div className="container">
                {isFormDisplay && 
                <div className="squre-form-wrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <ol>
                                {/* eslint-disable-next-line */}
                                <li>Create a square login if they do not have it - <a href="https://squareup.com/login" target="_blank">https://squareup.com/login</a></li>
                                {process.env.REACT_APP_STAGE === 'dev' &&
                                    // eslint-disable-next-line
                                    <li>After login is created, go to <a href="https://developer.squareup.com/apps" target="_blank">https://developer.squareup.com/apps</a> and log in using the square account</li>
                                }
                                {process.env.REACT_APP_STAGE === 'production' &&
                                    // eslint-disable-next-line
                                    <li>After login is created, go to <a href="https://squareup.com/apps" target="_blank">https://squareup.com/apps</a> and log in using the square account</li>
                                }
                                <li>Click + to create an app - name it svang.</li>
                                <li>Once the app is created, go to the credential menu on left and select SandBox.</li>
                                <li>Copy Sandbox Application ID and note the value - we will need it for the development.</li>
                                <li>Click Show for Sandbox Access Token and copy the value.</li>
                                {process.env.REACT_APP_STAGE === 'dev' &&
                                    // eslint-disable-next-line
                                    <li>Click oauth on left side and enter the URL <a href="https://apidev.svang.app/api/v1/square-oauth-callback" target="_blank">https://apidev.svang.app/api/v1/square-oauth-callback</a> in the field for Sandbox Redirect URL</li>
                                }
                                {process.env.REACT_APP_STAGE === 'production' &&
                                    // eslint-disable-next-line
                                    <li>Click oauth on left side and enter the URL <a href="https://api.svang.app/api/v1/square-oauth-callback" target="_blank">https://api.svang.app/api/v1/square-oauth-callback</a> in the field for Sandbox Redirect URL</li>
                                }
                                <li>Copy the value of Sandbox Application ID - we will need it as this is the client_id.</li>
                                <li>Copy the value of Sandbox Application Secret - we will need it for the form as it is client_secret.</li>
                                <li>Go to the locations on the left side - copy the locationID - we will need it for the form as it is locationID.</li>
                                <li>The vendorID is our field and we should give them a link that will have this value already as a query parameter.</li>
                                <li>Then, we ask them to save it.</li>
                                <li>Then, ask them to go back to the application page showing svang tile - scroll down and open the Default Test Account dashboard.</li>
                                <li>Fill the client_id, client_secret and locationID as obtained from the above and fill in the ou web form and submit. It will ask for authorization and then create entries in our svangdev database with the token and auth token that our web and mobile app will use.</li>
                            </ol>
                        </div>
                        <div className="col-md-6">
                            <div className="new-form-wrapper">
                                <h1 className="form-title">Square Auth Form</h1>
                                <div className="form-wrapper">
                                    <form>
                                        <div className="form-group">
                                            <label>Client Id</label>
                                            <input
                                                type="text"
                                                className={"form-control " + (frm_errors.clientId !== "" ? 'invalid' : '')}
                                                name="clientId"
                                                id="clientId"
                                                placeholder="Enter Client Id"
                                                value={frm_squareDetails.clientId}
                                                onChange={this.onDetailsChange}
                                                onBlur={this.onFocusOut}
                                                required
                                                />
                                                {frm_errors.clientId && frm_errors.clientId !== "" &&
                                                    <div className="invalid-feedback">{frm_errors.clientId}</div>
                                                }
                                        </div>
                                        <div className="form-group">
                                            <label>Client Secret</label>
                                            <input
                                                type="text"
                                                className={"form-control " + (frm_errors.clientSecret !== "" ? 'invalid' : '')}
                                                name="clientSecret"
                                                id="clientSecret"
                                                placeholder="Enter Client Secret"
                                                value={frm_squareDetails.clientSecret}
                                                onChange={this.onDetailsChange}
                                                onBlur={this.onFocusOut}
                                                required
                                                />
                                                {frm_errors.clientSecret && frm_errors.clientSecret !== "" &&
                                                    <div className="invalid-feedback">{frm_errors.clientSecret}</div>
                                                }
                                        </div>
                                        <div className="form-group">
                                            <label>Location Id</label>
                                            <input
                                                type="text"
                                                className={"form-control " + (frm_errors.locationId !== "" ? 'invalid' : '')}
                                                name="locationId"
                                                id="locationId"
                                                placeholder="Enter Location Id"
                                                value={frm_squareDetails.locationId}
                                                onChange={this.onDetailsChange}
                                                onBlur={this.onFocusOut}
                                                required
                                                />
                                                {frm_errors.locationId && frm_errors.locationId !== "" &&
                                                    <div className="invalid-feedback">{frm_errors.locationId}</div>
                                                }
                                        </div>
                                        <div className="submit-btn-wrapper">
                                            <button type="button" className="btn btn-submit" onClick={this.onFormSubmit}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                }
                {!isFormDisplay &&
                    <div>
                        <h1>
                            {errorMessage}
                        </h1>
                    </div>
                }
            </div>
        )
    }
}
 
export default SquareForm;