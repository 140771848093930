import React from "react";
import { payment3d } from './service';
import './demosqure.css';

class Demosqure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: 'sandbox-sq0idb-13CLR4jM7P0TXRs4d1WJsA',
            locationId: 'L3GRW9PGEVVZ4'
        };
    }
    componentDidMount() {
        this.initSquareUI();
    }
    async tokenize11(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
          return tokenResult.token;
      } else {
          throw new Error(
          `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
          );
      }
    }
    async initSquareUI() {
        let self = this;
        if (!window.Square) {
          throw new Error('Square.js failed to load properly');
        }
  
        let payments;
        try {
          payments = window.Square.payments(self.state.appId, self.state.locationId);
        } catch {
          const statusContainer = document.getElementById(
            'payment-status-container'
          );
          statusContainer.className = 'missing-credentials';
          statusContainer.style.visibility = 'visible';
          return;
        }
  
        let card;
        try {
          card = await self.initializeCard(payments);
        } catch (e) {
          console.error('Initializing Card failed', e);
          return;
        }
  
        async function handlePaymentMethodSubmission(
          event,
          paymentMethod,
          shouldVerify = false
        ) {
          event.preventDefault();

          try {
            // disable the submit button as we await tokenization and make a payment request.
            cardButton.disabled = true;
            const token = await self.tokenize11(paymentMethod);
            let verificationToken;

            if (shouldVerify) {
              verificationToken = await self.verifyBuyer(payments, token);
            }

            const paymentResults = await self.createPayment(
              token,
              verificationToken
            );

            self.displayPaymentResults('SUCCESS');
            console.debug('Payment Success', paymentResults);
          } catch (e) {
            console.error('>>>>>>>>>>>>>> e.message', e.message);
            cardButton.disabled = false;
            self.displayPaymentResults('FAILURE');
          }
        }
  
        const cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', async function (event) {
          // SCA only needs to be run for Card Payments. All other payment methods can be set to false.
          handlePaymentMethodSubmission(event, card, true);
        });
    }
    async initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');

        return card;
    }
    // verificationToken can be undefined, as it does not apply to all payment methods.
    async createPayment(token, verificationToken) {
        const bodyParameters = {
            locationId: this.state.locationId,
            sourceId: token,
        };

        if (verificationToken !== undefined) {
            bodyParameters.verificationToken = verificationToken;
        }
        const body = JSON.stringify(bodyParameters);

        payment3d(bodyParameters, (data) => {
          console.log('data', data);
        })

        const paymentResponse = await fetch('/payment', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body,
        });

        if (paymentResponse.ok) {
            return paymentResponse.json();
        }

        const errorBody = await paymentResponse.text();
        throw new Error(errorBody);
    }
    
    // status is either SUCCESS or FAILURE;
    displayPaymentResults(status) {
        const statusContainer = document.getElementById(
            'payment-status-container'
        );
        if (status === 'SUCCESS') {
            statusContainer.classList.remove('is-failure');
            statusContainer.classList.add('is-success');
        } else {
            statusContainer.classList.remove('is-success');
            statusContainer.classList.add('is-failure');
        }

        statusContainer.style.visibility = 'visible';
    }
    
    async verifyBuyer(payments, token) {
        const verificationDetails = {
            amount: '1.00',
            billingContact: {
            addressLines: ['123 Main Street', 'Apartment 1'],
            familyName: 'shyam',
            givenName: 'alian',
            email: 'shyamalian1211@gmail.com',
            country: 'GB',
            phone: '3214563987',
            region: 'LND',
            city: 'London',
            },
            currencyCode: 'GBP',
            intent: 'CHARGE',
        };

        const verificationResults = await payments.verifyBuyer(
            token,
            verificationDetails
        );
        return verificationResults.token;
    }
     
    render() {
        return (
            <div className="">
                <div className="text-center">
                    <h1>Demo Square</h1>
                    <form id="payment-form">
                        <div id="card-container"></div>
                        <button id="card-button" className="card-button-css" type="button">Pay $1.00</button>
                    </form>
                    <div id="payment-status-container"></div>
                </div>
            </div>
        )
    }
}
 
export default Demosqure;