/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 11/25/2020, Fixed open cart item that time UI responsive
 * shyam vadaliya, 11/27/2020, Working Add Breadcrumb
 */
 import React from "react";
 import uuid from 'react-uuid';
 import $ from "jquery";
 import queryString from 'query-string'
 import swal from 'sweetalert';
 import ReactTooltip from 'react-tooltip';
 import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
 import { getVendorProductData } from './service'
 import { getSingleVendorData } from '../home/service'
 import noImage from "../../assets/no-image.svg";
 import {ReactComponent as Spinner} from "../../assets/spinner.svg";
 import searchIcon from "../../assets/search-icon.png";
 import Navi from "../../nav";
 import {Modal, Button, Tabs, Tab } from "react-bootstrap";
 import './vendorProduct.css';
 const moment = require('moment-timezone');
 
 let cartItems = [];
 let cartItemsIds = [];
 let childOptionTopping = [];
 let childOptionToppingObjArray = [];
 let tmpUpsellWithAmount = 0;
 let tmpSpecialInstructions = [];
 let currentDay;
 let queryData;
 const convertTime12to24 = (time12h) => {
     time12h = time12h.replace('PM', ' PM');
     time12h = time12h.replace('AM', ' AM');
     const [time, modifier] = time12h.split(' ');
     
     let [hours, minutes] = time.split(':');
     
     if (hours === '12') {
         hours = '00';
     }
     
     if (modifier === 'PM') {
         hours = parseInt(hours, 10) + 12;
     }
     
     return `${hours}:${minutes}`;
 }
 const dayPassReturnKey = (day) => {
     let key = 0;
         if (day === 'Sunday') {
             key = 0;
         }
         else if (day === 'Monday') {
             key = 1;
         }
         else if (day === 'Tuesday') {
             key = 2;
         }
         else if (day === 'Wednesday') {
             key = 3;
         }
         else if (day === 'Thursday') {
             key = 4;
         }
         else if (day === 'Friday') {
             key = 5;
         } else {
             key = 6;
         }
         return key;
 }
 // eslint-disable-next-line
 const getDayFunction = (day) => {
     // eslint-disable-next-line
     switch (day) {
         case 0:
             return "SUN";
         case 1:
             return "MON";
         case 2:
             return "TUE";
         case 3:
             return "WED";
         case 4:
             return "THU";
         case 5:
             return "FRI";
         case 6:
             return "SAT";
     }
 }
 // eslint-disable-next-line
 switch (new Date().getDay()) {
     case 0:
         currentDay = "SUN";
         break;
     case 1:
         currentDay = "MON";
         break;
     case 2:
         currentDay = "TUE";
         break;
     case 3:
         currentDay = "WED";
         break;
     case 4:
         currentDay = "THU";
         break;
     case 5:
         currentDay = "FRI";
         break;
     case 6:
         currentDay = "SAT";
 }
 
 function parseTime(s, key) {
     var c = s.split(':');
     let addMinute = 0;
     if (key === 'add') {
         addMinute = 30;
     }
     return (parseInt(c[0]) * 60) + parseInt(c[1]) + parseInt(addMinute);
 }
   
 function convertHours(mins){
     var hour = Math.floor(mins/60);
     var tmins = mins%60;
     var converted = pad(hour, 2)+':'+pad(tmins, 2);
     return converted;
   }
   
 function pad (str, max) {
 str = str.toString();
 return str.length < max ? pad("0" + str, max) : str;
 }
   
 const manageCurrentTime = (timezone, dateType, date) => {
     var options = {
         timeZone: timezone,
     };
     if (dateType === 'weekDay') {
         options.weekday = 'long';
         var formatterW = new Intl.DateTimeFormat([], options);
         var currentTimeW = formatterW.format(date);
         return currentTimeW;
     }
     if (!dateType) {
         return Number(moment.tz(timezone).format("HH")+moment.tz().format("mm"))
     }
     if (dateType === 'short') {
         options.year = 'numeric';
         options.month = '2-digit';
         options.day = '2-digit';
         var formatter1 = new Intl.DateTimeFormat(['en-US'], options);
         var currentTime1 = formatter1.format(date);
         return currentTime1;
     }
     var formatter2 = new Intl.DateTimeFormat([], options);
     var currentTime2 = formatter2.format(new Date());
     return parseInt(currentTime2.split(':').join(''));
 }
 
 const manageTabArrayFun = (array, timezone) => {
     let isSingleRecord = false;
     for (let i = 0; i < array.length; i += 1) {
         if (array[i].door) {
             var value = array[i].door.split('-');
             var startTime = parseInt(value[0].split(':').join(''));
             var endTime = parseInt(value[1].split(':').join(''));
             if (startTime <= manageCurrentTime(timezone) && endTime >= manageCurrentTime(timezone)) {
                 array[i].isSelected = true;
             } else {
                 array[i].isSelected = false;
             }
         }
     }
 
     for (let i = 0; i < array.length; i += 1) {
         if (array[i].isSelected) {
             isSingleRecord = false;
             break;
         } else {
             isSingleRecord = true;
         }
     }
 
     if (isSingleRecord) {
         let tIsSingleRecord = false;
         for (let i = 0; i < array.length; i += 1) {
             if (array[i].door) {
                 var tvalue = array[i].door.split('-');
                 var tstartTime = parseInt(tvalue[0].split(':').join(''));
                 if (tstartTime >= manageCurrentTime(timezone)) {
                     array[i].isSelected = true;
                     tIsSingleRecord = false;
                     break;
                 } else {
                     tIsSingleRecord = true;
                 }
             }
         }
         if (tIsSingleRecord) {
             array[0].isSelected = true;
         }
         return array;
     }
     return array;
 }
 function toRad(Value) {
     return (Value * Math.PI) / 180;
 }
 
 const distanceCalculation = (lat1, lon1, lat2, lon2) => {
     var R = 6371; // km
     var dLat = toRad(lat2 - lat1);
     var dLon = toRad(lon2 - lon1);
     var lat = toRad(lat1);
     var lat3 = toRad(lat2);
     let factor = 0.621371;
     var a =
     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
     Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat) * Math.cos(lat3);
     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
     var d = R * c;
     var distance = parseFloat(d * factor);
     distance = distance.toFixed(2);
     return Math.round(distance);
 }
 const manageScheduleArray = (vendorData, currentDay) => {
     let tmpArray = [];
     for (const [key, value] of Object.entries(vendorData.schedule[currentDay])) {
         if (value && value.available) {
             tmpArray.push({
                 title: key,
                 available: value.available,
                 delivery: value.delivery,
                 door: value.door,
                 order: value.order
             });
         }
     }
     if (tmpArray && tmpArray.length) {
         tmpArray.sort(function(a, b) {
             return a.order - b.order;
         });
         return tmpArray;
     } else {
         return tmpArray;
     }
 };
 
 class VendorProduct extends React.Component {
     constructor(props) {
         super(props);
         this.state = {
             vendorData: {},
             tmpData: '',
             productData: [],
             allProductData: [],
             productDetailsObj: {},
             strProductObj: {},
             userAddress1: null,
             productModelShow: false,
             productModelShowLatest: false,
             isSpinner: false,
             searchValue: '',
             tmpProduct: [],
             fullProductData: [],
             restaurantNotAvailable: false,
             tabsArray: [],
             selectTabObj: {},
             dining: false,
             isActiveAddressType: 2,
             isOpenScheduleModel: false,
             selectValueOrderType: 'Order Now',
             userLng: 0,
             userLat: 0,
             userAddress: '',
             scheduleData: [],
             orderAddressObj: {},
             scheduleTimeData: [],
             defaultValueTime: '',
             scheduleTimeDate: '',
             catIndex: 0,
             productIndex: 0,
             browerLatitude: 0,
             browerLongitude: 0,
             openMoreDetails: false,
             deliveryFullData: {},
             linkId: '',
             linkIds: [],
             Error: {
                 modifiresError: {message: '', index: []},
             },
             queryData: queryString.parse(props.location.search),
             searchCateName: '',
             productPrice: 0
         };
         queryData = queryString.parse(props.location.search);
         if (queryData.couponcode && queryData.email) {
             localStorage.setItem(this.props.match.params.vendorId + '___dealCouponAndEmail', JSON.stringify(queryData));
         }
     }
     componentDidMount() {
         let self = this;
         navigator.geolocation.getCurrentPosition(function(position) {
             self.setState({
                 browerLatitude: position?.coords?.latitude ? position.coords.latitude : 0,
                 browerLongitude: position?.coords?.longitude ? position.coords.longitude : 0
             });
         });
         const paramsData = this.props.match.params;
         getSingleVendorData(paramsData.vendorId, (vendors) => {
             if (!vendors.status) {
                 this.setState({
                     isSpinner: true,
                     vendorData: {}
                 });
                 return;
             }
             let vendorData = vendors.data;
             localStorage.removeItem('orderReceiveData');
             localStorage.removeItem(paramsData.vendorId + '___orderObj');
             if (localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') {
                 this.setState({dining: true});
             } else {
                 this.setState({dining: false});
                 if (!localStorage.getItem(paramsData.vendorId + '___delivery')) {
                     localStorage.setItem(paramsData.vendorId + '___delivery', JSON.stringify({
                         isActiveAddressType: 2,
                         DeliveryType: 'Pick at Restaurant',
                         isUnivercity: false,
                         orderType: 'Order Now'
                     }));
                     this.setState({
                         deliveryFullData: {
                             isActiveAddressType: 2,
                             DeliveryType: 'Pick at Restaurant',
                             isUnivercity: false,
                             orderType: 'Order Now'
                         }
                     })
                 } else {
                     const ttmpDelivery = JSON.parse(localStorage.getItem(paramsData.vendorId + '___delivery'));
                     
                     this.setState({
                         deliveryFullData: ttmpDelivery,
                         isActiveAddressType: ttmpDelivery.isActiveAddressType,
                         selectValueOrderType: ttmpDelivery.orderType,
                         defaultValueTime: ttmpDelivery.scheduleTime || '',
                         userAddress1: ttmpDelivery.userAddress1 || null,
                         userAddress: ttmpDelivery.line1 || '',
                         scheduleTimeDate: ttmpDelivery.scheduleDate,
                     });
                 }
             }
             const initTabsFun = (vendorData) => {
                 if (!(vendorData && vendorData.schedule && Object.keys(vendorData.schedule).length)) {
                     this.setState({restaurantNotAvailable: true});
                     this.resetAddtoCart();
                     this.setState({restaurantNotAvailableReason: 'Restaurant Not Available.'});
                 }
                 
                 if (!(vendorData && vendorData.schedule && Object.keys(vendorData.schedule).length && vendorData.schedule[currentDay] && Object.keys(vendorData.schedule[currentDay]).length)) {
                     this.setState({restaurantNotAvailable: true});
                     this.resetAddtoCart();
                     this.setState({restaurantNotAvailableReason: 'Restaurant Not Available.'});
                 }
         
                 if (vendorData && vendorData.schedule && Object.keys(vendorData.schedule).length && vendorData.schedule[currentDay] && Object.keys(vendorData.schedule[currentDay]).length) {
                     const tmpArray = [];
                     for (const [key, value] of Object.entries(vendorData.schedule[currentDay])) {
                         if (value && value.available) {
                             tmpArray.push({
                                 title: key,
                                 available: value.available,
                                 delivery: value.delivery,
                                 door: value.door,
                                 order: value.order
                             });
                         }
                     }
                     if (tmpArray && tmpArray.length) {
                         tmpArray.sort(function(a, b) {
                             return a.order - b.order;
                         });
                         let selectTabObj = {};
                         for (let ii = 0; ii < manageTabArrayFun(tmpArray, vendorData.tz).length; ii += 1) {
                             if (manageTabArrayFun(tmpArray, vendorData.tz)[ii].isSelected) {
                                 selectTabObj = manageTabArrayFun(tmpArray, vendorData.tz)[ii];
                                 break;
                             }
                         }
                         this.setState({tabsArray: manageTabArrayFun(tmpArray, vendorData.tz)})
                         this.setState({selectTabObj: selectTabObj})
                         this.tabsChange(selectTabObj.title, manageTabArrayFun(tmpArray, vendorData.tz));
                     }
                     if (!(tmpArray && tmpArray.length)) {
                         this.setState({restaurantNotAvailable: true});
                         this.resetAddtoCart();
                         this.setState({restaurantNotAvailableReason: 'Restaurant Not Available.'});
                     }
                 }
     
                 if (vendorData && vendorData.offdays && vendorData.offdays.length) {
                     var today = new Date();
                     var dd = today.getDate();
     
                     var mm = today.getMonth()+1; 
                     var yyyy = today.getFullYear();
                     if (dd < 10) {
                         dd='0'+dd;
                     }
                     if (mm < 10) {
                         mm='0'+mm;
                     }
                     const date = mm+'-'+dd+'-'+yyyy;
                     for (let ii = 0; ii < vendorData.offdays.length; ii += 1) {
                         if (vendorData.offdays[ii].date === date) {
                             this.setState({restaurantNotAvailable: true});
                             this.resetAddtoCart();
                             this.setState({restaurantNotAvailableReason: `${vendorData.offdays[ii].reason}.`});
                             break;
                         }
                     }
                 }
             }
             let tmpaddToCard = JSON.parse(localStorage.getItem('addToCard'));
             let uniqueCartData = {}
             if (tmpaddToCard && tmpaddToCard.cartItems && tmpaddToCard.cartItems.length) {
                 cartItems = tmpaddToCard.cartItems;
                 for (let i = 0; i < cartItems.length; i += 1) {
                     if (!uniqueCartData[cartItems[i].id]) {
                         uniqueCartData[cartItems[i].id] = 0;
                     }
                     uniqueCartData[cartItems[i].id] += cartItems[i].quantity;
                     cartItemsIds.push(cartItems[i].id);
                 }
             }
     
             if (!(vendorData && vendorData.deliveryPublic)) {
                 this.setState({
                     isActiveAddressType: 2
                 });
             }
             this.setState({vendorData:vendorData});
             const manageProductFun = (vendorData) => {
                 var manageProductData = [];
                 getVendorProductData(vendorData.id, (vendorsProducts) => {
                     if (vendorsProducts && vendorsProducts.status) {
                         const vendorsProductsData = vendorsProducts.data;
                         if (vendorData && vendorData.product_categories && vendorData.product_categories.length) {
                             for (let i = 0; i < vendorData.product_categories.length; i += 1) {
                                 manageProductData.push({
                                     categoryName: vendorData.product_categories[i],
                                     product: []
                                 });
                             }
                             let tmpAllProduct = [];
                             // eslint-disable-next-line
                             for (const [key, value] of Object.entries(vendorsProductsData.vendor_products)) {
                                 if (!value.disable) {
                                     for (let ii = 0; ii < manageProductData.length; ii += 1) {
                                         if (manageProductData[ii].categoryName === value.product_category) {
                                             value.Defaultquantity = 1;
                                             value.select_optional_items = [];
                                             value.select_all_items = [];
                                             value.select_child_options_without_topping = [];
                                             value.quantityCount = uniqueCartData[value.id] || 0;
                                             tmpAllProduct.push(value);
                                             manageProductData[ii].product.push(value);
                                             var temp = manageProductData[ii].product.sort((a, b) => a.sortingOrderBy - b.sortingOrderBy);
                                             manageProductData[ii].product=temp;
                                         }
                                     }
                                 }
                             }
                             this.setState({tmpProductData:manageProductData, fullProductData: JSON.stringify(manageProductData), tmpProduct: JSON.stringify(manageProductData), allProductData: tmpAllProduct});
                             this.setState({isSpinner: true});
                             initTabsFun(vendorData);
                         } else {
                             this.setState({tmpProductData:[], isSpinner: true, fullProductData: JSON.stringify([]), tmpProduct: JSON.stringify([]), tmpAllProduct: []});
                             initTabsFun(vendorData);
                         }
                     } else {
                         this.setState({tmpProductData:[], isSpinner: true, fullProductData: JSON.stringify([]), tmpProduct: JSON.stringify([])});
                         this.setState({restaurantNotAvailable: true});
                         this.resetAddtoCart();
                         this.setState({restaurantNotAvailableReason: 'Restaurant Not Available.'});
                     }
                 });
             }
             if (vendorData) {
                 manageProductFun(vendorData);
             }
         });
     }
     resetAddtoCart() {
         const vendorId = this.props.match.params.vendorId;
         const tmpAddToCart = JSON.parse(localStorage.getItem('addToCard'));
         const tmpAddToCartRemove = [];
         if (tmpAddToCart && tmpAddToCart.cartItems && tmpAddToCart.cartItems.length) {
             for (let i = 0; i < tmpAddToCart.cartItems.length; i += 1) {
                 if (tmpAddToCart.cartItems[i].vendorID !== vendorId) {
                     tmpAddToCartRemove.push(tmpAddToCart.cartItems[i]);
                 }
             }
         }
         if (!(tmpAddToCart && tmpAddToCart.cartItems && tmpAddToCart.cartItems.length)) {
             localStorage.removeItem('addToCard');
         } else {
             localStorage.setItem('addToCard', JSON.stringify({cartItems: tmpAddToCartRemove}));
         }
         this.child.getCartCount();
     }
     tabsChange(key, data) {
         let tabsData = [];
         const tmpVendorData = this.state.vendorData;
         if (!(this.state && this.state.tabsArray && this.state.tabsArray.length)) {
             tabsData = data;
         } else {
             tabsData = this.state.tabsArray;
         }
         if (tabsData && tabsData.length) {
             for (let i = 0; i < tabsData.length; i += 1) {
                 if (tabsData[i].title === key) {
                     this.setState({selectTabObj: tabsData[i]});
                     var value = tabsData[i].door.split('-');
                     var startTime = parseInt(value[0].split(':').join(''));
                     var endTime = parseInt(value[1].split(':').join(''));
                     if (startTime <= manageCurrentTime(tmpVendorData.tz) && endTime >= manageCurrentTime(tmpVendorData.tz)) {
                         tabsData[i].isOpenMenu = true;
                     } else {
                         tabsData[i].isOpenMenu = false;
                     }
                     tabsData[i].isSelected = true;
                     // break;
                 } else {
                     tabsData[i].isSelected = false;
                 }
             }
             this.setState({tabsArray: tabsData});
             this.child.handleClose();
         }
         const tmpArray = [];
         if (this.state && this.state.tmpProductData && this.state.tmpProductData.length) {
             // eslint-disable-next-line
             for (const [field, value] of Object.entries(this.state.tmpProductData)) {
                 if (value && value.product && value.product.length) {
                     for (let i = 0; i < value.product.length; i += 1) {
                         if (value.product[i].available && value.product[i].available.indexOf(key) !== -1) {
                             if (!tmpArray[key]) {
                                 tmpArray[key] = [];
                             }
                             tmpArray[key].push(value.product[i]);
                         }
                     }
                 }
             }
 
             const manageData = (nameKey, myArray) => {
                 for (var i = 0; i < myArray.length; i++) {
                     if (myArray[i].categoryName === nameKey) {
                         return i;
                     }
                 }
             };
             let productData = [];
             if (tmpArray[key] && tmpArray[key].length) {
                 tmpArray[key].forEach((doc, vIndex) => {
                     let index = null;
                     index = manageData(doc.product_category, productData);
                     if (index !== undefined) {
                         productData[index].product.push(doc);
                     } else {
                         productData.push({ categoryName: doc.product_category, product: [doc] });
                     }
                 })
                 for (let ii = 0; ii < productData.length; ii += 1) {
                     if (productData[ii].product && productData[ii].product.length) {
                         productData[ii].product.sort(function(a, b) {
                             return b.priority - a.priority;
                         });
                     }
                 }
                 
                 this.setState({productData:productData, fullProductData: JSON.stringify(productData), tmpProduct: JSON.stringify(productData), tmpData: JSON.stringify(productData)});
             } else {
                 this.setState({productData:[], fullProductData: JSON.stringify([]), tmpProduct: JSON.stringify([]), tmpData: JSON.stringify([])});
             }
         }
         $('#search').val('');
         this.setState({searchValue: ''});
         setTimeout(() => {
             this.changeSearchCateName('');
         }, 100);
     }
     searchChange(value) {
         if (value === "") {
             this.setState({productData: JSON.parse(this.state.tmpProduct)});
             this.setState({searchValue: value});
         } else {
             let finalFilterData = [];
             let allData = JSON.parse(this.state.tmpProduct);
             this.setState({searchValue: value});
             if (allData && allData.length) {
                 for (let i = 0; i < allData.length; i += 1) {
                     let filterProduct = [];
                     if (allData[i].product && allData[i].product.length) {
                         let filterData = 
                         allData[i].product.filter(data => {
                             return data.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || data.description.toLowerCase().indexOf(value.toLowerCase()) > -1;
                         })
                         filterProduct.push(...filterData);
                     }
                     if(filterProduct.length > 0){
                         allData[i].product = filterProduct;
                         finalFilterData.push(allData[i])
                     }
                 }
             }
             this.setState({productData: finalFilterData, tmpData: JSON.stringify(finalFilterData)});
         }
     }
     changeSearchCateName(name) {
         this.refs = {
             searchInput: {
                 value: ""
             }  
         };
         this.setState({
             searchCateName: name
         });
         if (name === "") {
             this.setState({productData: JSON.parse(this.state.fullProductData), tmpData: this.state.fullProductData, tmpProduct: this.state.fullProductData});
         } else {
             let finalFilterData = [];
             let allData = JSON.parse(this.state.fullProductData);
             if (allData && allData.length) {
                 for (let i = 0; i < allData.length; i += 1) {
                     if (allData[i].product && allData[i].product.length && name === allData[i].categoryName) {
                         finalFilterData.push(allData[i]);
                     }   
                 }
             }
             this.setState({productData: finalFilterData, tmpData: JSON.stringify(finalFilterData), tmpProduct: JSON.stringify(finalFilterData)});
         }
     }
     openProductModelLatest(row, index, pIndex) {
         this.setState({
             catIndex: index,
             productIndex: pIndex,
             Error: {
                 modifiresError: {message: '', index: []},
             },
         });
         row.totalAmount = 0;
         childOptionTopping = [];
         childOptionToppingObjArray = [];
         tmpUpsellWithAmount = 0;
         tmpSpecialInstructions = [];
         row.variant = [];
         row.compareVariant = [];
         row.Defaultquantity = 1;
         cartItemsIds = [];
         cartItems = [];
         let tmpaddToCard = JSON.parse(localStorage.getItem('addToCard'));
         if (tmpaddToCard && tmpaddToCard.cartItems && tmpaddToCard.cartItems.length) {
             cartItems = tmpaddToCard.cartItems;
             for (let i = 0; i < cartItems.length; i += 1) {
                 cartItemsIds.push(cartItems[i].id);
             }
         }
 
         // Manage special instruction
         row.add_special_instructions = '';
 
         if (row && row.upsell && row.upsell.length) {
             var TempData = this.state.tmpProductData;
             var upsellData = [];
             var upsellAll = [];
             // eslint-disable-next-line
             this.state.allProductData.map(function(data, index1) {
                 // eslint-disable-next-line
                 row.upsell.map((itm, inx) => {
                     if (itm.ids.includes(data.id)) {
                         const isLargeNumber = element =>
                             element.categoryName === data.product_category;
                         var tempMainIndex = TempData.findIndex(isLargeNumber);
                         const isLargeNumberNew = element => element.id === data.id;
                         var tempIndex1 = TempData[tempMainIndex].product.findIndex(
                             isLargeNumberNew,
                         );
                         data.Defaultquantity = 0;
                         data.tmpUpsellAmount = 0;
                         data.quantity = 0;
                         data.add_special_instructions = '';
                         data.variant = [];
                         data.compareVariant = [];
                         data.totalAmount = Number(data.price);
                         upsellData.push({
                             data: data,
                             index: tempIndex1,
                             mainIndex: tempMainIndex,
                             upsellindex: inx,
                         });
                     }
                 });
             });
             // eslint-disable-next-line
             row.upsell.map((itm, inx) => {
                 upsellAll.push({
                 title: itm.name,
                 description: itm.description,
                 data: upsellData.filter(item => itm.ids.includes(item.data.id)),
                 });
             });
             row.upsellAll = upsellAll;
         }
 
 
         // Latest Modifier Manage
         let tmplinkId = [];
         if (row && row.modifiers && row.modifiers.length) {
             for (let i = 0; i < row.modifiers.length; i += 1) {
                 if (row.modifiers[i].isItemOption && row.modifiers[i].options && row.modifiers[i].options.length) {
                     for (let ii = 0; ii < row.modifiers[i].options.length; ii += 1) {
                         if (row.modifiers[i].options[ii].isChecked) {
                             row.compareVariant.push(`${row.modifiers[i].displayName} ${row.modifiers[i].options[ii].name}`);
                             row.variant.push({
                                 title: row.modifiers[i].displayName,
                                 id: row.modifiers[i].options[ii].id,
                                 name: row.modifiers[i].options[ii].name,
                                 price: row.modifiers[i].options[ii].price,
                                 isItemOption: true
                             });
                             tmplinkId.push(row.modifiers[i].options[ii].id)
                         }
                     }
                 }
             }
         }
         let productPrice = 0;
         if (row && row.modifiers && row.modifiers.length) {
             for (let i = 0; i < row.modifiers.length; i += 1) {
                 for (let ii = 0; ii < row.modifiers[i].options.length; ii += 1) {
                     if (row.modifiers[i].options[ii].isChecked) {
                         productPrice += Number(row.modifiers[i].options[ii].price);
                     }
                 }
             }
         }
         this.setState({productDetailsObj:row, strProductObj: JSON.stringify(row), linkIds: tmplinkId, productPrice: productPrice});
         this.setState({productModelShowLatest: true});
     }
     closeProductModelLatest() {
         this.setState({productModelShowLatest: false, productData:JSON.parse(this.state.tmpData)})
     }
     decreaseItem(row) {
         if (row.Defaultquantity === 1) {
             return;
         }
         row.Defaultquantity -= 1;
         this.setState({productData:this.state.productData})
     }
     increaseItem(row) {
         row.Defaultquantity += 1;
         this.setState({productData:this.state.productData})
     }
     upsellDecreaseItem(row, data) {
         if (row.Defaultquantity === 0) {
             return;
         }
         row.Defaultquantity -= 1;
         row.tmpUpsellAmount -= Number(row.price);
         tmpUpsellWithAmount = data.reduce(
             (prev, next) => 
                 prev + Number(next.data.tmpUpsellAmount)
             , 0,
         );
         this.setState({productData:this.state.productData})
     }
     upsellIncreaseItem(row, data) {
         row.Defaultquantity += 1;
         row.tmpUpsellAmount = (Number(row.price) * row.Defaultquantity);
         tmpUpsellWithAmount = data.reduce(
             (prev, next) => 
                 prev + Number(next.data.tmpUpsellAmount)
             , 0,
         );
         this.setState({productData:this.state.productData})
     }
     changeSpecialInstructions(e, siUpdaterow) {
         siUpdaterow.add_special_instructions = e.target.value;
         tmpSpecialInstructions = [e.target.value];
     }
     checkValidateLatest(){
         const error = {
             modifiresError: {
                 message: '',
                 index: [],
             }
         }
         this.setState({
             Error: error
         });
         let tmpValidationCheck = true;
         let indexadd = [];
         // eslint-disable-next-line
         this.state.productDetailsObj?.modifiers?.map((item, index) => {
             if (item && item.choiceLimit && !item.isItemOption && !item.isComesWith && !item.isPizzaTopping) {
                 if (!item.isItemOptionId || item.isItemOptionId === "") {
                     const result = item.options.filter(word => word.isChecked === true);
                     var valid = true;
                     let tmpMessage = '';
                     if (item.choiceLimit !== "" && !item.isRequired && !item.isFixed) {
                         if (Number(item.choiceLimit) >= result.length) {
                             valid = true;
                         } else {
                             valid = false;
                         }
                     }
                     if (item.choiceLimit !== "" && item.isRequired && !item.isFixed) {
                         if (Number(item.choiceLimit) <= result.length) {
                             valid = true;
                         } else {
                             tmpMessage = `You must choose minimum ${item.choiceLimit} items `;
                             valid = false;
                         }
                     }
 
                     if (item.choiceLimit !== "" && !item.isRequired && item.isFixed) {
                         if (Number(item.choiceLimit) === result.length) {
                             valid = true;
                         } else {
                             tmpMessage = `You must choose ${item.choiceLimit} items `;
                             valid = false;
                         }
                     }
                     if (!valid) {
                         tmpValidationCheck = false;
                     }
                     if (!valid) {
                         indexadd.push(index);
                         let tError = {
                             modifiresError: {
                                 message: tmpMessage,
                                 index: indexadd,
                             }
                         };
                         this.setState({
                             Error:tError
                         });
                         // eslint-disable-next-line
                         this.state.Error = {
                             modifiresError: {
                                 message: tmpMessage,
                                 index: indexadd,
                             },
                         };
                     }
                 }
                 if (this.state.linkIds.indexOf(item.isItemOptionId) !== -1) {
                     const result = item.options.filter(word => word.isChecked === true);
                     // eslint-disable-next-line
                     var valid = true;
                     let tmpMessage = '';
                     if (item.choiceLimit !== "" && !item.isRequired && !item.isFixed) {
                         if (Number(item.choiceLimit) >= result.length) {
                             valid = true;
                         } else {
                             valid = false;
                         }
                     }
                     if (item.choiceLimit !== "" && item.isRequired && !item.isFixed) {
                         if (Number(item.choiceLimit) <= result.length) {
                             valid = true;
                         } else {
                             tmpMessage = `You must choose minimum ${item.choiceLimit} items `;
                             valid = false;
                         }
                     }
                     if (item.choiceLimit !== "" && !item.isRequired && item.isFixed) {
                         if (Number(item.choiceLimit) === result.length) {
                             valid = true;
                         } else {
                             tmpMessage = `You must choose ${item.choiceLimit} items `;
                             valid = false;
                         }
                     }
                     if (!valid) {
                         tmpValidationCheck = false;
                     }
                     if (!valid) {
                         indexadd.push(index);
                         let tError = {
                             modifiresError: {
                                 message: tmpMessage,
                                 index: indexadd,
                             }
                         };
                         this.setState({
                             Error:tError
                         });
                         // eslint-disable-next-line
                         this.state.Error = {
                             modifiresError: {
                                 message: tmpMessage,
                                 index: indexadd,
                             },
                         };
                     }
                 }
             }
         });
         if (tmpValidationCheck) {
             // eslint-disable-next-line
             this.state.Error = {
                 modifiresError: {
                     message: '',
                     index: [],
                 },
             };
         }
         if (this.state.Error.modifiresError.index.length === 0) {
             // eslint-disable-next-line
             this.state.Error = {
                 modifiresError: {
                     message: '',
                     index: [],
                 },
             };
             this.addtoCartLatest(this.state.productDetailsObj);
         } else {
             const tmpError = this.state.Error;
             this.setState({Error: tmpError});
             // var container = $(".product_modal");
             // var scrollTo = $(".error-scroll-top");
             // container.animate({
             //     scrollTop: scrollTo.offset().top - container.offset().top
             // });
             return;
         }
     }
     // Latest Code Come with
     changeComesWith(e, oiUpdaterow, oiRow, moiRow) {
         let productPrice = this.state.productPrice;
         if (oiRow.isChecked) {
             oiRow.isChecked = false;
             productPrice -= Number(oiRow.price);
             oiUpdaterow.compareVariant.push(`${moiRow.displayName} No ${oiRow.name}`);
             oiUpdaterow.variant.push({
                 title: moiRow.displayName,
                 id: oiRow.id,
                 name: `No ${oiRow.name}`,
                 isComesWith: true,
                 price: oiRow.price,
             });
         } else {
             oiRow.isChecked = true;
             if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                 for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                     if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isComesWith) {
                         productPrice += Number(oiRow.price);
                         oiUpdaterow.compareVariant.splice(i, 1);
                         oiUpdaterow.variant.splice(i, 1);
                     }
                 }
             }
         }
         this.setState({productDetailsObj: this.state.productDetailsObj, productPrice: productPrice});
     }
     // Latest Code Item Options
     changeItemOptions(e, aoUpdaterow, aoRow, row, index) {
         let productPrice = this.state.productPrice;
         let tmplinkId = this.state.linkIds;
         for (let i = 0; i < row.options.length; i += 1) {
             if (i === index) {
                 row.options[i].isChecked = true; 
                 productPrice += Number(aoRow.price);
                 tmplinkId.push(row.options[i].id);
                 aoUpdaterow.compareVariant.push(`${row.displayName} No ${aoRow.name}`);
                 aoUpdaterow.variant.push({
                     title: row.displayName,
                     id: aoRow.id,
                     name: aoRow.name,
                     price: aoRow.price,
                     isItemOption: true
                 });
             } else {
                 if (row.options[i].isChecked) {
                     productPrice -= Number(row.options[i].price);
                     if (aoUpdaterow.variant && aoUpdaterow.variant.length) {
                         for (let ii = 0; ii < aoUpdaterow.variant.length; ii += 1) {
                             if (aoUpdaterow.variant[ii].id === row.options[i].id) {
                                 aoUpdaterow.compareVariant.splice(ii, 1);
                                 aoUpdaterow.variant.splice(ii, 1);
                             }
                         }
                     }
                 }
                 row.options[i].isChecked = false;
                 if (tmplinkId.indexOf(row.options[i].id) !== -1) {
                     tmplinkId.splice(tmplinkId.indexOf(row.options[i].id), 1)
                 }
             }
         }
         childOptionToppingObjArray = [];
         childOptionTopping = [];
         if (aoUpdaterow && aoUpdaterow.modifiers && aoUpdaterow.modifiers.length) {
             for (let ii = 0; ii < aoUpdaterow.modifiers.length; ii += 1) {
                 if (aoUpdaterow.modifiers[ii].displayName && aoUpdaterow.modifiers[ii].isPizzaTopping) {
                     for (let iii = 0; iii < aoUpdaterow.modifiers[ii].options.length; iii += 1) {
                         if (aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'full') {
                             productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].fullPrice);
                         } else if (aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'right' || aoUpdaterow.modifiers[ii].options[iii].selectedValue === 'left') {
                             productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].halfPrice);
                         }
                         if (this.state.linkIds.indexOf(aoRow.id) !== -1) {
                             if (aoUpdaterow.modifiers[ii].options[iii].include) {
                                 aoUpdaterow.modifiers[ii].options[iii].selectedValue = 'full';
                                 childOptionToppingObjArray.push({
                                     title: aoUpdaterow.modifiers[ii].displayName,
                                     name: aoUpdaterow.modifiers[ii].options[iii].name,
                                     id: aoUpdaterow.modifiers[ii].options[iii].id,
                                     price: aoUpdaterow.modifiers[ii].options[iii].fullPrice,
                                     type: 'Full'
                                 });
                                 if (childOptionTopping.indexOf(`${aoUpdaterow.modifiers[ii].displayName} Full ${aoUpdaterow.modifiers[ii].options[iii].name}`) === -1) {
                                     childOptionTopping.push(`${aoUpdaterow.modifiers[ii].displayName} Full ${aoUpdaterow.modifiers[ii].options[iii].name}`);
                                 }
                             }
                             if (!aoUpdaterow.modifiers[ii].options[iii].include) {
                                 aoUpdaterow.modifiers[ii].options[iii].selectedValue = '';
                             }
                         }
                     }
                 }
                 if (aoUpdaterow.modifiers[ii].isItemOptionId && aoUpdaterow.modifiers[ii].isItemOptionId !== "" && this.state.linkIds.indexOf(aoRow.id) !== -1) {
                     for (let iii = 0; iii < aoUpdaterow.modifiers[ii].options.length; iii += 1) {
                         if (aoUpdaterow.modifiers[ii].options[iii].isChecked) {
                             aoUpdaterow.modifiers[ii].options[iii].isChecked = false;
                             productPrice -= Number(aoUpdaterow.modifiers[ii].options[iii].price);
                             for (let j = 0; j < aoUpdaterow.variant.length; j += 1) {
                                 if (aoUpdaterow.variant[j].id === aoUpdaterow.modifiers[ii].options[iii].id) {
                                     aoUpdaterow.variant.splice(j, 1);
                                     aoUpdaterow.compareVariant.splice(j, 1);
                                 }
                             }
                         }
                     }
                 }
             }
         }
         this.setState({productDetailsObj: this.state.productDetailsObj, linkIds: tmplinkId, productPrice: productPrice});
     }
     // Latest Code Item Option Select 
     changeItemOptionsSelect(e, oiUpdaterow, oiRow, moiRow) {
         let productPrice = this.state.productPrice;
         if (moiRow && moiRow.choiceLimit && moiRow.choiceLimit !== "" && !moiRow.isRequired) {
             oiRow['isChecked'] = !oiRow.isChecked;
             const result = moiRow.options.filter(
                 word => word.isChecked === true,
             );
             var valid = true;
             if (moiRow.choiceLimit !== "" && !moiRow.isRequired) {
                 if (Number(moiRow.choiceLimit) >= result.length) {
                     valid = true;
                 } else {
                     oiRow['isChecked'] = !oiRow.isChecked;
                     valid = false;
                 }
             }
             if (valid) {
                 if (oiRow.isChecked === true) {
                     oiRow.isChecked = true;
                     productPrice += Number(oiRow.price);
                     oiUpdaterow.compareVariant.push(`${moiRow.displayName} ${oiRow.name}`);
                     oiUpdaterow.variant.push({
                         title: moiRow.displayName,
                         name: oiRow.name,
                         id: oiRow.id,
                         isItemOptionSelect: true,
                         price: oiRow.price
                     });
                 } else {
                     oiRow.isChecked = false;
                     if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                         for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                             if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isItemOptionSelect) {
                                 productPrice -= Number(oiUpdaterow.variant[i].price);
                                 oiUpdaterow.compareVariant.splice(i, 1);
                                 oiUpdaterow.variant.splice(i, 1);
                             }
                         }
                     }
                     
                 }
             }
         } else {
             if (!oiRow.isChecked) {
                 oiRow.isChecked = true;
                 productPrice += Number(oiRow.price);
                 oiUpdaterow.compareVariant.push(`${moiRow.displayName} ${oiRow.name}`);
                 oiUpdaterow.variant.push({
                     title: moiRow.displayName,
                     name: oiRow.name,
                     id: oiRow.id,
                     isItemOptionSelect: true,
                     price: oiRow.price
                 });
             } else {
                 oiRow.isChecked = false;
                 if (oiUpdaterow.variant && oiUpdaterow.variant.length) {
                     for (let i = 0; i < oiUpdaterow.variant.length; i += 1) {
                         if (oiUpdaterow.variant[i].id === oiRow.id && oiUpdaterow.variant[i].isItemOptionSelect) {
                             productPrice -= Number(oiUpdaterow.variant[i].price);
                             oiUpdaterow.compareVariant.splice(i, 1);
                             oiUpdaterow.variant.splice(i, 1);
                         }
                     }
                 }
             }
         }
         this.setState({productDetailsObj: this.state.productDetailsObj, productPrice: Number(productPrice.toFixed(2))});
     }
     // Pizza Options
     changePizzaOptions(e, key, childOptionsRow, aoRow, index) {
         childOptionToppingObjArray = [];
         childOptionTopping = [];
         let productPrice = this.state.productPrice;
         for (let i = 0; i < aoRow.length; i += 1) {
             if (aoRow[i].selectedValue === 'full') {
                 productPrice -= Number(aoRow[i].fullPrice);
             } else if (aoRow[i].selectedValue === 'left' || aoRow[i].selectedValue === 'right') {
                 productPrice -= Number(aoRow[i].halfPrice);
             }
             if (i === index) {
                 if (aoRow[i].selectedValue === key) {
                     aoRow[i].selectedValue = '';
                 } else {
                     aoRow[i].selectedValue = key;
                 }
             }
         }
         for (let i = 0; i < aoRow.length; i += 1) {
             if (aoRow[i].selectedValue === 'left') {
                 productPrice += Number(aoRow[i].halfPrice);
                 childOptionToppingObjArray.push({
                     title: childOptionsRow.displayName,
                     name: aoRow[i].name,
                     price: aoRow[i].halfPrice,
                     id: aoRow[i].id,
                     type: 'Left Half'
                 });
                 if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Left Half ${aoRow[i].name}`) === -1) {
                     childOptionTopping.push(`${childOptionsRow.displayName} Left Half ${aoRow[i].name}`);
                 }
             }
             if (aoRow[i].selectedValue === 'right') {
                 productPrice += Number(aoRow[i].halfPrice);
                 childOptionToppingObjArray.push({
                     title: childOptionsRow.displayName,
                     name: aoRow[i].name,
                     price: aoRow[i].halfPrice,
                     id: aoRow[i].id,
                     type: 'Right Half'
                 });
                 if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Right Half ${aoRow[i].name}`) === -1) {
                     childOptionTopping.push(`${childOptionsRow.displayName} Right Half ${aoRow[i].name}`);
                 }
             }
             if (aoRow[i].selectedValue === 'full') {
                 productPrice += Number(aoRow[i].fullPrice);
                 childOptionToppingObjArray.push({
                     title: childOptionsRow.displayName,
                     name: aoRow[i].name,
                     price: aoRow[i].fullPrice,
                     id: aoRow[i].id,
                     type: 'Full'
                 });
                 if (childOptionTopping.indexOf(`${childOptionsRow.displayName} Full ${aoRow[i].name}`) === -1) {
                     childOptionTopping.push(`${childOptionsRow.displayName} Full ${aoRow[i].name}`);
                 }
             }
         }
         this.setState({productDetailsObj: this.state.productDetailsObj, productPrice: Number(productPrice.toFixed(2))});
     }
     // Latest Add To Cart Function
     async addtoCartLatest(row) {
         const checkEqualvalue = (arr1, arr2) => {
             if(arr1.length !== arr2.length) {
                 return false;
             } else {
                 for(let i = 0; i < arr1.length; i++) {
                     if(arr1[i] !== arr2[i]) {
                         return false;
                         // eslint-disable-next-line
                         break; 
                     }
                 }
                 return true;
             }
         };
         const finalArray = [];
         const tmpRow = row;
         tmpRow.totalAmount = this.state.productPrice;
         tmpRow.compareVariant = [...tmpRow.compareVariant, ...tmpSpecialInstructions, ...childOptionTopping].sort();
         tmpRow.variant = [...tmpRow.variant,  ...childOptionToppingObjArray];
         tmpRow.price = Number(this.state.productPrice);
 
         finalArray.push(tmpRow);
         const addProductCart = (pArray, cb) => {
             let count = 0;
             const countFunction = (row) => {
                 if (pArray.length === count) {
                     cb(cartItems);
                     return;
                 }
                 if (cartItemsIds.indexOf(row.id) === -1) {
                     cartItemsIds.push(row.id);
                     row.quantity = row.Defaultquantity;
                     row.cartItemId = uuid();
                     cartItems.push(row);
                     count += 1;
                     countFunction(pArray[count]);
                 } else {
                     let findRecord = false;
                     for(let i = 0; i < cartItems.length; i += 1) {
                         if (checkEqualvalue(row.compareVariant.sort(), cartItems[i].compareVariant.sort())) {
                             if (cartItems[i].id === row.id) {
                                 findRecord = true;
                                 cartItems[i].Defaultquantity = cartItems[i].quantity + row.Defaultquantity;
                                 cartItems[i].quantity = cartItems[i].quantity + row.Defaultquantity;
                                 break;
                             } else {
                                 findRecord = false;
                             }
                         }
                     }
                     if (!findRecord) {
                         cartItemsIds.push(row.id);
                         row.quantity = row.Defaultquantity;
                         row.cartItemId = uuid();
                         cartItems.push(row);
                         count += 1;
                         countFunction(pArray[count]);
                     } else {
                         count += 1;
                         countFunction(pArray[count]);
                     }
                 }
             }
             countFunction(pArray[count]);
         }
 
         const oldData = JSON.parse(this.state.fullProductData);
         if (tmpRow && tmpRow.upsellAll && tmpRow.upsellAll.length) {
             // eslint-disable-next-line
             tmpRow.upsellAll.map((upsellRow, inx) => {
                 if (upsellRow && upsellRow.data && upsellRow.data.length) {
                     // eslint-disable-next-line
                     upsellRow.data.map((subupsellRow, subinx) => {
                         if (subupsellRow && subupsellRow.data && subupsellRow.data.Defaultquantity > 0) {
                             oldData[subupsellRow.mainIndex].product[subupsellRow.index].quantityCount += subupsellRow.data.Defaultquantity;
                             finalArray.push(subupsellRow.data);
                         }
                     });
                 }
             });
         }
         await addProductCart(finalArray, (data) => {
             cartItems = data;
         });
         const addToCard = {
             cartItems: cartItems,
         };
         localStorage.setItem('addToCard', JSON.stringify(addToCard));
         this.child.getCartCount();
         oldData[this.state.catIndex].product[this.state.productIndex].quantityCount += tmpRow.Defaultquantity;
         this.setState({
             tmpData: JSON.stringify(oldData),
             productModelShowLatest: false,
             productData:oldData
         });
         $('#search').val('');
         this.setState({searchValue: ''});
         setTimeout(() => {
             this.changeSearchCateName('');
         }, 100);
     }
     selectOrderOption(key) {
         let tmpType = 'Delivery';
         if (this.state.isActiveAddressType === key) {
             return;
         }
         if (key === 2) {
             this.setState({
                 userAddress: '',
                 userAddress1: ''
             });
             tmpType = 'Pick at Restaurant';
         }
         this.setState({
             isActiveAddressType: key,
             selectValueOrderType: 'Order Now'
         });
         localStorage.setItem(this.props.match.params.vendorId + '___delivery', JSON.stringify({
             isActiveAddressType: key,
             DeliveryType: tmpType,
             isUnivercity: false,
             orderType: 'Order Now'
         }));
         this.setState({
             deliveryFullData: {
                 isActiveAddressType: key,
                 DeliveryType: tmpType,
                 isUnivercity: false,
                 orderType: 'Order Now'
             }
         })
     }
     openScheduleModel() {
         const ttmpDelivery = JSON.parse(localStorage.getItem(this.props.match.params.vendorId + '___delivery'));
         this.setState({
             deliveryFullData: ttmpDelivery,
                 isActiveAddressType: ttmpDelivery.isActiveAddressType,
                 selectValueOrderType: ttmpDelivery.orderType,
                 defaultValueTime: ttmpDelivery.scheduleTime || '',
                 userAddress1: ttmpDelivery.userAddress1 || null,
                 userAddress: ttmpDelivery.line1 || '',
                 scheduleTimeDate: ttmpDelivery.scheduleDate,
         });
         if (ttmpDelivery && ttmpDelivery.orderType === 'Schedule For Later') {
             this.changeScheduleOrderType('Schedule For Later');
         }
         this.child.handleClose();
         this.setState({
             isOpenScheduleModel: true
         });
     }
     closeScheduleModel() {
         this.setState({
             isOpenScheduleModel: false,
             selectValueOrderType: 'Order Now'
         });
     }
     manageScheArryFunction(vendorData) {
         const oneDayTime = 60 * 60 * 24 * 1000;
         let nextDay = 1;
         var manageScheArry = [{
             title: 'Today',
             date: manageCurrentTime(vendorData.tz, 'short', new Date().getTime()),
             scheduleDay:  getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date()))),
             scheduleArray: manageScheduleArray(vendorData, getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date())))),
             timeArray: []
         }];
 
         const scheduleArrarManage = () => {
             let holiday = true;
             const date = manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)).split('/').join('-');
             if (vendorData && vendorData.offdays && vendorData.offdays.length) {
                 for (let ii = 0; ii < vendorData.offdays.length; ii += 1) {
                     if (vendorData.offdays[ii].date === date) {
                         nextDay += 1;
                         holiday = false;
                     }
                 }
             }
             if (holiday === true) {
                 manageScheArry.push({
                     title: manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)),
                     date: manageCurrentTime(vendorData.tz, 'short', new Date().getTime() + (oneDayTime*nextDay)),
                     scheduleDay: getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date(new Date().getTime() + (oneDayTime*nextDay))))),
                     scheduleArray: manageScheduleArray(vendorData, getDayFunction(dayPassReturnKey(manageCurrentTime(vendorData.tz, 'weekDay', new Date(new Date().getTime() + (oneDayTime*nextDay)))))),
                     timeArray: []
                 });
                 if (manageScheArry.length !== 3) {
                     nextDay += 1;
                     scheduleArrarManage();
                 }
             } else {
                 if (manageScheArry.length !== 3) {
                     scheduleArrarManage();
                 }
             }
         }
         scheduleArrarManage();
         return manageScheArry;
     }
     manageLaterData() {
         if (this.state.vendorData && this.state.vendorData.schedule) {
             var manageScheArry = this.manageScheArryFunction(this.state.vendorData);
             const getGenTime = (timeString) => {
                 let H = +timeString.substr(0, 2);
                 let h = (H % 12) || 12;
                 if (h < 10) {
                     h = '0'+h;
                 }
                 let ampm = H < 12 ? "AM" : "PM";
                 return timeString = h + timeString.substr(2, 3) + ampm;
             };
             let count = 0;
             const countFun = (row) => {
                 if (count >= manageScheArry.length) {
                     this.setState({scheduleData: manageScheArry});
                     setTimeout(() => {
                         if (manageCurrentTime(this.state.vendorData.tz, 'short', new Date().getTime()) === this.state.scheduleTimeDate) {
                             this.changeScheduleDay('Today');
                         } else {
                             this.changeScheduleDay(this.state.scheduleTimeDate);
                         }
                     }, 500);
                     return;
                 }
                 var testArr = [];
                 let scheduleArrayCount = 0;
                 const scheduleArrayFun = (scheduleRow) => {
                     if (scheduleArrayCount >= row.scheduleArray.length) {
                         count += 1;
                         countFun(manageScheArry[count]);
                         return;
                     }
                     let fromtime = scheduleRow.door.split('-')[0];
                     let totime = scheduleRow.door.split('-')[1];
                     function calculateTimeSlot(start_time, end_time, interval = 30){
                         var formatted_time;
                         var time_slots = [];
                         for(var i=start_time; i<=end_time; i = i+interval){
                             formatted_time = convertHours(i);
                             time_slots.push(formatted_time);
                         }
                         if (parseTime(time_slots[time_slots.length-1]) < end_time) {
                             time_slots.push(convertHours(end_time));
                         }
                         for (let ii = 0; ii < time_slots.length; ii += 1) {
                             if (ii === 0) {
                                 if (testArr.indexOf(getGenTime(time_slots[0])) === -1) {
                                     // testArr.push(getGenTime(time_slots[0]))
                                 }
                             } else {
                                 if (time_slots[ii+1] !== undefined && testArr.indexOf(getGenTime(time_slots[ii])) === -1) {
                                     testArr.push(getGenTime(time_slots[ii]));
                                 }
                                 
                                 if ((time_slots.length - 1) === ii) {
                                     testArr.push(getGenTime(time_slots[ii]));
                                 }
                             }
                         }
                         return testArr;
                     }
                     row.timeArray = calculateTimeSlot( parseTime(fromtime, 'add'), parseTime(totime), 30);
                     scheduleArrayCount += 1;
                     scheduleArrayFun(row.scheduleArray[scheduleArrayCount]);
                 }
                 if (row && row.scheduleArray) {
                     scheduleArrayFun(row.scheduleArray[scheduleArrayCount]);
                 }
                 
                 
             };
             countFun(manageScheArry[count]);
         }
     }
     changeScheduleOrderType(name) {
         this.setState({
             selectValueOrderType: name
         });
         if (name === 'Schedule For Later') {
             this.manageLaterData();
         }
         
     }
     changeScheduleDay(value, key) {
         if (!value) {
             value = 'Today';
         }
         const tmpData = this.state.scheduleData;
         for (let i = 0; i < tmpData.length; i += 1) {
             if (tmpData[i].title === value) {
                 if (tmpData[i].title === 'Today') {
                     let manageTimeArr = [];
                     for (let ii = 0; ii < tmpData[i].timeArray.length; ii += 1) {
                         var tmpTime = convertTime12to24(tmpData[i].timeArray[ii].split(' - ')[0]);
                         var startTime = parseInt(tmpTime.split(':').join(''));
                         if (startTime > manageCurrentTime(this.state.vendorData.tz)+100) {
                             manageTimeArr.push(tmpData[i].timeArray[ii]);
                         }
                     }
                     if (!(manageTimeArr && manageTimeArr.length)) {
                         manageTimeArr = ['Restaurant Not Available'];
                     }
                     let tmpDefaultValueTime = manageTimeArr[0];
                     let tmpScheduleTimeDate = tmpData[i].date;
                     if (!key) {
                         const tmpD = JSON.parse(localStorage.getItem(this.props.match.params.vendorId + '___delivery'));
                         if (tmpD && tmpD.scheduleTime) {
                             tmpDefaultValueTime = tmpD.scheduleTime;
                         }
                         if (tmpD && tmpD.scheduleDate) {
                             tmpScheduleTimeDate = tmpD.scheduleDate;
                         }
                     }
                     this.setState({
                         defaultValueTime: tmpDefaultValueTime,
                         scheduleTimeData: manageTimeArr,
                         scheduleTimeDate: tmpScheduleTimeDate
                     });
                 } else {
                     if (!(tmpData[i].timeArray && tmpData[i].timeArray.length)) {
                         tmpData[i].timeArray = ['Restaurant Not Available'];
                     }
                     let tmpDefaultValueTime = tmpData[i].timeArray[0];
                     let tmpScheduleTimeDate = tmpData[i].date;
                     if (!key) {
                         const tmpD = JSON.parse(localStorage.getItem(this.props.match.params.vendorId + '___delivery'));
                         if (tmpD && tmpD.scheduleTime) {
                             tmpDefaultValueTime = tmpD.scheduleTime;
                         }
                         if (tmpD && tmpD.scheduleDate) {
                             tmpScheduleTimeDate = tmpD.scheduleDate;
                         }
                     }
                     this.setState({
                         defaultValueTime: tmpDefaultValueTime,
                         scheduleTimeData: tmpData[i].timeArray,
                         scheduleTimeDate: tmpScheduleTimeDate
                     });
                 }
                 break;
             }
         }
     }
     changeScheduleTime(value) {
         this.setState({
             defaultValueTime: value,
         });
     }
     startOrderClick() {
         const orderObj = {
             DeliveryType: ''
         };
         
         if (this.state.selectValueOrderType === 'Schedule For Later') {
             if (this.state.defaultValueTime === 'Restaurant Not Available') {
                 swal('This time restaurant not available, please select other schedule');
                 return;
             }
             orderObj.scheduleDate = this.state.scheduleTimeDate;
             orderObj.scheduleTime = this.state.defaultValueTime;
         }
         
         if (this.state.isActiveAddressType === 1) {
             if (!this.state.userAddress) {
                 swal('Please enter delivery address');
                 return;
             }
 
             if (this.state.userAddress) {
                 geocodeByAddress(this.state.userAddress)
                     .then(results => getLatLng(results[0]))
                     .then(({ lat, lng }) => {
                         this.setState({
                             userAddress: this.state.userAddress,
                             userLng: lng,
                             userLat: lat,
                         });
                         if (this.state.vendorData.deliveryRadius < distanceCalculation(lat, lng, this.state.vendorData.latitude, this.state.vendorData.longitude)) {
                             swal(
                                 'Sorry... Your address is beyond our delivery range. Please choose a pickup option.'
                             );
                             return;
                         } else {
                             orderObj.DeliveryType = 'Delivery';
                             orderObj.isUnivercity = false;
                             orderObj.line1 = this.state.userAddress;
                             orderObj.orderType = this.state.selectValueOrderType;
                             orderObj.location = {
                                 latitude: this.state.userLat,
                                 longitude: this.state.userLng
                             };
                             this.setState({
                                 orderAddressObj: orderObj
                             });
                             this.closeScheduleModel();
                         }
                     }
                 );
             }
         } else {
             orderObj.DeliveryType = 'Pick at Restaurant';
             orderObj.isUnivercity = false;
             orderObj.orderType = this.state.selectValueOrderType;
             this.setState({
                 orderAddressObj: orderObj
             });
             this.closeScheduleModel();
         }
         let tmpOrderObj = orderObj;
         tmpOrderObj.isActiveAddressType = this.state.isActiveAddressType;
         if (this.state.isActiveAddressType === 1) {
             tmpOrderObj.userAddress1 = this.state.userAddress1;
         }
         setTimeout(() => {
             localStorage.setItem( this.props.match.params.vendorId + '___delivery', JSON.stringify(tmpOrderObj));
             this.setState({
                 deliveryFullData: tmpOrderObj,
             });
         }, 1000);
     }
     openMoreDetailsClick() {
         const openMoreDetails = !this.state.openMoreDetails;
         this.setState({
             openMoreDetails: openMoreDetails,
         });
     }
     render() {
         const { linkIds
         } = { ...this.state }
         const convertTimeAMPM = (timeString) => {
             let day = timeString.split('-');
             const manageVar = (day) => {
                 var timeString = day;
                 var hourEnd = timeString.indexOf(":");
                 var H = +timeString.substr(0, hourEnd);
                 var h = H % 12 || 12;
                 var ampm = H < 12 ? "AM" : "PM";
                 timeString = h + timeString.substr(hourEnd, 3) + ampm;
                 return timeString;
             }
 
             return manageVar(day[0])+ ' - ' +manageVar(day[1]);
         };
         return (
             <div>
                 {this.state && this.state.isSpinner && this.state.vendorData && Object.keys(this.state.vendorData) && Object.keys(this.state.vendorData).length && 
                     <div>
                         <Navi ref={i => {this.child = i}} vendorData={this.state.vendorData} vendorId={this.props.match.params.vendorId} browerLatitude={this.state.browerLatitude} browerLongitude={this.state.browerLongitude} hideHeader={this?.state?.queryData?.hideHeader ? this.state.queryData.hideHeader : ""} vendorProduct={this.state} deleteItem={(item)=>{ 
                             
                             var Index=-1;
                             var selecIndex = this.state.productData.findIndex(
                                 element => element.categoryName === item.product_category,
                             );
                             
                             if(selecIndex!==-1){
                                  Index = this.state.productData[selecIndex].product.findIndex(
                                     element => element.id === item.id,
                                 );  
                             }
                             // eslint-disable-next-line
                             this.state.productData[selecIndex].product[Index].quantityCount -= item.Defaultquantity;
                             const tmpData = this.state.productData;
                             this.setState({
                                 productData:tmpData,
                                 browerLatitude: this.state.browerLatitude,
                                 browerLongitude: this.state.browerLongitude,
                                 tmpData: JSON.stringify(tmpData),
                             })
                         }} updateItem={(item)=>{
                             var Index=-1;
                             var selecIndex = this.state.productData.findIndex(
                                 element => element.categoryName === item.product_category,
                             );
                             
                             if(selecIndex!==-1){
                                  Index = this.state.productData[selecIndex].product.findIndex(
                                     element => element.id === item.id,
                                 );  
                             }
                             // eslint-disable-next-line
                             if (this.state.productData[selecIndex] && this.state.productData[selecIndex].product[Index] && this.state.productData[selecIndex].product[Index].quantityCount) {
                                 // eslint-disable-next-line
                                 this.state.productData[selecIndex].product[Index].quantityCount = item.Defaultquantity;
                                 const tmpData = this.state.productData;
                                 this.setState({
                                     productData:tmpData,
                                     tmpData: JSON.stringify(tmpData),
                                 })
                             }
                         }}/>
                         <div className="sidebar-trigger p-t-61">
                             {queryData.hideHeader !== 'true' &&
                                 <div className="vendor-header">
                                     <div className="restaurent-wrapper">
                                         <div className="res-logo">
                                             <img src={this.state.vendorData.photo} alt={this.state.vendorData.photo}></img>
                                         </div>
                                         <div className="res-deatil">
                                             <h4>{this.state.vendorData.title}</h4>
                                             {!this.state.openMoreDetails &&
                                                 // eslint-disable-next-line
                                                 <a onClick={() => this.openMoreDetailsClick()}>Show More Details</a>
                                             }
                                             {this.state.openMoreDetails && 
                                                 <div>
                                                     <p>{this.state.vendorData.description}</p>
                                                     <p>{this.state.vendorData.addressLine1}</p>
                                                     <p>{this.state.vendorData.addressLine2}</p>
                                                     <p>{this.state.vendorData.phone}</p>
                                                     {this.state && this.state.vendorData && this.state.vendorData.website && 
                                                         <a href={this.state.vendorData.website} target="__blank">{this.state.vendorData.website}</a>
                                                     }
                                                 </div>
                                             }
                                             {this.state.openMoreDetails &&
                                                 // eslint-disable-next-line
                                                 <a onClick={() => this.openMoreDetailsClick()}>Hide More Details</a>
                                             }
                                         </div>
                                     </div>
                                     <div className="restaurent-wrapper">
                                         {!(localStorage.getItem('dining') && localStorage.getItem('dining') === 'true') &&
                                             <div>
                                                 {this.state.deliveryFullData && this.state.deliveryFullData.DeliveryType === 'Delivery' &&
                                                     <div>
                                                         <div className="font-weight-600">{this.state.deliveryFullData.DeliveryType} at</div>
                                                         {this.state.deliveryFullData && this.state.deliveryFullData.line1 &&
                                                             <div>{this.state.deliveryFullData.line1}</div>
                                                         }
                                                     </div>
                                                 }
                                                 {this.state.deliveryFullData && this.state.deliveryFullData.DeliveryType === 'Pick at Restaurant' &&
                                                     <div>
                                                         <div className="font-weight-600">{this.state.deliveryFullData.DeliveryType}</div>
                                                     </div>
                                                 }
                                                 {this.state.deliveryFullData && this.state.deliveryFullData.scheduleDate &&
                                                     <div>Schedule Date: {this.state.deliveryFullData.scheduleDate}</div>
                                                 }
                                                 {this.state.deliveryFullData && this.state.deliveryFullData.scheduleTime &&
                                                     <div>Schedule Time: {this.state.deliveryFullData.scheduleTime}</div>
                                                 }
                                             </div>
                                         }
                                     </div>
                                 </div>
                             }
                             {!this.state.restaurantNotAvailable && 
                                 <div>
                                     <div className="main-search-section">
                                         <div className={'search-section '+ (this.state.isActiveAddressType === 2 ? 'search-section-delivery ' : ' ') + (this.state && this.state.vendorData && !this.state.vendorData.deliveryPublic ? 'without-delivery' : ' ')}>
                                             {!this.state.dining && 
                                                 <div className={'display-contents '+ (this.state.isActiveAddressType === 1 ? 'delivery-active' : ' ')}>
                                                     <div className="order-button-group">
                                                         <button type="button" className={'order-btn-primary mr-3 '+ (this.state.isActiveAddressType === 2 ? 'active' : ' ')} onClick={() => this.selectOrderOption(2)}>Pickup</button>
                                                         {this.state && this.state.vendorData && this.state.vendorData.deliveryPublic && 
                                                             <button type="button" className={'order-btn-primary mr-3 '+ (this.state.isActiveAddressType === 1 ? 'active' : ' ')} onClick={() => this.selectOrderOption(1)}>Delivery</button>
                                                         }
                                                         <button type="button" className={'order-btn-primary mr-3 '+ (this.state.isActiveAddressType === 3 ? 'active' : ' ')} onClick={() => this.openScheduleModel()}>Schedule</button>
                                                         <div className={'select-box-cate ' + (this.state.isActiveAddressType === 1 ? 'delivery-active2 ' : 'cate-box ')+(this.state.dining === true ? 'cate-search-select-main' : 'cate-box ')}>
                                                             <select className="cate-search-select" value={this.state.searchCateName} onChange={(e) => this.changeSearchCateName(e.target.value)}>
                                                                 <option value="">Select Category</option>
                                                                 {this.state.vendorData.product_categories.map((cRow, cIndex) => (
                                                                     <option key={cIndex} value={cRow}>{cRow}</option>
                                                                 ))}
                                                                 
                                                             </select>
                                                         </div>
                                                     </div>
                                                     {this.state.isActiveAddressType === 1 && 
                                                         <div className="address-place">
                                                             <GooglePlacesAutocomplete
                                                                 apiKey={process.env.REACT_APP_GOOGLEMAPKEY}
                                                                 fetchDetails={true}
                                                                 selectProps={{
                                                                     value: this.state.userAddress1,
                                                                     placeholder: 'Type delivery address',
                                                                     styles: {
                                                                         input: (provided) => ({
                                                                         ...provided,
                                                                         width: '100%'
                                                                         }),
                                                                     },
                                                                     onChange: (data) => {
                                                                         this.setState({
                                                                             userAddress1: data,
                                                                         });
                                                                         geocodeByAddress(data.label)
                                                                             .then(results => getLatLng(results[0]))
                                                                             .then(({ lat, lng }) => {
                                                                                 this.setState({
                                                                                     userAddress: data.label,
                                                                                     userLng: lng,
                                                                                     userLat: lat,
                                                                                 });
                                                                                 if (this.state.vendorData.deliveryRadius < distanceCalculation(lat, lng, this.state.vendorData.latitude, this.state.vendorData.longitude)) {
                                                                                     swal(
                                                                                         'Sorry... Your address is beyond our delivery range. Please choose a pickup option.'
                                                                                         );
                                                                                         return;
                                                                                     }
                                                                                     let tmpDelivery = JSON.parse(localStorage.getItem(this.state.vendorData.id + '___delivery'));
                                                                                     if (tmpDelivery) {
                                                                                         let tmpObj = {
                                                                                             isActiveAddressType: 1,
                                                                                             DeliveryType: 'Delivery',
                                                                                             isUnivercity: false,
                                                                                             line1: data.label,
                                                                                             location: {
                                                                                                 latitude: lat,
                                                                                                 longitude: lng
                                                                                             },
                                                                                             orderType: this.state.selectValueOrderType,
                                                                                             userAddress1: data
                                                                                         }
                                                                                         if (tmpDelivery.orderType !== 'Order Now') {
                                                                                             tmpObj.scheduleDate = tmpDelivery.scheduleDate;
                                                                                             tmpObj.scheduleTime = tmpDelivery.scheduleTime;
                                                                                         }
                                                                                         localStorage.setItem(this.state.vendorData.id + '___delivery', JSON.stringify(tmpObj));
                                                                                         this.setState({
                                                                                             deliveryFullData: tmpObj,
                                                                                         })
                                                                                     }
                                                                             }
                                                                         );
                                                                     },
                                                                 }}
                                                             />
                                                         </div>
                                                     }
                                                 </div>
                                             }
                                             {/* <div className={(this.state.isActiveAddressType === 1 ? 'delivery-active2 ' : 'cate-box ')+(this.state.dining === true ? 'cate-search-select-main' : 'cate-box ')}>
                                                 <select className="cate-search-select" value={this.state.searchCateName} onChange={(e) => this.changeSearchCateName(e.target.value)}>
                                                     <option value="">Select Category</option>
                                                     {this.state.vendorData.product_categories.map((cRow, cIndex) => (
                                                         <option key={cIndex} value={cRow}>{cRow}</option>
                                                     ))}
                                                     
                                                 </select>
                                             </div> */}
                                             <div className={'res-search-wrapper '+ (this.state.dining === true ? 'search-res-main ' : ' ') + (this.state.isActiveAddressType === 1 ? 'delivery-active1' : ' ')}>
                                                 <input type="text" autoComplete="off" id="search" name="search" maxLength="20" ref="searchInput" placeholder="Search products" onChange={(e) => this.searchChange(e.target.value)} />
                                                 <img src={searchIcon} alt="success-logo"></img>
                                             </div>
                                             
                                             <Modal className="schedule_model" show={this.state.isOpenScheduleModel} onHide={() => this.closeScheduleModel()}>
                                                 <Modal.Header closeButton>
                                                     <Modal.Title>Schedule</Modal.Title>
                                                 </Modal.Header>
                                                 <Modal.Body>
                                                     <div className="schedule-body-section">
                                                         <div className="select-schedule">
                                                             <div className="btn-group">
                                                                 <button type="button" className={'btn btn-primary '+ (this.state.isActiveAddressType === 2 ? 'active' : ' ')} onClick={() => this.selectOrderOption(2)}>Pickup</button>
                                                                 {this.state && this.state.vendorData && this.state.vendorData.deliveryPublic && 
                                                                     <button type="button" className={'btn btn-primary '+ (this.state.isActiveAddressType === 1 ? 'active' : ' ')} onClick={() => this.selectOrderOption(1)}>Delivery</button>
                                                                 }
                                                             </div>
                                                         </div>
                                                         <div className="delivery-content">
                                                             <form>
                                                                 {this.state.isActiveAddressType === 1 &&
                                                                     <div className="form-group">
                                                                         <label>Where should we  Send your Order?</label>
                                                                         <div>
                                                                             <GooglePlacesAutocomplete
                                                                                 apiKey={process.env.REACT_APP_GOOGLEMAPKEY}
                                                                                 selectProps={{
                                                                                     value: this.state.userAddress1,
                                                                                     placeholder: 'Type delivery address',
                                                                                     styles: {
                                                                                         input: (provided) => ({
                                                                                         ...provided,
                                                                                         width: '100%'
                                                                                         })
                                                                                     },
                                                                                     onChange: (data) => {
                                                                                         this.setState({
                                                                                             userAddress1: data
                                                                                         })
                                                                                         geocodeByAddress(data.label)
                                                                                             .then(results => getLatLng(results[0]))
                                                                                             .then(({ lat, lng }) => {
                                                                                                 this.setState({
                                                                                                     userAddress: data.label,
                                                                                                     userLng: lng,
                                                                                                     userLat: lat,
                                                                                                 });
                                                                                                 if (this.state.vendorData.deliveryRadius < distanceCalculation(lat, lng, this.state.vendorData.latitude, this.state.vendorData.longitude)) {
                                                                                                     swal(
                                                                                                         'Sorry... Your address is beyond our delivery range. Please choose a pickup option.'
                                                                                                     );
                                                                                                     return;
                                                                                                 }
                                                                                             }
                                                                                         );
                                                                                     }
                                                                                 }}
                                                                             />
                                                                         </div>
                                                                     </div>
                                                                 }
                                                                 <div className="form-group mb-0fg">
                                                                     <label>Where would you Like your Order?</label>
                                                                     <div className="radiodelivery">
                                                                         <input id="radio-1" name="radio" type="radio" value="Order Now" checked={this.state.selectValueOrderType === 'Order Now'} onChange={() => this.changeScheduleOrderType('Order Now')} />
                                                                         <label htmlFor="radio-1" className="radiodelivery-label">Order Now</label>
                                                                     </div>
                                                                     <hr className="orderhr"></hr>
                                                                     <div className="radiodelivery">
                                                                         <input id="radio-2" name="radio" type="radio" value="Schedule For Later" checked={this.state.selectValueOrderType === 'Schedule For Later'} onChange={() => this.changeScheduleOrderType('Schedule For Later')} />
                                                                         <label htmlFor="radio-2" className="radiodelivery-label">Schedule For Later </label>
                                                                     </div>
                                                                     { this.state.selectValueOrderType !== 'Order Now' &&
                                                                         <div className="time-date">
                                                                             <select value={this.state.scheduleTimeDate} onChange={(e) => this.changeScheduleDay(e.target.value, '1')}>
                                                                                 {this.state.scheduleData.map((sRow, sIndex) => (
                                                                                     <option key={sIndex} value={sRow.title}>{sRow.title}</option>
                                                                                 ))}
                                                                             </select>
                                                                             <select value={this.state.defaultValueTime} onChange={(e) => this.changeScheduleTime(e.target.value)}>
                                                                                 {this.state.scheduleTimeData.map((stRow, stIndex) => (
                                                                                     <option key={stIndex} value={stRow}>{stRow}</option>
                                                                                 ))}
                                                                             </select>
                                                                         </div>
                                                                     }
                                                                 </div>
                                                             </form>
                                                         </div>
                                                     </div>
                                                 </Modal.Body>
                                                 <Modal.Footer>
                                                     <div>
                                                         <Button className="btn-orange" variant="orange" onClick={() => this.startOrderClick()}>
                                                             Start Order
                                                         </Button>
                                                     </div>
                                                 </Modal.Footer>
                                             </Modal>
                                         </div>                       
                                     </div>
                                     <div className="main-tab-wrapper">
                                         <Tabs className="mt-3" activeKey={this.state.selectTabObj.title} onSelect={(k) => this.tabsChange(k)}>
                                         {this.state.tabsArray.map((tRow, tIndex) => (
                                                 <Tab eventKey={tRow.title} title={tRow.title} key={tIndex}>
                                                     {!tRow.isOpenMenu && 
                                                         <p className="text-center mt-3 font-weight-600">{tRow.title} {convertTimeAMPM(tRow.door)}</p>
                                                     }
                                                     <div>
                                                         {this.state.productData && this.state.productData.length ? (
                                                             <div className="vendor-product-container">
                                                                 <ReactTooltip />
                                                                 {this.state.productData.map((row, index) => (
                                                                     <div className="vendor-product-section" key={index}>
                                                                         {row.product && row.product.length !== 0 && 
                                                                             <h1 className="category-title">{row.categoryName}</h1>
                                                                         }
                                                                         <div className="row">
                                                                             {this.state.dining && this.state.productData[index].product.map((pRow, pIndex) => (
                                                                                 <div className="col-md-3 catagory-col disabled" key={pIndex}>
                                                                                     <div className="product-box" onClick={() => this.openProductModelLatest(pRow, index, pIndex)}>
                                                                                         <div className="product_small_img">
                                                                                             {pRow.photo && 
                                                                                                 <img className="" src={pRow.photo} alt={pRow.name}></img>
                                                                                             }
                                                                                             {!pRow.photo && 
                                                                                                 <img className="" src={noImage} alt={pRow.name}></img>
                                                                                             }
                                                                                         </div>
                                                                                         <div className="product_details_right">
                                                                                             <div className="product_title-price-wrapper">
                                                                                                 <div className="product_title">
                                                                                                     {pRow.name && pRow.name.length > 30 &&                                                                                                    
                                                                                                         <h3 data-tip={pRow.name}>{pRow.name}</h3>
                                                                                                     }
                                                                                                     {pRow.name && pRow.name.length <= 30 &&                                                                                                    
                                                                                                         <h3>{pRow.name}</h3>
                                                                                                     }
                                                                                                 </div>
                                                                                             </div>
                                                                                             <div className="product_desc">
                                                                                                 <p>{pRow.description}</p>
                                                                                             </div>
                                                                                         </div>
                                                                                     </div>
                                                                                 </div> 
                                                                             ))}
                                                                             {!this.state.dining && this.state.productData[index].product.map((pRow, pIndex) => (
                                                                                 <div className={'col-md-3 catagory-col disabled '+ (pRow.delivery === false ? 'd-none' : ' ')} key={pIndex}>
                                                                                         <div className="product-box" onClick={() => this.openProductModelLatest(pRow, index, pIndex)}>
                                                                                             <div className="product_small_img">
                                                                                                 {pRow.photo && 
                                                                                                     <img className="" src={pRow.photo} alt={pRow.name}></img>
                                                                                                 }
                                                                                                 {!pRow.photo && 
                                                                                                     <img className="" src={noImage} alt={pRow.name}></img>
                                                                                                 }
                                                                                             </div>
                                                                                             <div className="product_details_right">
                                                                                                 <div className="product_title-price-wrapper">
                                                                                                     <div className="product_title">
                                                                                                         {pRow.name && pRow.name.length > 30 &&                                                                                                    
                                                                                                             <h3 data-tip={pRow.name}>{pRow.name}</h3>
                                                                                                         }
                                                                                                         {pRow.name && pRow.name.length <= 30 &&                                                                                                    
                                                                                                             <h3>{pRow.name}</h3>
                                                                                                         }
                                                                                                     </div>
                                                                                                 </div>
                                                                                                 <div className="product_desc">
                                                                                                     <p>{pRow.description}</p>
                                                                                                 </div>
                                                                                             </div>
                                                                                         </div>
                                                                                 </div> 
                                                                             ))}
                                                                         </div>
                                                                     </div>
                                                                 ))}
                                                             </div>        
                                                         ) : (
                                                             <div className="row mr-0 ml-0">
                                                                 <div className="col-md-12">
                                                                     <h4 className="text-center mt-3">No Product Found.</h4>
                                                                 </div>
                                                             </div>
                                                         )}
                                                     </div>     
                                                 </Tab>
                                         ))}
                                         </Tabs>
                                         <Modal className="product_modal product-box-model" show={this.state.productModelShowLatest} onHide={() => this.closeProductModelLatest()}>
                                             <Modal.Header className="modal-header-main" closeButton>
                                                 <Modal.Title>
                                                     <h3 className="modal-product-title">{this.state.productDetailsObj.name}</h3>
                                                     <h5 className="mb-0">${((
                                                                 (Number(this.state.productPrice.toFixed(2)))
                                                                 *
                                                                 this.state.productDetailsObj.Defaultquantity
                                                                 )
                                                                 +
                                                                 tmpUpsellWithAmount).toFixed(2)}
                                                     </h5>
                                                     {this.state.productDetailsObj.description && 
                                                         <p>{this.state.productDetailsObj.description}</p>
                                                     }
                                                 </Modal.Title>
                                             </Modal.Header>
                                             
                                             {((this.state.productDetailsObj && this.state.productDetailsObj.modifiers && this.state.productDetailsObj.modifiers.length > 0) 
                                                 || this.state.productDetailsObj.special_instructions) && 
                                                 <Modal.Body className="modal-body-main">
                                                     {this.state && this.state.productDetailsObj && this.state.productDetailsObj.photo && 
                                                         <img className="model-product-image" src={this.state.productDetailsObj.photo} alt={this.state.productDetailsObj.name}></img>
                                                     }
 
                                                     <div className="row">
                                                         {this.state.productDetailsObj.modifiers && this.state.productDetailsObj.modifiers.map((row, index) => (
                                                             <div className="col-md-12" key={index}>
                                                                 {/* Comes With Options */}
                                                                 {row.isComesWith && !row.isItemOption && !row.isPizzaTopping &&
                                                                     <div className="product-optional-items">
                                                                         <h5>{row.displayName}</h5>
                                                                         {
                                                                             row.options.map((oiRow, oiIndex) => (
                                                                                 <div className="checkbox" key={oiIndex}>
                                                                                     <input type="checkbox" id={row.displayName+oiRow.name+index+oiIndex} name={oiRow.name+"comewith"+index} value={oiRow.name} checked={oiRow.isChecked} onChange={(e) => this.changeComesWith(e, this.state.productDetailsObj, oiRow, row)} />
                                                                                     <label className={"check-label " + (oiRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oiRow.name+index+oiIndex} > {oiRow.name}
                                                                                     </label>
                                                                                 </div>
                                                                             ))
                                                                         }
                                                                     </div>
                                                                 }
                                                                 {/* Item Options */}
                                                                 {row.isItemOption && !row.isComesWith && !row.isPizzaTopping &&
                                                                     <div className="product-addition-options">
                                                                         <h5>{row.displayName}</h5>
                                                                         {
                                                                             row.options.map((aoRow, aoIndex) => (
                                                                                 <div className="radio mb-3 mt-0" key={aoIndex}>
                                                                                     <input type="radio" id={row.displayName+aoRow.name+index+aoIndex} name={row.displayName+'itemoption'+index+aoIndex} value={aoRow.name} checked={aoRow.isChecked} onChange={(e) => this.changeItemOptions(e, this.state.productDetailsObj, aoRow, row, aoIndex)} />
                                                                                     <label className={"radio-label " + (aoRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+aoRow.name+index+aoIndex}>{aoRow.name}</label>
                                                                                 </div>
                                                                             ))
                                                                         }
                                                                     </div>
                                                                 }
                                                                 {/* Item Options Selection */}
                                                                 {!row.isItemOption && !row.isComesWith && !row.isPizzaTopping &&
                                                                     <div>
                                                                         {(!row.isItemOptionId || row.isItemOptionId === "") &&
                                                                             <div className="product-optional-items">
                                                                                 <h5>{row.displayName}
                                                                                 </h5>
                                                                                 {row && row.choiceLimit !== "" && !row.isRequired && !row.isFixed &&
                                                                                     <p>Maximum of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row && row.choiceLimit !== "" && row.isRequired && !row.isFixed &&
                                                                                     <p>Minimum of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row && row.choiceLimit !== "" && !row.isRequired && row.isFixed && 
                                                                                     <p>Select of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row.choiceLimit !== "" && row.isRequired && !row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                                     <p className="m-t--5-i color-red error-scroll-top">You must choose minimum {row.choiceLimit} items</p>
                                                                                 }
                                                                                 {row.choiceLimit !== "" && !row.isRequired && row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                                     <p className="m-t--5-i color-red error-scroll-top">You must choose {row.choiceLimit} items</p>
                                                                                 }
                                                                                 {
                                                                                     row.options.map((oisRow, oiIndex) => (
                                                                                         <div className="checkbox" key={oiIndex}>
                                                                                             <input type="checkbox" id={row.displayName+oisRow.name+index+oiIndex} name={oisRow.name+"itemoptionselect"+index} value={oisRow.name} checked={oisRow.isChecked ? oisRow.isChecked : false} onChange={(e) => this.changeItemOptionsSelect(e, this.state.productDetailsObj, oisRow, row)} />  
                                                                                             <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name+index+oiIndex} > {oisRow.name}
                                                                                             </label>
                                                                                         </div>
                                                                                     ))
                                                                                 }
                                                                             </div>
                                                                         }
                                                                         { linkIds.indexOf(row.isItemOptionId) !== -1 &&
                                                                             <div className="product-optional-items">
                                                                                 <h5>{row.displayName}
                                                                                 </h5>
                                                                                 {row && row.choiceLimit !== "" && !row.isRequired && !row.isFixed &&
                                                                                     <p>Maximum of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row && row.choiceLimit !== "" && row.isRequired && !row.isFixed &&
                                                                                     <p>Minimum of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row && row.choiceLimit !== "" && !row.isRequired && row.isFixed && 
                                                                                     <p>Select of <span>{row.choiceLimit}</span></p>
                                                                                 }
                                                                                 {row.choiceLimit !== "" && row.isRequired && !row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                                     <p className="m-t--5-i color-red error-scroll-top">You must choose minimum {row.choiceLimit} items</p>
                                                                                 }
                                                                                 {row.choiceLimit !== "" && !row.isRequired && row.isFixed && this.state.Error.modifiresError.index.length > 0 && this.state.Error.modifiresError.index.includes(index) && 
                                                                                     <p className="m-t--5-i color-red error-scroll-top">You must choose {row.choiceLimit} items</p>
                                                                                 }
                                                                                 {
                                                                                     row.options.map((oisRow, oiIndex) => (
                                                                                         <div className="checkbox" key={oiIndex}>
                                                                                             <input type="checkbox" id={row.displayName+oisRow.name+index+oiIndex} name={oisRow.name+"itemoptionselect"+index} value={oisRow.name} checked={oisRow.isChecked ? oisRow.isChecked : false} onChange={(e) => this.changeItemOptionsSelect(e, this.state.productDetailsObj, oisRow, row)} />  
                                                                                             <label className={"check-label " + (oisRow.isChecked ? "font-weight-700":"")} htmlFor={row.displayName+oisRow.name+index+oiIndex} > {oisRow.name}
                                                                                             </label>
                                                                                         </div>
                                                                                     ))
                                                                                 }
                                                                             </div>
                                                                         }
                                                                     </div>
                                                                 }
                                                                 {/* Pizza Topping */}
                                                                 {!row.isComesWith && !row.isItemOption && row.isPizzaTopping && 
                                                                     <div className="row width-100">
                                                                         <div className="col-md-12">
                                                                             { linkIds.indexOf(row.isItemOptionId) !== -1 &&
                                                                                 <div className="">
                                                                                         <h5 className="childOptionTopping_name toppingName_main">
                                                                                             <span className="sub_topping_title_left">{row.displayName}</span>
                                                                                             <span className="sub_topping_title">
                                                                                                 <span>Left Half</span>
                                                                                                 <span>Right Half</span>
                                                                                                 <span>Full</span>
                                                                                             </span>
                                                                                         </h5>
                                                                                         {row.options.map((subChildOptionsRow, subChildOptionsIndex) => (
                                                                                             <div className="child-topping-box" key={subChildOptionsIndex}>
                                                                                                 <h5 className="childOptionTopping_name">
                                                                                                     <span className={"sub_topping_title_left" + (!subChildOptionsRow.selectedValue ? " font-weight-300": "")}>{subChildOptionsRow.name} 
                                                                                                     </span>
                                                                                                     <div className="m-b-5 mt-0 radio_fancy_wrapper">                                                                                                                     
                                                                                                         <div className="radio_fancy">
                                                                                                             <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'left' ? 'check-topping-radio' : '')}>
                                                                                                                 <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'left'} checked={subChildOptionsRow.selectedValue === 'left'} onClick={(e) => this.changePizzaOptions(e, 'left', row, row.options, subChildOptionsIndex)} />
                                                                                                             </div>
                                                                                                             <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'right' ? 'check-topping-radio' : '')}>
                                                                                                                 <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'right'} checked={subChildOptionsRow.selectedValue === 'right'} onClick={(e) => this.changePizzaOptions(e, 'right', row, row.options, subChildOptionsIndex)} />
                                                                                                                 </div>
                                                                                                             <div className={"radio_fancy_option " + (subChildOptionsRow.selectedValue === 'full' ? 'check-topping-radio' : '')}>
                                                                                                                 <input type="radio" name={subChildOptionsIndex+'childOptions'} value={subChildOptionsRow.selectedValue === 'full'} checked={subChildOptionsRow.selectedValue === 'full'} onClick={(e) => this.changePizzaOptions(e, 'full', row, row.options, subChildOptionsIndex)} />
                                                                                                             </div>
                                                                                                         </div>
                                                                                                     </div>
                                                                                                 </h5>
                                                                                             </div>
                                                                                         ))}
                                                                                 </div>
                                                                             }
                                                                         </div>
                                                                     </div>
                                                                 }
                                                             </div>
                                                         ))}
                                                     </div>
                                                     
 
                                                     { this.state.productDetailsObj.special_instructions &&
                                                         <div className="product-optional-items product-special-instructions mt-0">
                                                             <h5>Special Instructions</h5>
                                                             <div className="special-name">
                                                                 <input type="text" autoComplete="off" id="specialInstructions" name="specialInstructions" maxLength="120" placeholder="Special Instructions" onChange={(e) => this.changeSpecialInstructions(e, this.state.productDetailsObj)} />
                                                             </div>
                                                         </div>
                                                     }
                                                 </Modal.Body>
                                             }
                                             <Modal.Footer className="modal-footer-main">
                                                 <div className="custome-modal-footer mt-0">
                                                     <div className="dynamic-price-section">
                                                         <div className="product-category-menu">
                                                             <span className="prod-cate-decrease" onClick={() => this.decreaseItem(this.state.productDetailsObj)}>-</span>
                                                             <span className="">{this.state.productDetailsObj.Defaultquantity}</span>
                                                             <span className="prod-cate-increase" onClick={() => this.increaseItem(this.state.productDetailsObj)}>+</span>
                                                         </div>
                                                         <div>
                                                             <p className="mb-0">$ {((
                                                                 (Number(this.state.productPrice.toFixed(2)))
                                                                 *
                                                                 this.state.productDetailsObj.Defaultquantity
                                                                 )
                                                                 +
                                                                 tmpUpsellWithAmount).toFixed(2)}</p>
                                                         </div>
                                                     </div>
                                                     <div className="model-button-wrapper">
                                                         {Number((((Number(this.state.productPrice.toFixed(2))) * this.state.productDetailsObj.Defaultquantity) + tmpUpsellWithAmount).toFixed(2)) > 0 &&
                                                             <Button className="btn-orange" variant="orange" onClick={() => this.checkValidateLatest(this.state.productDetailsObj)}>
                                                                 Add To Cart
                                                             </Button>
                                                         }
                                                         {Number((((Number(this.state.productPrice.toFixed(2))) * this.state.productDetailsObj.Defaultquantity) + tmpUpsellWithAmount).toFixed(2)) === 0 &&
                                                             <Button className="btn-orange disabled" variant="orange">
                                                                 Add To Cart
                                                             </Button>
                                                         }
                                                     </div>
                                                 </div>
                                             </Modal.Footer>
                                         </Modal>
                                     </div>
                                 </div>
                             }
                             { this.state.restaurantNotAvailable && 
                                 <div>
                                     <p className="text-center mt-3">{this.state.restaurantNotAvailableReason}</p>
                                 </div>
                             }
                         </div>
                     </div>
                 }
                 { this.state && this.state.isSpinner && !Object.keys(this.state.vendorData).length && 
                     <div>
                         <h2 className="text-center">No Data.</h2>
                     </div>
                 }
                 { this.state && !this.state.isSpinner && 
                     <div className="center-box-cn">
                         <div className="center-box-section">
                             <Spinner/>
                         </div>
                     </div>
                 }
             </div>
         );
     }
   }
 export default VendorProduct;