import { postMethod } from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';
// const apiBaseURLV2 = process.env.REACT_APP_APIURL+'/api/v2/';
// const version = 'V2';
export async function cashOrderStatusChange(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'cashorderstatuschange', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('cashorderstatuschange', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'cashorderstatuschange', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('cashorderstatuschange', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export async function addCashPayment(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'addcashpayment', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('addcashpayment', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'addcashpayment', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('addcashpayment', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};

export async function checkDeliveryOrderUrlValid(requestOptions, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.post(apiBaseURL+'checkdeliveryorderurlvalid', requestOptions, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            postMethod('checkdeliveryorderurlvalid', requestOptions, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            });
        } else {
            axios.post(apiBaseURL+'checkdeliveryorderurlvalid', requestOptions, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    postMethod('checkdeliveryorderurlvalid', requestOptions, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
};