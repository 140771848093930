import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';
// const apiBaseURLV2 = process.env.REACT_APP_APIURL+'/api/v2/';

/**
 * Maintainence Hoistory (Name, Date, Comments)
 * shyam vadaliya, 12/07/2020, Get dynamic primary and secondry key
 */
const getAuthPrimaryOrSecondary = (version, cb) => {
    try {
        // if (version === 'V2') {
        //     apiBaseURL = apiBaseURLV2;
        // }
        axios.get(apiBaseURL+'get-auth-primary-secondary', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(result => {
            if (result.data.status) {
                cb(result.data.data);
            } else {
                cb({});
            }
        }).catch(error => {
            console.error(error);
            cb({});
        });
    } catch (error) {
        console.error(error);
        cb({});
    }
};


const HostUrl = process.env.REACT_APP_APIURL+'/'
export async function getAuth(version, cb) {
    await getAuthPrimaryOrSecondary(version, (data) => {
        try {
            axios.post(HostUrl+'89578159-d0f4-4a4c-a267-3595c01aa459', data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(result => {
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        } catch (error) {
            console.error(error);
            cb({});
        }
    });
}