import React from "react";
import Navi from "../../nav";
import { cashOrderStatusChange, addCashPayment, checkDeliveryOrderUrlValid } from './service';
import './orderStatusChange.css';
class OrderStatusChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayKey: 0,
            displayMessage: '',
            orderData: {},
            frm_Details: {
                cashPayment: '',
            },
            frm_errors: {
                cashPayment: '',
            }
        };
        this.checkValidation = this.checkValidation.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormSubmitDelivered = this.onFormSubmitDelivered.bind(this);
    }
    componentDidMount() {
        const params = this.props.match.params;
        checkDeliveryOrderUrlValid({
            orderUrl: params.orderUrl
        }, (valid) => {
            if (!valid.status) {
                this.setState({
                    displayKey: 4,
                    displayMessage: 'Url is invalid',
                });
                return;
            }
            cashOrderStatusChange({
                orderNumber: valid.data.orderNumber,
                vendorId: valid.data.vendorID,
                orderId: valid.data.id
            }, (data) => {
                this.setState({
                    displayKey: data.key,
                    displayMessage: data.statusText,
                    orderData: data?.orderData ? data.orderData : {}
                });
            });
        })
    }
 
    checkValidation(fieldName = "", value = "") {
        let formDetails = this.state.frm_Details;
        let formErrors = this.state.frm_errors;
        let isError = false;
        let formSubmit = false;
        if (fieldName === "" && value === "") {
            formSubmit = true;
        }
        if ((formSubmit === false && fieldName === "cashPayment" && value === "") || (formSubmit === true && formDetails["cashPayment"] === "")) {
            fieldName = 'cashPayment';
            formErrors[fieldName] = `The 'Cash Payment' is a required field.`;
            isError = true;
        }
        this.setState({ frm_errors: formErrors });
        this.setState({ frm_Details: formDetails });
        return isError;
    }

    onFocusOut(e) {
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        this.checkValidation(fieldName, fieldValue);
    }

    onDetailsChange(e) {
        let self = this;
        let formDetails = self.state.frm_Details;
        let formErrors = self.state.frm_errors;
        let fieldName = e.target.id;
        let fieldValue = e.target.value;
        formDetails[fieldName] = fieldValue;
        self.setState({ frm_Details: formDetails });
        let isError = self.checkValidation(fieldName, fieldValue);
        if (!isError) {
            formErrors[fieldName] = "";
            self.setState({ frm_errors: formErrors });
        }
    }

    onFormSubmit() {
        let self = this;
        let isError = self.checkValidation();
        if (!isError) {
            if (parseFloat(self.state?.orderData?.resturantDetails?.total) > parseFloat(self.state.frm_Details.cashPayment)) {
                let formDetails = self.state.frm_Details;
                let formErrors = self.state.frm_errors;
                const fieldName = 'cashPayment';
                formErrors[fieldName] = `Please enter a valid amount for order.`;
                self.setState({ frm_errors: formErrors });
                self.setState({ frm_Details: formDetails });
                return;
            }
            addCashPayment({
                vendorId: self.state.orderData.vendorID,
                cashPayment: parseFloat(self.state.frm_Details.cashPayment),
                orderData: self.state.orderData,
            }, (data) => {
                this.setState({
                    displayKey: data.key,
                    displayMessage: data.statusText,
                    orderData: data?.orderData ? data.orderData : {}
                });
            });
        }
    }
    
    onFormSubmitDelivered() {
        let self = this;
        cashOrderStatusChange({
            orderNumber: self.state.orderData.orderNumber,
            vendorId: self.state.orderData.vendorID,
            orderId: self.state.orderData.id,
            delivered: true
        }, (data) => {
            this.setState({
                displayKey: data.key,
                displayMessage: data.statusText,
                orderData: data?.orderData ? data.orderData : {}
            });
        });
    }
 
    render() {
        const { displayKey, displayMessage, orderData, frm_errors, frm_Details} = {...this.state};
        return (
            <div className="">
                <div>
                    <Navi />
                    <div className="single_msg_wrapper">
                        {displayKey === 1 && 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="c_info">
                                        {orderData?.resturantDetails?.resturantDetails?.name && 
                                            <h3>{orderData?.resturantDetails?.resturantDetails?.name}</h3>
                                        }
                                        {orderData?.author?.firstName && 
                                            <b>{orderData?.author?.firstName ? orderData.author.firstName : ""} {orderData?.author?.lastName ? orderData.author.lastName : ""}</b>
                                        }
                                        {orderData?.address?.line1 && 
                                            <p>{orderData?.address?.line1 ? orderData.address.line1 : ""}</p>
                                        }
                                        {orderData?.author?.phone && 
                                            <p>{orderData?.author?.phone ? orderData.author.phone : ""}</p>
                                        }
                                        {orderData?.author?.email && 
                                            <p>{orderData?.author?.email ? orderData.author.email : ""}</p>
                                        }
                                        <p>Order Number: {orderData.orderNumber}</p>
                                        {orderData?.resturantDetails?.total && 
                                            <p>Total: ${orderData?.resturantDetails?.total ? orderData.resturantDetails.total : "0"}</p>
                                        }
                                        <table className="table table-bordered mt-4">
                                            <thead>
                                            <tr>
                                                <th><h6>Qty</h6></th>
                                                <th className="text-left"><h6>Name</h6></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {orderData.products.map((product, productIndex) => (<tr key={productIndex}>
                                                    <td><span className="font-weight-bold">{product.quantity}x</span></td>
                                                    <td className="text-left"><p className="font-weight-bold mb-1">{product.name}</p></td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <p className="single_msg">{displayMessage}</p>
                                </div>
                            </div>
                        }
                        {displayKey === 2 && 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="c_info">
                                        {orderData?.resturantDetails?.resturantDetails?.name && 
                                            <h3>{orderData?.resturantDetails?.resturantDetails?.name}</h3>
                                        }
                                        {orderData?.author?.firstName && 
                                            <b>{orderData?.author?.firstName ? orderData.author.firstName : ""} {orderData?.author?.lastName ? orderData.author.lastName : ""}</b>
                                        }
                                        {orderData?.address?.line1 && 
                                            <p>{orderData?.address?.line1 ? orderData.address.line1 : ""}</p>
                                        }
                                        {orderData?.author?.phone && 
                                            <p>{orderData?.author?.phone ? orderData.author.phone : ""}</p>
                                        }
                                        {orderData?.author?.email && 
                                            <p>{orderData?.author?.email ? orderData.author.email : ""}</p>
                                        }
                                        <p>Order Number: {orderData.orderNumber}</p>
                                        {orderData?.resturantDetails?.total && 
                                            <p>Total: ${orderData?.resturantDetails?.total ? orderData.resturantDetails.total : "0"}</p>
                                        }
                                        <table className="table table-bordered mt-4">
                                            <thead>
                                            <tr>
                                                <th><h6>Qty</h6></th>
                                                <th className="text-left"><h6>Name</h6></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {orderData.products.map((product, productIndex) => (<tr key={productIndex}>
                                                    <td><span className="font-weight-bold">{product.quantity}x</span></td>
                                                    <td className="text-left"><p className="font-weight-bold mb-1">{product.name}</p></td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                        <div className="form-wrapper">
                                            <button type="button" className="btn btn-submit" onClick={this.onFormSubmitDelivered}>Delivered</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {displayKey === 3 && 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="c_info">
                                        {orderData?.resturantDetails?.resturantDetails?.name && 
                                            <h3>{orderData?.resturantDetails?.resturantDetails?.name}</h3>
                                        }
                                        {orderData?.author?.firstName && 
                                            <b>{orderData?.author?.firstName ? orderData.author.firstName : ""} {orderData?.author?.lastName ? orderData.author.lastName : ""}</b>
                                        }
                                        {orderData?.address?.line1 && 
                                            <p>{orderData?.address?.line1 ? orderData.address.line1 : ""}</p>
                                        }
                                        {orderData?.author?.phone && 
                                            <p>{orderData?.author?.phone ? orderData.author.phone : ""}</p>
                                        }
                                        {orderData?.author?.email && 
                                            <p>{orderData?.author?.email ? orderData.author.email : ""}</p>
                                        }
                                        <p>Order Number: {orderData.orderNumber}</p>
                                        {orderData?.resturantDetails?.total && 
                                            <p>Total: ${orderData?.resturantDetails?.total ? orderData.resturantDetails.total : "0"}</p>
                                        }
                                        <table className="table table-bordered mt-4">
                                            <thead>
                                            <tr>
                                                <th><h6>Qty</h6></th>
                                                <th className="text-left"><h6>Name</h6></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {orderData.products.map((product, productIndex) => (<tr key={productIndex}>
                                                    <td><span className="font-weight-bold">{product.quantity}x</span></td>
                                                    <td className="text-left"><p className="font-weight-bold mb-1">{product.name}</p></td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="new-form-wrapper add_cash_payment">
                                        <h5 className="form-title">Add Cash Payment</h5>
                                        <div className="form-wrapper">
                                            <form>
                                                <div className="form-group">
                                                    <label>Cash Payment</label>
                                                    <input
                                                        type="number"
                                                        className={"form-control " + (frm_errors.cashPayment !== "" ? 'invalid' : '')}
                                                        name="cashPayment"
                                                        id="cashPayment"
                                                        placeholder="Enter Amount"
                                                        value={frm_Details.cashPayment}
                                                        onChange={this.onDetailsChange}
                                                        onBlur={this.onFocusOut}
                                                        required
                                                    />
                                                    {frm_errors.cashPayment && frm_errors.cashPayment !== "" &&
                                                        <div className="text-left invalid-feedback">{frm_errors.cashPayment}</div>
                                                    }
                                                </div>
                                                <div className="submit-btn-wrapper">
                                                    <button type="button" className="btn btn-submit" onClick={this.onFormSubmit}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {displayKey === 4 && 
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="single_msg">{displayMessage}</p>
                                </div>
                            </div>
                        }
                        {displayKey === 5 && 
                            <div className="row">
                            <div className="col-md-12">
                                <div className="c_info">
                                    {orderData?.resturantDetails?.resturantDetails?.name && 
                                        <h3>{orderData?.resturantDetails?.resturantDetails?.name}</h3>
                                    }
                                    {orderData?.author?.firstName && 
                                        <b>{orderData?.author?.firstName ? orderData.author.firstName : ""} {orderData?.author?.lastName ? orderData.author.lastName : ""}</b>
                                    }
                                    {orderData?.address?.line1 && 
                                        <p>{orderData?.address?.line1 ? orderData.address.line1 : ""}</p>
                                    }
                                    {orderData?.author?.phone && 
                                        <p>{orderData?.author?.phone ? orderData.author.phone : ""}</p>
                                    }
                                    {orderData?.author?.email && 
                                        <p>{orderData?.author?.email ? orderData.author.email : ""}</p>
                                    }
                                    <p>Order Number: {orderData.orderNumber}</p>
                                    {orderData?.resturantDetails?.total && 
                                        <p>Total: ${orderData?.resturantDetails?.total ? orderData.resturantDetails.total : "0"}</p>
                                    }
                                    <table className="table table-bordered mt-4">
                                        <thead>
                                        <tr>
                                            <th><h6>Qty</h6></th>
                                            <th className="text-left"><h6>Name</h6></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.products.map((product, productIndex) => (<tr key={productIndex}>
                                                <td><span className="font-weight-bold">{product.quantity}x</span></td>
                                                <td className="text-left"><p className="font-weight-bold mb-1">{product.name}</p></td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="single_msg">{displayMessage}</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
 
export default OrderStatusChange;