import {Route, Switch } from "react-router-dom";
import VendorProduct from './components/vendorProduct/vendorProduct';
import OrderReceive from './components/orderReceive/orderReceive';
import Checkout from './components/checkout/checkout';
import Home from './components/home/home';
import Demosqure from './components/demosqure/demosqure';
import Root from './components/root/root';
import Unsubscribe from './components/unsubscribe/unsubscribe';
import OrderStatusChange from './components/orderStatusChange/orderStatusChange';
import PageNotFound from './components/pageNotFound/pageNotFound';

import SquareForm from './components/squareform/squareform';
import LoyaltyCustomer from './components/loyaltycustomer/loyaltycustomer';

// your components
const routes = (
    <Switch>
        <Route path="/" component={Root} exact/>
        <Route path="/home" component={Home} />
        <Route path="/demosqure" component={Demosqure} />
        <Route path="/vendorproduct/:vendorId" component={VendorProduct}/>
        <Route path="/checkout/:vendorId" component={Checkout}/>
        <Route path="/orderreceive/:vendorId" component={OrderReceive}/>
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/o/:orderUrl" component={OrderStatusChange} />
        <Route path="/squareform/:vendorId" component={SquareForm} />
        <Route path="/loyaltycustomer/:vendorId" component={LoyaltyCustomer} />
        <Route component={PageNotFound} />
    </Switch>
);

export default routes;
