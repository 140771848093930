import { getMethod } from '../regenerateAuth'
import { getAuth } from '../../service'
import axios from 'axios'
const apiBaseURL = process.env.REACT_APP_APIURL+'/api/v1/';

export async function getVendorProductData(vendorId, cb) {
    try {
        if (!localStorage.getItem('token')) {
            getAuth('', async (auth) => {
                if (auth && auth.success && auth.token) {
                    localStorage.setItem('token', auth.token);
                    axios.get(apiBaseURL+`get-vendor-product-data/${vendorId}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    }).then(result => {
                        if (result.data.success === false) {
                            getMethod(`get-vendor-product-data/${vendorId}`, '', (resData) => {
                                cb(resData);
                            });
                            return;
                        }
                        cb(result.data);
                    }).catch(error => {
                        console.error(error);
                        cb({});
                    });
                }
            })
        } else {
            axios.get(apiBaseURL+`get-vendor-product-data/${vendorId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(result => {
                if (result.data.success === false) {
                    getMethod(`get-vendor-product-data/${vendorId}`, '', (resData) => {
                        cb(resData);
                    });
                    return;
                }
                cb(result.data);
            }).catch(error => {
                console.error(error);
                cb({});
            });
        }
    } catch (error) {
        console.error(error);
        cb({});
    }
}